import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";

const GallerySlider = ({ gallery }) => {
  console.log("gallerygallerygallery", gallery);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "start",
    slidesToScroll: 1,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <div>
      <div className="embla relative">
        <div className="embla__viewport overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex -ml-3">
            {gallery?.map((image, index) => (
              <div
                key={index}
                className="embla__slide flex-[0_0_65%] min-w-0 pl-3"
              >
                <div className="">
                  <img className="block" src={image[0]} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          className="embla__prev w-[calc(35%-12px)] h-full absolute top-0 left-0 hidden sm:block z-50"
          onClick={scrollPrev}
        ></button>
        <button
          type="button"
          className="embla__next w-[calc(35%-12px)] h-full absolute top-0 right-0 hidden sm:block z-50"
          onClick={scrollNext}
        ></button>
      </div>
      {gallery?.length > 1 && (
        <div className="embla__dots flex gap-4 justify-center mt-8">
          {scrollSnaps.map((_, index) => (
            <div
              key={index}
              onClick={() => scrollTo(index)}
              className={clsx(
                "w-[11px] h-[11px] rounded-full cursor-pointer",
                index === selectedIndex ? "bg-black" : "bg-tpc-darker"
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GallerySlider;
