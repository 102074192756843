let pinterestClick = () => {
 jQuery('.flex-viewport').append('<div class="pinterest-cta"><a class="pinterest-cta__link" href="" title=""><span class="pinterest-cta__circle"><i class="fa fa-pinterest-p"></i></span></a></div>');
 var url = window.location;
 var title = jQuery('div.summary h2').first().text();
 var artist = jQuery('.product-artist').text();

 jQuery('.pinterest-cta__link').click(function() {
   var imgSrc = jQuery('.woocommerce-product-gallery__image.flex-active-slide img').attr('src');
   var pinterestLink = 'https://pinterest.com/pin/create/button/?url='+url+'&media='+imgSrc+'&description='+title+' by '+artist+' | Poster from theposterclub.com';
   jQuery(this).attr('href',pinterestLink);
   jQuery(this).attr('target', '_blank');
 }); 

}

export default pinterestClick;
