import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import {
  activeProductInfoSidebarTabAtom,
  showProductInfoSidebarAtom,
} from "../../store";
import { productInfoSidebarTabs } from "../../lib/globals";

const Button = ({ title, slug }) => {
  const [showProductInfo, setShowProductInfo] = useAtom(
    showProductInfoSidebarAtom
  );

  const [activeProductInfoSidebarTab, setActiveProductInfoSidebarTab] = useAtom(
    activeProductInfoSidebarTabAtom
  );

  return (
    <button
      className="flex justify-between items-center [&:not(:first-child)]:border-t border-gray-light py-4 cursor-pointer group w-full"
      onClick={() => {
        setShowProductInfo(true);
        setActiveProductInfoSidebarTab(slug);
      }}
    >
      <span className="font-display-regular font-medium text-[14px] tracking-[0.6px] leading-none text-[#000000] uppercase">
        {title}
      </span>
      <FontAwesomeIcon
        icon={["fal", "plus"]}
        className="text-gray-light group-hover:text-[#000000] transition-colors duration-200 ease-in-out pr-5"
      />
    </button>
  );
};

const Info = () => {
  return (
    <div className="mt-12 border-gray-light">
      {productInfoSidebarTabs
        ?.filter((productInfoSidebarTab) =>
          isWallObjectsProduct || isLimitedEditionsProduct
            ? productInfoSidebarTab?.slug !==
                productInfoSidebarTabs?.[0]?.slug &&
              productInfoSidebarTab?.slug !== productInfoSidebarTabs?.[1]?.slug
            : productInfoSidebarTab
        )
        ?.filter((productInfoSidebarTab) =>
          isFrameProduct
            ? productInfoSidebarTab?.slug !== productInfoSidebarTabs?.[1]?.slug
            : productInfoSidebarTab
        )
        ?.map((productInfoSidebarTab) =>
          isPassepartoutProduct &&
          productInfoSidebarTab?.slug === productInfoSidebarTabs?.[1]?.slug
            ? {
                slug: "frame-and-passepartout",
                tabTitle: "Frame",
                mainTitle: "Frame Details",
              }
            : productInfoSidebarTab
        )
        ?.map(({ mainTitle, slug }, index) => (
          <Button key={index} title={mainTitle} slug={slug} />
        ))}
    </div>
  );
};

export default Info;
