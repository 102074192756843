import { isEmpty } from "lodash";
import NewsletterContainer from "../containers/Newsletter";
import CurrencyPicker from "./currencyPicker/CurrencyPicker";
// import CurrencyPickerComponent from "./CurrencyPickerComponent";

const FooterComponent = () => {
  const year = new Date().getFullYear();

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    console.log("cookiebot trigger");
    if (window.Cookiebot) {
      window.Cookiebot.renew();
    }
  };

  return (
    <div className="bg-tpc-darker w-full pt-14 pb-5">
      <div className="container">
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex flex-col sm:flex-row sm:flex-1 sm:flex-wrap -mt-10 2lg:mt-0">
            {!isEmpty(footerColumns) &&
              footerColumns.map(
                ({ footer_column_title, footer_column_links }, index) => (
                  <div
                    key={index}
                    className="flex flex-col w-1/2 2lg:flex-1 mt-10 2lg:mt-0"
                  >
                    <p
                      className="font-display-regular text-gray-light text-xs uppercase mb-3 tracking-wider"
                      style={{ lineHeight: "16px" }}
                    >
                      {footer_column_title}
                    </p>
                    <div className="flex flex-col -mt-2">
                      {!isEmpty(footer_column_links) &&
                        footer_column_links.map(
                          (
                            {
                              footer_column_link_custom_link,
                              footer_column_link_custom_title,
                              footer_column_link_link,
                            },
                            index
                          ) => {
                            const isPrivacyLink =
                              footer_column_link_custom_title ===
                              "Privacy Setting & Policy";
                            return (
                              <a
                                key={index}
                                href={
                                  footer_column_link_custom_link
                                    ? footer_column_link_custom_link
                                    : footer_column_link_link?.url
                                }
                                className="font-body-regular text-sm text-black mt-2 tracking-wide"
                                style={{ lineHeight: "22px" }}
                                onClick={
                                  isPrivacyLink ? handlePrivacyClick : undefined
                                }
                              >
                                {footer_column_link_custom_title
                                  ? footer_column_link_custom_title
                                  : footer_column_link_link?.name}
                              </a>
                            );
                          }
                        )}
                    </div>
                  </div>
                )
              )}
            <div className="flex flex-col w-1/2 2lg:flex-1 mt-10 2lg:mt-0">
              <p
                className="font-display-regular text-gray-light text-xs uppercase mb-3 tracking-wider"
                style={{ lineHeight: "16px" }}
              >
                Follow Us
              </p>
              <div className="flex flex-col -mt-2">
                {!isEmpty(footerSocials) &&
                  footerSocials.map(
                    (
                      {
                        footer_social_media_link,
                        footer_social_media_link_title,
                      },
                      index
                    ) => (
                      <a
                        key={index}
                        href={footer_social_media_link}
                        className="font-body-regular text-sm text-black mt-2 tracking-wide"
                        style={{ lineHeight: "22px" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {footer_social_media_link_title}
                      </a>
                    )
                  )}
              </div>
              <p
                className="font-display-regular text-gray-light text-xs uppercase mb-3 tracking-wider mt-10"
                style={{ lineHeight: "16px" }}
              >
                Shipping to:
              </p>
              <div className="flex flex-col">
                {/* <CurrencyPickerComponent /> */}
                <CurrencyPicker />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 md:mt-0 w-full md:w-80">
            <p
              className="font-display-regular text-gray-light text-xs uppercase mb-3 tracking-wider"
              style={{ lineHeight: "16px" }}
            >
              Newsletter
            </p>
            <NewsletterContainer type="footer" />
          </div>
        </div>
        <div className="mt-10 flex flex-col-reverse md:flex-row md:justify-between md:items-center">
          <p className="font-body-regular text-gray-light text-xs m-0">
            &copy; {`The Poster Club ${year}`}
          </p>
          <div className="footer-payment-icons flex -ml-5 mb-10 md:mb-0 self-center">
            <img className="h-5 w-auto pl-5 block" src={visa} />
            <img className="h-5 w-auto pl-5 block" src={mastercard} />
            <img className="h-5 w-auto pl-5 block" src={americanExpress} />
            <img className="h-5 w-auto pl-5 block" src={paypal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
