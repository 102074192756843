require('es6-promise').polyfill();
import fetch from 'isomorphic-fetch';
import fetchMoreProductsRequest from './fetchMoreProductsRequest';
import fetchMoreProductsFailure from './fetchMoreProductsFailure';
import fetchMoreProductsSuccess from './fetchMoreProductsSuccess';

import { endpoints } from './../../lib/globals';
import { prepareFilterAndPaginationParams } from './../../lib/parse'

export default ({page = 1, limit, filterParams = {}, search = '', mainGrid = true, favouriteIds = [], product_ids = [], favouritesHash = false, favouritesFromHash = []}) => {
    let root = document.getElementById('app'),
        taxonomySlug = root.getAttribute('data-taxonomy_slug'),
        termId = root.getAttribute('data-term_id'),
        endpoint

    if (taxonomySlug) {
      endpoint = endpoints.taxonomy + '/' + taxonomySlug + '/' + termId
    } else {
      const urlQuery = prepareFilterAndPaginationParams(
        {
          page: page,
          limit: limit,
          filterParams: filterParams,
          search: search,
          mainGrid: mainGrid,
          product_ids: product_ids,
          favouriteIds: favouriteIds,
          favouritesHash: favouritesHash,
          favouritesFromHash: favouritesFromHash
        }
      )
      endpoint = `${endpoints.products}${urlQuery}`
    }


    return dispatch => {
        dispatch(fetchMoreProductsRequest());
        return fetch(endpoint)
            .then(response => response.json())
            .then(json => dispatch(fetchMoreProductsSuccess(json)))
            .catch(err => dispatch(fetchMoreProductsFailure(err)))
    }
}
