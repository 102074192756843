// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faHeart as faHeartLight,
  faChevronDown,
  faTimes,
  faEnvelope,
  faLink,
  faComment,
  faSearch,
  faShoppingBag,
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faTrashAlt,
  faEdit,
  faSignOut,
  faExclamationCircle,
  faCheck,
  faInfoCircle,
  faBagsShopping,
  faHome,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";

// make icons useable by adding to library
library.add(
  faHeartLight,
  faHeartSolid,
  faTimes,
  faChevronDown,
  faEnvelope,
  faLink,
  faComment,
  faSearch,
  faShoppingBag,
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faTrashAlt,
  faEdit,
  faSignOut,
  faExclamationCircle,
  faCheck,
  faInfoCircle,
  faBagsShopping,
  faHome,
  faPlus
);
