'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';

import { endpoints } from './../lib/globals';

import NewsletterComponent from './../components/NewsletterComponent';
import NewsletterPageComponent from './../components/NewsletterPageComponent';
import NewsletterPopupComponent from '../components/NewsletterPopupComponent';
import KlaviyoSignUpComponent from '../components/KlaviyoSignUpComponent';

// const mailchimp = new Mailchimp('');

class NewsletterContainer extends Component {
  constructor(props) {
    super(props);
    // console.log('the continer', props);
    this.state = {
      toggled: false,
      value: '',
      listId: '9a4acaf73b',
      response: '',
      hasError: false,
      isAdded: false,
      isSubmitting: false,
      type: props.type,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.toggleInput = this.toggleInput.bind(this);
    this.renderByType = this.renderByType.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    console.log('submit triggered');

    let myHeaders = new Headers(),
        { value } = this.state,
        body = {email: value};

    myHeaders.append('Content-Type', 'application/json');
    this.setState({isSubmitting: true});

    fetch(endpoints.mailchimp, {
      method: 'POST',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body)
    })
    .then( response => response.json())
    .then( json => this.handleResponse(json));
  }

  handleResponse(json) {
      // console.log(json);
      let { statusCode } = json,
          { value } = this.state,
          hasError, response, isAdded;
      switch(statusCode){
            case 201:
            case 200:
                hasError = false;
                response = 'Thank you!';
                isAdded = true;
                value = '';
                break;
            case 501:
                hasError = true;
                response = 'Oops, we encountered an error with our newsletter service. Please try again, or contact us if the issue persists.';
                isAdded = false;
                value = value
                break;
            case 500:
                hasError = true;
                response = 'Please enter a valid email address.';
                value = value
                break;
            default:
                hasError = false;
                response = '';
                value = value
                break;
      }
      this.setState({
          hasError: hasError,
          response: response,
          isAdded: isAdded,
          isSubmitting: false,
          value: value
      });
      console.log(json);
  }

  handleChange(e) {
    e.preventDefault();
    // console.log(e.target);
    this.setState({
      value: e.target.value
    });
  }

  toggleInput(toggleState = null, e) {
    toggleState = toggleState !== null ? toggleState : !this.state.toggled;
    this.setState({
      toggled: toggleState,
      hasError: false
    });

    console.log(`state.toggled: ${this.state.toggled}`);
  }

  renderByType() {
    switch (this.props.type) {
      case 'menu':
        return <NewsletterComponent
            value={this.state.value}
            toggled={this.state.toggled}
            toggleInput={this.toggleInput}
            handleSubmit={this.handleSubmit}
            changeFunc={this.handleChange}
            hasError={this.state.hasError}
            isAdded={this.state.isAdded}
            response={this.state.response}
            isSubmitting={this.state.isSubmitting}
          />;
      case 'page':
        return <NewsletterPageComponent
            value={this.state.value}
            handleSubmit={this.handleSubmit}
            changeFunc={this.handleChange}
            hasError={this.state.hasError}
            isAdded={this.state.isAdded}
            response={this.state.response}
            isSubmitting={this.state.isSubmitting}
          />;
      case 'popup':
        return <NewsletterPopupComponent
            value={this.state.value}
            handleSubmit={this.handleSubmit}
            changeFunc={this.handleChange}
            hasError={this.state.hasError}
            isAdded={this.state.isAdded}
            response={this.state.response}
            isSubmitting={this.state.isSubmitting}
          />
      case 'footer':
        return <KlaviyoSignUpComponent
            value={this.state.value}
            handleSubmit={this.handleSubmit}
            changeFunc={this.handleChange}
            hasError={this.state.hasError}
            isAdded={this.state.isAdded}
            response={this.state.response}
            isSubmitting={this.state.isSubmitting}
          />
      default: {
        return null;
      }
    }
  }

  render() {
    return(
        this.renderByType()
    )
  }
}

export default NewsletterContainer;
