import GallerySlider from "./GallerySlider";

const ShippingInformation = () => {
  return (
    <div className="px-5 sm:px-8">
      <div
        className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
        dangerouslySetInnerHTML={{ __html: shippingInformation?.content }}
      />
      <a
        className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-8 underline underline-offset-4 inline-block"
        href={shippingInformation?.cta?.link}
      >
        {shippingInformation?.cta?.name}
      </a>
      <div className="mt-8">
        <GallerySlider gallery={shippingInformation?.gallery} />
      </div>
    </div>
  );
};

export default ShippingInformation;
