import { SET_COUNTRY_CODE } from './../actions/const'

const initialState = {
  countryCode: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_CODE:
      return Object.assign({}, state, {
        countryCode: action.countryCode,
      })

    default: {
      return state
    }
  }
}
