export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_MORE_PRODUCTS_REQUEST = 'FETCH_MORE_PRODUCTS_REQUEST';
export const FETCH_MORE_PRODUCTS_FAILURE = 'FETCH_MORE_PRODUCTS_FAILURE';
export const FETCH_MORE_PRODUCTS_SUCCESS = 'FETCH_MORE_PRODUCTS_SUCCESS';

export const FETCH_FILTERS_REQUEST = 'FETCH_FILTERS_REQUEST';
export const FETCH_FILTERS_FAILURE = 'FETCH_FILTERS_FAILURE';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const SET_PRODUCTS_PAGE = 'SET_PRODUCTS_PAGE';
export const SET_PRODUCTS_FILTERS = 'SET_PRODUCTS_FILTERS';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
export const SET_FILTER_SEARCH = 'SET_FILTER_SEARCH';
export const SET_FILTERS_CHECKED = 'SET_FILTER_SEARCH';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const SET_PRODUCT_PARAMS = 'SET_PRODUCT_PARAMS';
export const SET_PRODUCT_SEARCH = 'SET_PRODUCT_SEARCH';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_PRICE = 'TOGGLE_PRICE';
export const SET_FAVOURITES = 'SET_FAVOURITES';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_CLICK_COUNT = 'SET_CLICK_COUNT';
export const SET_SHAREABLE_URL = 'SET_SHAREABLE_URL';
export const SET_HASH = 'SET_HASH';
export const SET_UNCONFIGURED_FAVOURITES = 'SET_UNCONFIGURED_FAVOURITES';
export const SET_VARIATION_IDS = 'SET_VARIATION_IDS';
export const SET_BUY_ERROR = 'SET_BUY_ERROR';
export const SET_BUY_ERROR_SINGLE = 'SET_BUYER_ERROR_SINGLE';
export const SET_LAST_HAS_OVERLAY = 'SET_LAST_HAS_OVERLAY';
export const SET_ADDED_TO_CART = 'SET_ADDED_TO_CART';
export const SET_SHOW_OFF_CANVAS = 'SET_SHOW_OFF_CANVAS';
export const SET_ART_WALL_PRODUCTS = 'SET_ART_WALL_PRODUCTS';
export const SET_ART_WALL_PRODUCT_FEED = 'SET_ART_WALL_PRODUCT_FEED';
export const SET_ART_WALL_ACTIVE_PRODUCT = 'SET_ART_WALL_ACTIVE_PRODUCT';
export const SET_MINIMIZE_ART_WALL_PRODUCTS_SIDEBAR = 'SET_MINIMIZE_ART_WALL_PRODUCTS_SIDEBAR';
export const SET_MINIMIZE_ART_WALL_PRODUCT_SIDEBAR = 'SET_MINIMIZE_ART_WALL_PRODUCT_SIDEBAR';
export const SET_POSTER_ON_TOP = 'SET_POSTER_ON_TOP';
export const SET_ART_WALL_CANVAS_HEIGHT = 'SET_ART_WALL_CANVAS_HEIGHT';
export const SET_SHOW_EDIT_ROOM_MODAL = 'SET_SHOW_EDIT_ROOM_MODAL';
export const SET_ACTIVE_ART_WALL_BACKGROUND = 'SET_ACTIVE_ART_WALL_BACKGROUND';
export const SET_ART_WALL_CANVAS_REF = 'SET_ART_WALL_CANVAS_REF';
export const SET_ART_WALL_SCREENSHOT = 'SET_ART_WALL_SCREENSHOT';
export const SET_SHOW_SHARE_AND_DOWNLOAD_MODAL = 'SET_SHOW_SHARE_AND_DOWNLOAD_MODAL';
export const SET_ART_WALL_CANVAS_WIDTH = 'SET_ART_WALL_CANVAS_WIDTH';
export const SET_FIRST_CLICK = 'SET_FIRST_CLICK';
export const SET_ART_WALL_SHAREABLE_URL = 'SET_ART_WALL_SHAREABLE_URL';
export const SET_ART_WALL_PRODUCTS_TO_BE_SHARED = 'SET_ART_WALL_PRODUCTS_TO_BE_SHARED';
export const SET_FRAMES = 'SET_FRAMES';
export const TOGGLE_OFFCANVAS_FILTER = 'TOGGLE_OFFCANVAS_FILTER';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_SHOW_ART_WALL_SORT_BY = 'SET_SHOW_ART_WALL_SORT_BY';
export const SET_SHOW_ART_WALL_FILTERS = 'SET_SHOW_ART_WALL_FILTERS';
export const SET_PRODUCT_CLICK = 'SET_PRODUCT_CLICK'
export const SET_SHOW_ART_WALL_CLEAR_ALL_MODAL = 'SET_SHOW_ART_WALL_CLEAR_ALL_MODAL'
export const SET_SHOW_ART_WALL_CLOSE_MODAL = 'SET_SHOW_ART_WALL_CLOSE_MODAL'
export const SET_ART_WALL_SCREENSHOT_STATUS = 'SET_ART_WALL_SCREENSHOT_STATUS'