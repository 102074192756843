'use strict';

import React from 'react';
import _ from 'lodash';

import FavouritesNotificationsComponent from './../components/FavouritesNotificationsComponent';

class FavouritesNotifications extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return(
      <FavouritesNotificationsComponent />
    )
  }
}

export default FavouritesNotifications;
