const aSizes = {
  A0: { width: 841, height: 1189 },
  A1: { width: 594, height: 841 },
  A2: { width: 420, height: 594 },
  A3: { width: 297, height: 420 },
  A4: { width: 210, height: 297 },
  A5: { width: 148, height: 210 },
  A6: { width: 105, height: 148 },
  A7: { width: 74, height: 105 },
  A8: { width: 52, height: 74 },
  A9: { width: 37, height: 52 },
  A10: { width: 26, height: 37 },
};

export const extractDimensions = (size) => {
  if (!size) {
    return null;
  }

  const aSizeMatch = size.match(/A\d+/);
  if (aSizeMatch) {
    const aSize = aSizeMatch[0];
    return aSizes[aSize];
  }

  const regex = /(\d+(\.\d+)?)\s*x\s*(\d+(\.\d+)?)\s*(cm|mm)/;
  const match = size.match(regex);

  if (match) {
    let width = parseFloat(match[1]);
    let height = parseFloat(match[3]);
    const unit = match[5];

    // Convert cm to mm
    if (unit === "cm") {
      width *= 10;
      height *= 10;
    }

    return { width, height };
  }

  // Return default values if the pattern does not match
  // return { width: 0, height: 0 };
  return null;
};

export const compareSizes = (a, b) => {
  const sizeA = extractDimensions(a.size);
  const sizeB = extractDimensions(b.size);

  const aSizeA = a.size.match(/A\d+/);
  const aSizeB = b.size.match(/A\d+/);

  if (aSizeA && !aSizeB) return -1; // A sizes come before non-A sizes
  if (!aSizeA && aSizeB) return 1; // Non-A sizes come after A sizes
  if (aSizeA && aSizeB) return aSizeB[0].localeCompare(aSizeA[0]); // Compare A sizes directly

  // Compare widths first
  if (sizeA.width !== sizeB.width) {
    return sizeA.width - sizeB.width;
  }
  // If widths are equal, compare heights
  return sizeA.height - sizeB.height;
};

export const compareSizesFavourites = (a, b) => {
  console.log("compareSizesFavourites a", a);
  console.log("compareSizesFavourites b", b);
  const sizeA = extractDimensions(a.name);
  const sizeB = extractDimensions(b.name);

  const aSizeA = a.name.match(/A\d+/);
  const aSizeB = b.name.match(/A\d+/);

  if (aSizeA && !aSizeB) return -1; // A sizes come before non-A sizes
  if (!aSizeA && aSizeB) return 1; // Non-A sizes come after A sizes
  if (aSizeA && aSizeB) return aSizeB[0].localeCompare(aSizeA[0]); // Compare A sizes directly

  // Compare widths first
  if (sizeA.width !== sizeB.width) {
    return sizeA.width - sizeB.width;
  }
  // If widths are equal, compare heights
  return sizeA.height - sizeB.height;
};

export const compareSizesPassepartoutAndFrames = (a, b) => {
  const sizeA = extractDimensions(a?.sizeForSort);
  const sizeB = extractDimensions(b?.sizeForSort);

  // Handle undefined sizes
  if (!sizeA && !sizeB) return 0;
  if (!sizeA) return 1;
  if (!sizeB) return -1;

  const aSizeA = a?.sizeForSort.match(/A\d+/);
  const aSizeB = b?.sizeForSort.match(/A\d+/);

  if (aSizeA && !aSizeB) return -1; // A sizes come before non-A sizes
  if (!aSizeA && aSizeB) return 1; // Non-A sizes come after A sizes
  if (aSizeA && aSizeB) return aSizeB[0].localeCompare(aSizeA[0]); // Compare A sizes directly

  // Compare widths first
  if (sizeA.width !== sizeB.width) {
    return sizeA.width - sizeB.width;
  }
  // If widths are equal, compare heights
  return sizeA.height - sizeB.height;
};

export const isWithinRange = (value, target, tolerance) => {
  return value >= target - tolerance && value <= target + tolerance;
};
