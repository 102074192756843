import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Select from "@radix-ui/react-select";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDimensions from "react-cool-dimensions";
import { isEmpty } from "../../utilities/isEmpty";
import { manualClickAtom } from "../../store";
import { useSetAtom } from "jotai";
import { AnimatePresence, motion } from "framer-motion";
import useClickAway from "../../hooks/useClickAway";

const SelectItem = React.forwardRef(
  (
    {
      children,
      className,
      dataItem,
      color,
      frameSwatch,
      type,
      dataLength,
      setStateCallbackHandler,
      setShowList,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <button
        disabled={dataLength === 1 && type !== "size" ? true : false}
        className={clsx(
          "outline-none font-display-regular font-normal text-xs text-black tracking-[0.5px] uppercase py-2 transition-colors duration-200 ease-in-out text-left",
          type !== "size" && "flex items-center gap-2",
          dataLength === 1 && type !== "size"
            ? "cursor-default"
            : "cursor-pointer hover:text-gray-light",
          className
        )}
        {...props}
        ref={forwardedRef}
        onClick={() => {
          setStateCallbackHandler(dataItem);
          setShowList(false);
        }}
      >
        {type !== "size" &&
          (frameSwatch ? (
            <img
              src={frameSwatch?.[0]}
              className="block rounded-full overflow-hidden shrink-0 grow-0"
              width={24}
              height={24}
              alt=""
            />
          ) : (
            <div
              className={clsx(
                "w-6 h-6 rounded-full relative overflow-hidden shrink-0 grow-0",
                color?.toLowerCase() === "#efeeec" &&
                  "after:content-[''] after:w-full after:h-[2px] after:bg-white after:absolute after:top-1/2 after:-translate-y-1/2 after:left-0 after:rotate-45 after:origin-center",
                color?.toLowerCase() === "#ffffff" && "border border-gray"
              )}
              style={{ backgroundColor: color }}
            />
          ))}
        <span
          className="translate-y-[1px] leading-[14px] m-0 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: children }}
        />
        {/* <Select.ItemIndicator className="absolute top-0 left-0 w-6 h-full flex justify-center items-center">
          <FontAwesomeIcon icon={["fal", "check"]} />
        </Select.ItemIndicator> */}
      </button>
    );
  }
);

const CustomSelect = ({ placeholder, data, state, setStateCallback, type }) => {
  // const [selectWidth, setSelectWidth] = useState(0);

  const setManualClick = useSetAtom(manualClickAtom);

  // const { observe, unobserve, width, height, entry } = useDimensions({
  //   onResize: ({ observe, unobserve, width, height, entry }) => {
  //     setSelectWidth(width);
  //   },
  // });

  const findFrameSwatch = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)
      ? data.find(({ slug }) => slug === value)?.frameSwatch
      : false;
  };

  const findName = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)?.name
      ? data.find(({ slug }) => slug === value)?.name
      : placeholder;
  };

  const findColor = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)?.color
      ? data.find(({ slug }) => slug === value)?.color
      : "none";
  };

  const setStateCallbackHandler = (value) => {
    setManualClick(data?.[0]?.type);
    setStateCallback(value);
  };

  console.log("datatata", data);

  const [showList, setShowList] = useState(false);

  console.log("showList", showList);

  const ref = useRef(null);

  useClickAway([ref], [() => setShowList(false)]);

  const handleEscKey = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setShowList(false);
      }
    },
    [setShowList]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);

  const listRef = useRef(null);

  const [showListArrow, setShowListArrow] = useState(true);

  useEffect(() => {
    if (listRef?.current && showList) {
      console.log(
        "listRef",
        listRef?.current?.scrollHeight,
        listRef?.current?.clientHeight
      );

      if (listRef?.current?.scrollHeight > listRef?.current?.clientHeight) {
        setShowListArrow(true);
      } else if (
        listRef?.current?.scrollHeight === listRef?.current?.clientHeight
      ) {
        setShowListArrow(false);
      }
    }
  }, [listRef, showList]);

  const onScrollHandler = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight === clientHeight) return;

    console.log(
      "scrollTop, scrollHeight, clientHeight",
      scrollTop,
      scrollHeight,
      clientHeight
    );
    if (scrollHeight - scrollTop === clientHeight) {
      console.log("reached the bottom");
      setShowListArrow(false);
    } else {
      setShowListArrow(true);
    }
  };

  return (
    // <Select.Root value={state} onValueChange={setStateCallbackHandler}>
    //   <Select.Trigger
    //     ref={observe}
    //     className="border border-gray px-3 h-11 w-full outline-none hidden sm:flex items-center justify-between font-display-regular font-normal text-xs text-black tracking-[0.5px] uppercase"
    //     aria-label=""
    //     disabled={isEmpty(data)}
    //   >
    //     <Select.Value aria-label={state} asChild>
    //       <div
    //         className={clsx(
    //           "text-left",
    //           type !== "size" &&
    //             findColor(state) !== "none" &&
    //             "flex gap-2 items-center"
    //         )}
    //       >
    //         {type !== "size" &&
    //           findColor(state) !== "none" &&
    //           (findFrameSwatch(state) ? (
    //             <img
    //               src={findFrameSwatch(state)?.[0]}
    //               className="block rounded-full overflow-hidden shrink-0 grow-0"
    //               width={24}
    //               height={24}
    //               alt=""
    //             />
    //           ) : (
    //             <div
    //               className={clsx(
    //                 "w-6 h-6 rounded-full relative overflow-hidden shrink-0 grow-0",
    //                 findColor(state)?.toLowerCase() === "#efeeec" &&
    //                   "after:content-[''] after:w-full after:h-[2px] after:bg-white after:absolute after:top-1/2 after:-translate-y-1/2 after:left-0 after:rotate-45 after:origin-center",
    //                 findColor(state)?.toLowerCase() === "#ffffff" &&
    //                   "border border-gray"
    //               )}
    //               style={{ backgroundColor: findColor(state) }}
    //             />
    //           ))}
    //         <span
    //           className="leading-[14px] translate-y-[1px] m-0 whitespace-pre-line"
    //           dangerouslySetInnerHTML={{ __html: findName(state) }}
    //         />
    //       </div>
    //     </Select.Value>
    //     <Select.Icon className="SelectIcon">
    //       <FontAwesomeIcon
    //         icon={["fal", "chevron-down"]}
    //         className="text-gray-light"
    //       />
    //     </Select.Icon>
    //   </Select.Trigger>
    //   <Select.Portal>
    //     <Select.Content
    //       avoidCollisions={false}
    //       position="popper"
    //       className="border-b border-x border-gray bg-white px-3 py-[15px] flex flex-col relative z-50 max-h-[200px] overflow-y-auto"
    //       style={{ width: `${selectWidth + 26}px` }}
    //     >
    //       <ScrollArea.Root className="w-full h-full" type="auto">
    //         <Select.Viewport className="flex flex-col gap-2" asChild>
    //           <ScrollArea.Viewport className="w-full h-full">
    //             <div className="w-full h-full" ref={ref}>
    //               {data.map((dataItem, index) => (
    //                 <SelectItem
    //                   key={index}
    //                   value={dataItem}
    //                   color={dataItem.color}
    //                   frameSwatch={dataItem?.frameSwatch ?? false}
    //                   type={type}
    //                   dataLength={data?.length}
    //                 >
    //                   {dataItem.name}
    //                 </SelectItem>
    //               ))}
    //             </div>
    //           </ScrollArea.Viewport>
    //         </Select.Viewport>
    //         <ScrollArea.Scrollbar
    //           className="w-1 px-[5px] py-[2px]"
    //           orientation="vertical"
    //         >
    //           <ScrollArea.Thumb className="bg-black bg-opacity-5 rounded-[3px]" />
    //         </ScrollArea.Scrollbar>
    //       </ScrollArea.Root>
    //     </Select.Content>
    //   </Select.Portal>
    // </Select.Root>
    <div ref={ref} className="relative">
      <button
        className="border border-gray px-3 h-11 w-full outline-none hidden sm:flex items-center justify-between font-display-regular font-normal text-xs text-black tracking-[0.5px] uppercase"
        onClick={() => setShowList((prev) => !prev)}
        disabled={isEmpty(data)}
      >
        <span
          className={clsx(
            "inline-block text-left",
            type !== "size" &&
              findColor(state) !== "none" &&
              "flex gap-2 items-center"
          )}
        >
          {type !== "size" &&
            findColor(state) !== "none" &&
            (findFrameSwatch(state) ? (
              <img
                src={findFrameSwatch(state)?.[0]}
                className="block rounded-full overflow-hidden shrink-0 grow-0"
                width={24}
                height={24}
                alt=""
              />
            ) : (
              <div
                className={clsx(
                  "w-6 h-6 rounded-full relative overflow-hidden shrink-0 grow-0",
                  findColor(state)?.toLowerCase() === "#efeeec" &&
                    "after:content-[''] after:w-full after:h-[2px] after:bg-white after:absolute after:top-1/2 after:-translate-y-1/2 after:left-0 after:rotate-45 after:origin-center",
                  findColor(state)?.toLowerCase() === "#ffffff" &&
                    "border border-gray"
                )}
                style={{ backgroundColor: findColor(state) }}
              />
            ))}
          <span
            className="leading-[14px] translate-y-[1px] m-0 whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: findName(state) }}
          />
        </span>
        <span className="inline-block">
          <FontAwesomeIcon
            icon={["fal", "chevron-down"]}
            className="text-gray-light"
          />
        </span>
      </button>
      {showList && (
        <div className="absolute top-full w-full border-b border-x border-gray">
          <div
            ref={listRef}
            className="bg-white px-3 py-[15px] flex flex-col relative z-50 max-h-[200px] overflow-auto"
            onScroll={onScrollHandler}
          >
            {data.map((dataItem, index) => (
              <SelectItem
                key={index}
                dataItem={dataItem}
                value={dataItem}
                color={dataItem.color}
                frameSwatch={dataItem?.frameSwatch ?? false}
                type={type}
                dataLength={data?.length}
                setStateCallbackHandler={setStateCallbackHandler}
                setShowList={setShowList}
              >
                {dataItem.name}
              </SelectItem>
            ))}
          </div>
          {showListArrow && (
            <span className="absolute bottom-0 left-0 right-4 z-[51] flex justify-center bg-white py-1">
              <FontAwesomeIcon
                icon={["fal", "chevron-down"]}
                className="text-xs translate-x-2"
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
