'use strict';

import React from 'react';
import _ from 'lodash';

import FavouritesModalComponent from './../components/FavouritesModalComponent';

class FavouritesModal extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return(
      <FavouritesModalComponent />
    )
  }
}

export default FavouritesModal;
