import _ from 'underscore';
import { WOO_PRODUCT_LIMIT } from './globals'

// const self = {
  export const twoDigits = (n) => {
    return (n).toFixed(2);
  }
  export const randomHash = () => {
    let min, max;
    min = 1;
    max = 999;
    return Math.random() * (max - min) + min;
  }
  export const multipleValues = (query) => {
    let returnArr = [];
    _.mapObject(query, (val, key) => {
      val = val.split('&');
      val.map( (entry) => {
        returnArr.push(entry);
      })
    });
    return returnArr;
  }
  export const urlStrToJSON = (str) => {
        let json;
        str = str.substring(1);
        json = str ? JSON.parse('{"' + str.replace(/&/g, '","').replace(/=/g,'":"') + '"}',
                (key, value) => {
                  return key===""?value:decodeURIComponent(value)
                }):{}
      return json;
    }
    export const JSONtoUrlStr = (json) => {
        let str;
        str = Object.keys(json).map( (key) => {
            let k = decodeURIComponent(key),
                value = decodeURIComponent(json[k]);
            return encodeURIComponent(k) + '=' + encodeURIComponent(value);
        }).join('&');
        str = '?'+str;
        return str;
    }
    export const prepareFilterAndPaginationParams = ({filterParams, page = 1, search, limit = WOO_PRODUCT_LIMIT, mainGrid = true, favouriteIds = [], product_ids = [], favouritesHash = false}) => {
      let urlParams = {}
      urlParams.page = page
      urlParams.limit = limit
      if (search) {
        // @TODO handle search string
        urlParams.s = search
      }
      if (filterParams) {
        urlParams = Object.assign(urlParams, filterParams)
      }
      if (mainGrid) {
        urlParams.main_grid = mainGrid
      } else {
        urlParams.main_grid = false
      }
      // console.log('lets check the prep filter', favouriteIds, favouritesHash)
      if (favouritesHash) {
        urlParams.favourites_hash = favouritesHash
        urlParams.main_grid = false
      } else if(!_.isEmpty(favouriteIds)) {
        urlParams.favourite_ids = favouriteIds.join()
        urlParams.main_grid = false
      }
      if (!_.isEmpty(product_ids)) {
        urlParams.product_ids = product_ids.join()
        urlParams.main_grid = false
      }
      const urlQuery = JSONtoUrlStr(urlParams)
      return urlQuery
    }
// }