'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SearchFormComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            searchString: props.searchString,
            open: false
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeEsc = this.closeEsc.bind(this);
        this.closeClick = this.closeClick.bind(this);
        this.atFadeEnd = this.atFadeEnd.bind(this);
    }
    componentDidMount(){
        document.addEventListener('keydown', this.closeEsc);
        this.searchInput.focus();
        this.setState({open: true})
    }
    componentWillUnmount(){
        document.removeEventListener('keydown', this.closeEsc);
    }
    handleInput(e) {
        let value = e.target.value;
        this.setState({searchString: value});
    }
    handleSubmit(e) {
        e.preventDefault();
        let { searchString } = this.state;
        if(searchString){
            this.props.handleSubmit(this.state.searchString);
            // this.props.toggleSearch();
            this.setState({
              open: false
            });
        }
    }
    closeEsc(e) {
        if(e.keyCode === 27) {
            // this.props.toggleSearch();
            this.setState({
              open: false
            });
        }
    }
    closeClick() {
      this.setState({
        open: false
      });
    }
    atFadeEnd() {
      if(!this.state.open) {
        this.props.toggleSearch();
      }
    }
    render() {
        let classes = classNames({
          'search-input': true,
          'search-input-fullscreen': true,
          'container-fluid': true,
          'open': this.state.open
        })
        return(
            <div className={classes} onTransitionEnd={this.atFadeEnd}>
                <div className="container-fluid">
                    <form onSubmit={this.handleSubmit} className="col-xs-5" role="search">
                        <input
                            ref={(input) => {this.searchInput = input;}}
                            value={this.state.searchString}
                            onChange={this.handleInput}
                            type="text"
                            className="col-xs-12"
                            id="s"
                            name="s"
                            autoComplete="off"
                            placeholder="Press enter to search..."
                        />
                    </form>
                    <a href="#" onClick={this.closeClick} className="search-close col-xs-12">
                        <img src ="/wp-content/themes/posterclub/img/close.svg" />
                    </a>
                </div>
            </div>
        )
    }
}

SearchFormComponent.displayName = 'SearchFormComponent';
SearchFormComponent.propTypes = {
    searchString: PropTypes.string,
    toggleSearch: PropTypes.func
}
SearchFormComponent.defaultProps = {
    searchString: '',
    toggleSearch: () => {}
}

export default SearchFormComponent;
