'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ImageComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: props.loaded
        };
        this.imageLoad = this.imageLoad.bind(this);
    }
    imageLoad(e) {
        this.setState({loaded: true});
        this.props.loadedCallback(true);
    }
    render() {
        let classes;
        classes = this.props.classes;
        if(this.state.loaded && classes.indexOf('loaded') == -1){
            classes.push('loaded');
        }
        classes = classes.join(' ');

        return(
            <img
                onLoad={this.imageLoad}
                className={classes}
                src={this.props.src}
                // srcset={`${this.props.mobileSrc} 555w, ${this.props.src} 800w`}
                // sizes="(max-width: 639px) 555px, 800px"
                alt={this.props.alt}
                title={this.props.title}
                height={this.props.height}
                width={this.props.width}
                style={{pointerEvents: 'none'}}
            />
        )
    }
}

ImageComponent.displayName = 'ImageComponent';

ImageComponent.propTypes = {
    loaded: PropTypes.bool,
    src: PropTypes.string,
    mobileSrc: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    classes: PropTypes.array,
    loadedCallback: PropTypes.func
};
ImageComponent.defaultProps = {
    loaded: false,
    src: '',
    mobileSrc: '',
    alt: '',
    title: '',
    height: 500,
    width: 450,
    classes: [],
    loadedCallback: ()=>{}
};

export default ImageComponent;
