'use strict';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import FavouritesShareButtonComponent from './../components/FavouritesShareButtonComponent';

class FavouritesShareButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { favourites, products, text } = this.props
    console.log( 'favourties share button', this.props )
    const hasFavourites = !_.isEmpty(favourites.favourites) ? true : false
    const isLoaded = !products.isFetching && !products.isFailed ? true : false

    return(
      <div className={classNames( 'react-favourites-share-button', { 'react-favourites-share-button--show': isLoaded })}>
        {hasFavourites && <span className="share-explainer">{this.props.text}</span>}
        {hasFavourites && <FavouritesShareButtonComponent />}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites,
    products: state.products,
  }

  return props;
}

export default connect(mapStateToProps, null)(FavouritesShareButton);
