import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import store, { history } from "./stores";
import { ConnectedRouter } from "connected-react-router";
import SidebarFilter from "./containers/SidebarFilter";
import Search from "./containers/Search";
// import SearchToggle from './containers/SearchToggle'
import NewsletterContainer from "./containers/Newsletter";
import FavouritesShareButton from "./containers/FavouritesShareButton";
import FavouritesBuyButton from "./containers/FavouritesBuyButton";
import FavouritesModal from "./containers/FavouritesModal";
import FavouritesMenuIcon from "./containers/FavouritesMenuIcon";
import FavouritesNotifications from "./containers/FavouritesNotifications";
import BuyError from "./containers/BuyError";
import App from "./containers/App";
import Header from "./containers/Header";
// import Hero from "./containers/Hero";
import LoadMoreHomestories from "./containers/LoadMoreHomestories";
import ArtWall from "./containers/ArtWall";
import "./fontAwesome";
import Footer from "./containers/Footer";
import TopBar from "./containers/TopBar";
import SingleProductContainer from "./containers/SingleProduct";

const initReact = () => {
  const mainTarget = document.getElementById("app");
  const sidebarTarget = document.getElementById("mobile-filter-mount");
  const sidebarSearchTarget = document.getElementById("mobile-search");
  const searchOverlay = document.getElementById("search_overlay");
  // const searchToggle = document.getElementById('search_toggle')
  const newsletterTarget = document.getElementById("newsletter-mount");
  const newsletterShortcodeTarget = document.getElementById(
    "shortcode-newsletter-mount"
  );
  const favouritesShareButtonTarget =
    document.getElementById("favourites-share");
  const favouritesShareButtonText = document.getElementById("favourites-share")
    ? document.getElementById("favourites-share").getAttribute("data-text")
    : "";
  const favouritesBuyButtonTarget = document.getElementById(
    "favourites-buy-button"
  );
  const favouritesModalTarget = document.getElementById("favourites-modal");
  const favouritesMenuIconTarget = document.getElementById(
    "favourites-menu-icon"
  );
  const buyErrorTarget = document.getElementById("buy-error");
  const favouritesNotificationsTarget = document.getElementById(
    "favourites-notifications"
  );
  const headerTarget = document.getElementById("header");
  const heroTarget = document.getElementById("hero");
  const footerTarget = document.getElementById("footer");
  const topBarTarget = document.getElementById("top-bar");
  const loadMoreHomestories = document.getElementById("load-more-homestories");
  // const artWallTarget = document.getElementById('art-wall')

  const singleProductTarget = document.getElementById("single-product-app");

  if (mainTarget) {
    const page = mainTarget.dataset ? mainTarget.dataset.page : false;

    const root = createRoot(mainTarget);
    root.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/artprints" component={App} />
            <Route path="/frames" component={App} />
            <Route path="/outlet" component={App} />
            <Route path="/art-cards" component={App} />
            <Route path="/framepaint" component={App} />
            <Route path="/original-art" component={App} />
            <Route path="/product/:slug" component={App} />
            <Route path="/artists/:slug" component={App} />
            <Route path="/categories/:slug" component={App} />
            <Route path="/favourites" component={App} />
            <Route path="/tpc-frames" component={App} />
            <Route path="/other-frames" component={App} />
            <Route path="/aluminium-frames" component={App} />
            <Route path="/oak-frames" component={App} />
            <Route path="/moebe-frames" component={App} />
            <Route path="/coloured-frames" component={App} />
            <Route path="/collection/:slug" component={App} />
            <Route path="/art-wall-designer" component={ArtWall} />
            <Route path="/*">
              <App page={page} />
            </Route>
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }

  if (singleProductTarget) {
    const root = createRoot(singleProductTarget);
    root.render(<SingleProductContainer />);
  }

  if (topBarTarget) {
    const root = createRoot(topBarTarget);
    root.render(
      <Provider store={store}>
        <TopBar />
      </Provider>
    );
  }

  if (headerTarget) {
    const root = createRoot(headerTarget);
    root.render(
      <Provider store={store}>
        <Header />
      </Provider>
    );
  }

  if (footerTarget) {
    const root = createRoot(footerTarget);
    root.render(
      <Provider store={store}>
        <Footer />
      </Provider>
    );
  }

  if (loadMoreHomestories) {
    const root = createRoot(loadMoreHomestories);
    root.render(
      <Provider store={store}>
        <LoadMoreHomestories />
      </Provider>
    );
  }

  if (favouritesShareButtonTarget) {
    const root = createRoot(favouritesShareButtonTarget);
    root.render(
      <Provider store={store}>
        <FavouritesShareButton text={favouritesShareButtonText} />
      </Provider>
    );
  }

  if (favouritesBuyButtonTarget) {
    const root = createRoot(favouritesBuyButtonTarget);
    root.render(
      <Provider store={store}>
        <FavouritesBuyButton />
      </Provider>
    );
  }

  if (favouritesModalTarget) {
    const root = createRoot(favouritesModalTarget);
    root.render(
      <Provider store={store}>
        <FavouritesModal />
      </Provider>
    );
  }

  if (favouritesMenuIconTarget) {
    const root = createRoot(favouritesMenuIconTarget);
    root.render(
      <Provider store={store}>
        <FavouritesMenuIcon />
      </Provider>
    );
  }

  if (favouritesNotificationsTarget) {
    const root = createRoot(favouritesNotificationsTarget);
    root.render(
      <Provider store={store}>
        <FavouritesNotifications />
      </Provider>
    );
  }

  if (buyErrorTarget) {
    const root = createRoot(buyErrorTarget);
    root.render(
      <Provider store={store}>
        <BuyError />
      </Provider>
    );
  }

  if (sidebarTarget) {
    const root = createRoot(sidebarTarget);
    root.render(
      <Provider store={store}>
        <SidebarFilter />
      </Provider>
    );
  }

  if (sidebarSearchTarget) {
    const root = createRoot(sidebarSearchTarget);
    root.render(
      <Provider store={store}>
        <Search position="sidebar" />
      </Provider>
    );
  }

  if (searchOverlay) {
    const root = createRoot(searchOverlay);
    root.render(
      <Provider store={store}>
        <Search position="header" />
      </Provider>
    );
  }

  if (newsletterTarget) {
    const root = createRoot(newsletterTarget);
    root.render(<NewsletterContainer type="menu" />);
  }

  if (newsletterShortcodeTarget) {
    const type = newsletterShortcodeTarget.dataset.type;

    const root = createRoot(newsletterShortcodeTarget);
    root.render(<NewsletterContainer type={type} />);
  }
};

export default initReact;
