import CurrencyPicker from "./currencyPicker/CurrencyPicker"

const TopBarComponent = () => {
  return (
    <div>
      <div className="flex flex-col">
        <CurrencyPicker />
      </div>
    </div>
  )
}

export default TopBarComponent