'use strict';

import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history'
// import { browserHistory } from 'react-router';
// import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './../reducers';
import { routerMiddleware } from 'connected-react-router'

// const redux = require('redux');

export const history = createBrowserHistory();

// const routerMiddle = routerMiddleware(browserHistory);

const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunkMiddleware,
        ),
    ),
);

export default store;
