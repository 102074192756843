'use strict';

import React from 'react';
import { connect } from 'react-redux';
import BuyErrorComponent from './../components/BuyErrorComponent'

class BuyError extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { buyError } = this.props

    return(
      <div className="favourites-error">
        {buyError && <BuyErrorComponent />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    buyError: state.favourites.buyError
  }

  return props;
}


export default connect(mapStateToProps, null)(BuyError);
