'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { generateFilterUrl } from './../lib/filter';
import classNames from 'classnames';

class FilterAttributeComponent extends Component {
    render() {
        let { attribute, slug, search, listClass } = this.props;
        let attrName = {__html: attribute.name},
            url, urlSlug, isSizeCat;
        let liClass = classNames({
          'currently-selected': this.props.isSelected
        });
        isSizeCat = attribute.taxonomy == 'pa_size-category';
        
        urlSlug = isSizeCat ? 'size_category' : slug;
        urlSlug = slug === 'collection' ? 'collections' : urlSlug;
        url = generateFilterUrl(urlSlug, attribute, search);
        return(
            <li className={liClass} data-type={ attribute['size_type'] || '' }>
                <Link onClick={this.props.trackClick} to={url} dangerouslySetInnerHTML={attrName}></Link>
            </li>
        )
    }
}

FilterAttributeComponent.displayName = 'FilterAttributesComponent';

FilterAttributeComponent.propTypes = {
    attribute: PropTypes.shape({
        term_id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        term_group: PropTypes.number,
        term_taxonomy_id: PropTypes.number,
        taxonomy: PropTypes.string,
        description: PropTypes.string,
        parent: PropTypes.number,
        count: PropTypes.number,
        filter: PropTypes.string
    })
}
FilterAttributeComponent.defaultProps = {
    attribute: {
        term_id: '',
        name: '',
        slug: '',
        term_group: 0,
        term_taxonomy_id: 0,
        taxonomy: '',
        description: '',
        parent: 0,
        count: 0,
        filter: ''
    }
}

let mapStateToProps = (state) => {
    const props = {
        search: state.router.location.search,
    };
    return props;
}

export default connect(mapStateToProps, null)(FilterAttributeComponent);
