let scrollTo = (element, to, duration) => {
    // if (duration <= 0) return;
    // let difference = to - element.scrollTop,
    //     perTick = difference / duration * 10,
    //     scrollTimeout = window.setTimeout(() => {
    //       element.scrollTop = element.scrollTop + perTick;
    //       if (element.scrollTop === to) {
    //         window.clearTimeout(scrollTimeout);
    //         return;
    //       }
    //       scrollTo(element, to, duration - 10);
    //     }, 10);

    jQuery('html, body').animate({
        scrollTop: to
    }, duration, 'swing');
}

let scrollToShop = () => {
  let shopAnchor = document.getElementById('shop_anchor'),
      shopAnchorOffset = document.getElementById('shop_anchor').getBoundingClientRect().top,
      scrollDuration = 500;

  scrollTo(document.body, shopAnchorOffset, scrollDuration);
}

export {scrollTo, scrollToShop};
