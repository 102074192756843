import { AnimatePresence, motion, useElementScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { useAtom } from "jotai";
import { activeProductInfoSidebarTabAtom } from "../../../store";
import { productInfoSidebarTabs } from "../../../lib/globals";
import { fadeAnimation } from "../../../animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarInner = ({ children, title, setShowSidebar, extraCallbacks }) => {
  useLockBodyScroll();

  // const [showScrollOpacityOverlay, setShowScrollOpacityOverlay] =
  //   useState(false);

  // const scrollableContainerRef = useRef(null);

  // const { scrollYProgress } = useElementScroll(scrollableContainerRef);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (scrollableContainerRef?.current) {
  //       console.log(
  //         "scrollableContainerRef.current.scrollHeight",
  //         scrollableContainerRef.current.scrollHeight
  //       );
  //       console.log(
  //         "scrollableContainerRef.current.clientHeight",
  //         scrollableContainerRef.current.clientHeight
  //       );
  //       if (
  //         scrollableContainerRef.current.scrollHeight -
  //           scrollableContainerRef.current.clientHeight >
  //         0
  //       ) {
  //         setShowScrollOpacityOverlay(true);
  //       } else {
  //         setShowScrollOpacityOverlay(false);
  //       }
  //     }
  //   }, 0);

  //   return () => clearTimeout(timeoutId);
  // }, [scrollableContainerRef]);

  // scrollYProgress.onChange((latest) => {
  //   console.log("latest", latest);

  //   if (latest >= 1) {
  //     setShowScrollOpacityOverlay(false);
  //   } else {
  //     setShowScrollOpacityOverlay(true);
  //   }
  // });

  const [activeProductInfoSidebarTab, setActiveProductInfoSidebarTab] = useAtom(
    activeProductInfoSidebarTabAtom
  );

  return (
    <div className="h-[inherit] w-full flex py-9 sm:py-12 flex-col">
      <div className="flex justify-between px-5 sm:px-8 pb-5 sm:pb-12">
        {title ? (
          <div className="relative flex items-center">
            <span className="product-info-sidebar-tabs font-display-regular tracking-[0.8px] font-normal uppercase text-black">
              {title}
            </span>
            <div className="bg-black absolute left-0 product-info-side-bar-tab-underline w-full h-[1px]" />
          </div>
        ) : (
          <div className="gap-4 flex">
            {productInfoSidebarTabs
              ?.filter((productInfoSidebarTab) =>
                isWallObjectsProduct || isLimitedEditionsProduct
                  ? productInfoSidebarTab?.slug !==
                      productInfoSidebarTabs?.[0]?.slug &&
                    productInfoSidebarTab?.slug !==
                      productInfoSidebarTabs?.[1]?.slug
                  : productInfoSidebarTab
              )
              ?.filter((productInfoSidebarTab) =>
                isFrameProduct
                  ? productInfoSidebarTab?.slug !==
                    productInfoSidebarTabs?.[1]?.slug
                  : productInfoSidebarTab
              )
              ?.map((productInfoSidebarTab) =>
                isPassepartoutProduct &&
                productInfoSidebarTab?.slug ===
                  productInfoSidebarTabs?.[1]?.slug
                  ? {
                      slug: "frame-and-passepartout",
                      tabTitle: "Frame",
                      mainTitle: "Frame Details",
                    }
                  : productInfoSidebarTab
              )
              ?.map(({ tabTitle, slug }, index) => (
                <button
                  className="relative flex items-center"
                  key={index}
                  onClick={() => setActiveProductInfoSidebarTab(slug)}
                >
                  <span className="product-info-sidebar-tabs font-display-regular tracking-[0.8px] font-normal uppercase text-black">
                    {tabTitle}
                  </span>
                  <AnimatePresence exitBeforeEnter>
                    {activeProductInfoSidebarTab === slug && (
                      <motion.div
                        key="product-info-side-bar-tab-underline"
                        className="bg-black absolute left-0 product-info-side-bar-tab-underline w-full h-[1px]"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={fadeAnimation}
                        layoutId="product-info-side-bar-tab-underline"
                        transition={{
                          type: "tween",
                          duration: 0.35,
                          ease: "easeInOut",
                        }}
                      />
                    )}
                  </AnimatePresence>
                </button>
              ))}
          </div>
        )}
        <button
          className="product-info-sidebar-tabs font-display-bold font-normal uppercase tracking-wider text-black inline-block"
          onClick={() => {
            setShowSidebar(false);

            if (extraCallbacks?.length > 0) {
              extraCallbacks.forEach((extraCallback) => {
                extraCallback();
              });
            }
          }}
        >
          <FontAwesomeIcon
            icon={["fal", "times"]}
            className="text-black w-5 h-5 inline-block mt-[2px]"
          />
        </button>
      </div>
      <div className="overflow-y-auto hide-scrollbar">{children}</div>
      {/* <div className="relative flex h-full flex-col overflow-hidden">
        <div
          ref={scrollableContainerRef}
          className="hide-scrollbar flex flex-col overflow-y-auto"
        >
          {children}
        </div>
        <AnimatePresence mode="wait">
          {showScrollOpacityOverlay && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="pointer-events-none absolute left-0 top-0 z-50 h-full w-full bg-gradient-to-t from-white to-30%"
            />
          )}
        </AnimatePresence>
      </div> */}
    </div>
  );
};

export default SidebarInner;
