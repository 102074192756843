'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toggleFilter from './../actions/filters/toggleFilter';
import setFilterParams from './../actions/filters/setFilterParams';
import setSearchString from './../actions/filters/setSearchString';
import { isEqual, has, union, isEmpty } from 'lodash';
import { urlStrToJSON } from './../lib/parse';
import { trimParams, trimSearch } from './../lib/filter';

import { sortby } from './../lib/globals';
import FilterComponent from './../components/FilterComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import ArtWallFiltersComponent from '../components/ArtWallFiltersComponent';
import ArtWallSortByComponent from '../components/ArtWallSortByComponent';
import setShowArtWallSortBy from '../actions/filters/setShowArtWallSortBy';
import setShowArtWallFilters from '../actions/filters/setShowArtWallFilters';
import setArtWallProductFeed from "../actions/artWall/setArtWallProductFeed"

class FilterContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            filtersAmount: 0,
        }

        this.toggleFilter = this.toggleFilter.bind(this);
        this.sortByHandler = this.sortByHandler.bind(this);
        this.filtersHandler = this.filtersHandler.bind(this);

        this.sortByButtonRef = React.createRef();
        this.filterButtonRef = React.createRef();
    }
    componentDidMount(){
        let { search } = this.props;
        let params = urlStrToJSON(search);
        if(has(params, 's')){
            let str = trimSearch(params);
            this.props.setSearchString(str);
        } else {
            // console.log('whats search search ??', search)
            params = trimParams(params);
            // console.log('the paramsprams', params)
            this.props.setFilterParams(params);
        }

        //console.log('did mount! here is query from router', urlStrToJSON(search));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters.showArtWallSortBy !== this.props.filters.showArtWallSortBy) {
            if (this.props.filters.showArtWallSortBy) {
                this.props.setShowArtWallFilters(false)
            }
        }

        if (prevProps.filters.showArtWallFilters !== this.props.filters.showArtWallFilters) {
            if (this.props.filters.showArtWallFilters) {
                this.props.setShowArtWallSortBy(false)
            }
        }

        if (prevProps.filters.filterParams !== this.props.filters.filterParams) {
            const { filters } = this.props
            const { filterParams } = filters

            if (filterParams && !isEmpty(filterParams) && (!has(filterParams, 'limit') || !has(filterParams, 'page'))) {
                let filterArray = []
                for (const key in filterParams) {
                    if (filterParams.hasOwnProperty(key)) {
                        // console.log('key', key);
                        // console.log('filterParams[key]', filterParams[key]);
                        if (key !== 'sortby') {                            
                            const split = filterParams[key].split('&')
                            filterArray.push(split)
                        }
                    }
                }
                const flattenedFilterArray = filterArray.flat()
                // console.log('flattenedFilterArray', flattenedFilterArray)
                
                this.setState({
                    filtersAmount: flattenedFilterArray.length
                })
            } else {
                this.setState({
                    filtersAmount: 0
                })
            }
        }
    }

    componentWillReceiveProps(nextProps){
        let { search, filters } = nextProps;
        // let { filters } = nextProps;
        let { filterParams, searchString } = filters;
        let params = urlStrToJSON(search);
        // console.log('FILTERCONT, will receive', params, search)
        if(has(params, 's') && (searchString !== params.s)){
            let str = trimSearch(params);
            this.props.setSearchString(str);
        } else if(!has(params, 's')) {

            // console.log('noot search but filter', params)
            params = trimParams(params);
            if(!isEqual(filterParams, params)){
                // console.log('ComponentWillReceiveProps: setting the filter paras!', params)
                this.props.setFilterParams(params);
            }
            if(searchString){
                this.props.setSearchString('');
            }
        }
    }

    toggleFilter(key){
        let { filters } = this.props;
        let { toggled } = filters;
        this.props.toggleFilter(key !== toggled ? key : null);
    }

    sortByHandler() {
        // this.setState({
		// 	showSortBy: !this.state.showSortBy
		// })
        // console.log('sortByHandler', this.props.filters.showArtWallSortBy);
        this.props.setShowArtWallSortBy(!this.props.filters.showArtWallSortBy)
        // setShowSortBy(prevState => !prevState)
        this.props.setArtWallProductFeed('allArtPrints')
    }
    
    filtersHandler() {
        // this.setState({
		// 	showFilters: !this.state.showFilters
		// })
        // console.log('filtersHandler', this.props.filters.showArtWallFilters);
        this.props.setShowArtWallFilters(!this.props.filters.showArtWallFilters)
        // setShowFilters(prevState => !prevState)
        this.props.setArtWallProductFeed('allArtPrints')
    }

    countFilters() {
        let { filters } = this.props
        let { filterParams } = filters

        const arr = []

        Object.keys(filterParams).map((k) => {
            if (k == 'price_range') {
                const params = Object.assign({}, filterParams)
                const attr = params[k]
                
                arr.push(attr)
            } else {        
                if (!has(filterParams, 'limit') || !has(filterParams, 'page')) {
                    console.log('k', k);   
                    console.log('filterParams', filterParams);   
                    const splitParams = filterParams?.k ? filterParams.k.split('&') : []
    
                    splitParams.map((splitParam) => {
                        arr.push(splitParam)
                    })
                }     
            }
        })

        return arr.length
    }

    render() {
        const { filters } = this.props

        const { items, filterParams } = filters

        const isArtWall = this.props.pathname.indexOf('art-wall-designer') > -1

        // console.log('countFilters', this.countFilters());

        return(
            <>
                {isArtWall
                    ? (
                        <>
                            <div className="flex bg-tpc-light relative z-50" style={{height: '44px'}}>
                                <button
                                    ref={this.sortByButtonRef}
                                    className="w-1/2 bg-transparent font-display-regular font-normal text-black flex justify-center items-center uppercase border-b border-solid border-gray rounded-none tracking-wider"
                                    style={{fontSize: '10px'}}
                                    onClick={() => this.sortByHandler()}
                                >
                                    {filters?.filterParams && has(filters.filterParams, 'sortby') ? items.sortby.filter((s) => s.term_id.toString() === filterParams.sortby)[0].name : 'Sort By'}
                                    {/* {filters?.filterParams && has(filters.filterParams, 'sortby') &&
                                        <span className="inline-block ml-1">{'(1)'}</span>
                                    } */}
                                    <FontAwesomeIcon icon={['fal', this.props.filters.showArtWallSortBy ? 'chevron-up' : 'chevron-down']} className="ml-2 block mb-3px" />
                                </button>
                                <button
                                    ref={this.filterButtonRef}
                                    className="w-1/2 bg-transparent font-display-regular font-normal text-black flex justify-center items-center uppercase border-b border-solid border-gray rounded-none tracking-wider"
                                    style={{fontSize: '10px'}}
                                    onClick={() => this.filtersHandler()}
                                >
                                    Filters
                                    {this.state.filtersAmount > 0 &&
                                        ` (${this.state.filtersAmount})`
                                    }
                                    {/* {`Filters ${this.state.filtersAmount > 0 ? `(${this.state.filtersAmount})` : ''}`} */}
                                    {/* {filters?.filterParams && !has(filters.filterParams, 'sortby') && (this.countFilters() > 0) &&
                                        <span className="inline-block ml-1">{`(${this.countFilters()})`}</span>
                                    } */}
                                    <FontAwesomeIcon icon={['fal', this.props.filters.showArtWallFilters ? 'chevron-up' : 'chevron-down']} className="ml-2 block mb-3px" />
                                </button>
                            </div>
                            <AnimatePresence>
                                {this.props.filters.showArtWallSortBy &&
                                    <motion.div
                                        key="sortBy"
                                        initial={{y: '-100%'}}
                                        animate={{y: 0}}
                                        exit={{y: '-100%'}}
                                        transition={{type: 'tween'}}
                                        className="absolute w-full z-40 border-b border-solid border-gray bg-tpc-light"
                                    >
                                        <ArtWallSortByComponent filters={filters} buttonRef={this.sortByButtonRef} />
                                    </motion.div>
                                }
                            </AnimatePresence>
                            <AnimatePresence>
                                {this.props.filters.showArtWallFilters &&
                                    <motion.div
                                        key="filters"
                                        initial={{y: '-100%'}}
                                        animate={{y: 0}}
                                        exit={{y: '-100%'}}
                                        transition={{type: 'tween'}}
                                        className="absolute w-full z-40 border-b border-solid border-gray bg-tpc-light"
                                    >
                                        <ArtWallFiltersComponent filters={filters} buttonRef={this.filterButtonRef} />
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </>
                    )
                    : <FilterComponent filters={filters} toggleFilter={this.toggleFilter} />
                }
            </>
        )
    }
}

let mapStateToProps = (state) => {
    let { filters } = state;
    let { items } = filters;
    items.sortby = sortby;
    items.size = union(items.size, items.size_category);
    // console.log('the search from state to props', search)
    const props = {
        filters: filters,
        search: state.router.location.search,
        pathname: state.router.location.pathname,
    };

    return props;
}

let mapDispatchToProps = (dispatch) => {
    let actionMap = {
        toggleFilter: (attribute) => {
            dispatch(toggleFilter(attribute))
        },
        setFilterParams: (filterParams) => {
            dispatch(setFilterParams(filterParams))
        },
        setSearchString: (searchString) => {
            dispatch(setSearchString(searchString))
        },
        setShowArtWallSortBy: (showArtWallSortBy) => {
            dispatch(setShowArtWallSortBy(showArtWallSortBy))
        },
        setShowArtWallFilters: (showArtWallFilters) => {
            dispatch(setShowArtWallFilters(showArtWallFilters))
        },
        setArtWallProductFeed: (artWallProductFeed) => {
            dispatch(setArtWallProductFeed(artWallProductFeed))
        }
    }

    return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
