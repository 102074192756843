'use strict';

import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import setBuyError from '../actions/favourites/setBuyError';
import setAddedToCart from '../actions/favourites/setAddedToCart';
import _ from 'lodash';
import outsideClick from '../lib/clickOutside'
import { warpFragmentRefresh } from '../modules/ajaxAddToCart';
import mountMiniCart from '../modules/miniCart';
class FavouritesBuyButtonComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingToCartText: '',
      goToBag: false
    }

    this.addFavouritesToBag = this.addFavouritesToBag.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.favourites !== this.props.favourites) {
      const { favourites } = this.props

      if (prevProps.favourites !== favourites && this.state.goToBag === true) {
        this.setState({
          goToBag: false,
          addingToCartText: '',
        })
      }

      if (!favourites.find(f => f.isVariable && !f.variationId)) {
        this.props.actions.setBuyError(false)
      }
    }
  }

  handleAjaxAddToCart(e) {
    const clickMaster = new outsideClick()
    const { favourites } = this.props

    const productIds = favourites.map(f => !!f.variationId ? parseInt(f.variationId) : parseInt(f.id))
    const productIdsString = productIds.join()

    this.setState({
      addingToCartText: 'Adding',
    })

    jQuery.post(window.location, `add-to-cart=${productIdsString}&_wp_http_referer=${window.location}`, (result) => {
      // const cart_dropdown = jQuery('#mini-cart', result)
      // const cart_icon = jQuery('#cart-contents', result)
      const woocommerce_success = jQuery('.woocommerce-message', result)
      const woocommerce_error = jQuery('.woocommerce-error', result)
      let hasError

      if (woocommerce_error.length > 0) {
        hasError = true
      } else if (woocommerce_success.length > 0) {
        hasError = false
      }
  
      if (hasError) {
        jQuery('.type-product').eq(0).before(woocommerce_error)
      } else {
        const errorContainer = jQuery('.woocommerce-error')

        if (errorContainer.length > 0) {
          jQuery(errorContainer).remove()
        }

        // const hiddenMinicart = document.querySelector('.hidden-minicart')
        // jQuery('#mini-cart').replaceWith(hiddenMinicart)

        const hiddenMinicart = document.getElementById('mini-cart-hidden')
        const hiddenMinicartClone = hiddenMinicart.cloneNode(true)
        hiddenMinicartClone.setAttribute('id', 'mini-cart')
        jQuery('#mini-cart').replaceWith(hiddenMinicartClone)
        // jQuery('#cart-contents').replaceWith(cart_icon)
      }

      jQuery.ajax(warpFragmentRefresh)

      mountMiniCart(clickMaster)

      this.setState({
        addingToCartText: 'All items added',
      })
      this.props.actions.setAddedToCart(true)

      setTimeout(() => {
        this.setState({
          goToBag: true,
          addingToCartText: 'Go to bag',
        })
      }, 2500)
    })
  }

  addFavouritesToBag(e) {
    const { favourites } = this.props

    if (favourites.some(f => f.isVariable && !f.variationId)) {
      this.props.actions.setBuyError(true)
    } else {
      this.state.goToBag ? window.location.href = `${window.location.origin}/cart` : this.handleAjaxAddToCart(e)
    }
  }

  render() {
    const { buyError, favourites } = this.props
    const { addingToCartText } = this.state
    // console.log('the favourites', favourites)

    return(
      favourites &&
      <div className='favourites-button-flex-wrapper'>
        <button className='favourites-buy-button ajax-add-to-cart' disabled={buyError} style={{cursor: buyError ? 'no-drop' : 'pointer', transform: buyError && 'scale(1)'}} onClick={(e) => this.addFavouritesToBag(e)}>{buyError ? 'Select size for all items' : !!addingToCartText ? addingToCartText : 'Add all items to bag'}</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites.favourites,
    buyError: state.favourites.buyError,
  }

  return props;
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setBuyError,
    setAddedToCart
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesBuyButtonComponent);