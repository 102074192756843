import PropTypes from "prop-types"
import { connect } from "react-redux"
import { has } from "lodash"
import { urlStrToJSON } from "../lib/parse"
import ArtWallProductComponent from "./ArtWallProductComponent"
import ArtWallSpinner from "./ArtWallSpinner"
// import Loading from './LoadingComponent';
// import { motion } from "framer-motion"

const ArtWallProductsComponent = ({ products, favourites, search }) => {

  const items = products.items
  const favouritesFromHash = products.favouritesFromHash
  const searchParams = urlStrToJSON(search)
  
  let currentItems = items

  if (pathname.includes('favourites')) {
    if (!has(searchParams, 'h')) {
      const favouriteIds = favourites.map(favourite => favourite.id)
      currentItems = items.filter(item => favouriteIds.includes(item.id))
    }
  }

  return (
    <>
      {
        !products.isFetching && !products.isFailed
        ? (currentItems.length > 0 &&
          <div className="mt-3 pb-3">
            <ul className="flex flex-wrap -ml-3 -mt-3 overflow-y-auto">
              {currentItems.map((item, index) => <ArtWallProductComponent key={index} product={item} favouritesFromHash={favouritesFromHash} />)}
            </ul>
            {products.isFetchingMore &&
              <div className="py-10">
                <ArtWallSpinner />
              </div>
            }
          </div>
        )
        : <ArtWallSpinner />
      }
    </>
  )
}

ArtWallProductsComponent.propTypes = {
	products: PropTypes.shape({
		items: PropTypes.array,
		isFetching: PropTypes.bool,
		isFailed: PropTypes.bool,
	}),
}

ArtWallProductsComponent.defaultProps = {
	products: {
		items: [],
		isFetching: false,
		isFailed: false,
	},
}

const mapStateToProps = (state) => {
	const props = {
		favourites: state.favourites.favourites,
		pathname: state.router.location.pathname,
		search: state.router.location.search,
	}

	return props
}

export default connect(mapStateToProps, null)(ArtWallProductsComponent)