import { useSetAtom } from "jotai";
import { manualClickAtom } from "../../store";
import { isEmpty } from "../../utilities/isEmpty";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useEffect, useRef, useState } from "react";
import useOnClickOutsideExtended from "../../hooks/useOutsideClickExtended";
import { isWithinRange } from "../../lib/helpers";

const SelectItem = forwardRef(
  (
    {
      children,
      className,
      color,
      frameSwatch,
      type,
      setStateCallbackHandler,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <button
        className={clsx(
          "outline-none font-display-regular font-normal text-xs leading-none text-black tracking-[0.5px] uppercase py-3 cursor-pointer hover:text-gray-light transition-colors duration-200 ease-in-out text-left",
          type !== "size" && "flex items-center gap-2",
          className
        )}
        onClick={() => setStateCallbackHandler(props.value)}
        {...props}
        ref={forwardedRef}
      >
        {type !== "size" &&
          (frameSwatch ? (
            <img
              src={frameSwatch?.[0]}
              className="block rounded-full overflow-hidden shrink-0 grow-0"
              width={24}
              height={24}
              alt=""
            />
          ) : (
            <div
              className={clsx(
                "w-6 h-6 rounded-full relative overflow-hidden shrink-0 grow-0",
                color?.toLowerCase() === "#efeeec" &&
                  "after:content-[''] after:w-full after:h-[2px] after:bg-white after:absolute after:top-1/2 after:-translate-y-1/2 after:left-0 after:rotate-45 after:origin-center",
                color?.toLowerCase() === "#ffffff" && "border border-gray"
              )}
              style={{ backgroundColor: color }}
            />
          ))}
        <span
          className="translate-y-[1px] leading-[14px] whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: children }}
        />

        {/* <Select.ItemIndicator className="absolute top-0 left-0 w-6 h-full flex justify-center items-center">
          <FontAwesomeIcon icon={["fal", "check"]} />
        </Select.ItemIndicator> */}
      </button>
    );
  }
);

const CustomSelectDrawer = ({
  placeholder,
  data,
  state,
  setStateCallback,
  type,
}) => {
  const [open, setOpen] = useState(false);

  const buttonRef = useRef(null);
  const drawerRef = useRef(null);

  const setManualClick = useSetAtom(manualClickAtom);

  const findFrameSwatch = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)
      ? data.find(({ slug }) => slug === value)?.frameSwatch
      : false;
  };

  const findName = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)?.name
      ? data.find(({ slug }) => slug === value)?.name
      : placeholder;
  };

  const findColor = (value) => {
    if (isEmpty(data)) return;

    return data.find(({ slug }) => slug === value)?.color
      ? data.find(({ slug }) => slug === value)?.color
      : "none";
  };

  const setStateCallbackHandler = (value) => {
    setManualClick(data?.[0]?.type);
    setStateCallback(value);
    setOpen(false);
  };

  useOnClickOutsideExtended(drawerRef, buttonRef, () => setOpen(false));

  const [drawerListHeight, setDrawerListHeight] = useState("auto");

  useEffect(() => {
    const height = window.innerHeight;
    const drawerButtonHeight = 44;
    const drawerListHeightMax = height / 2 - drawerButtonHeight;
    const _drawerListHeight =
      data.length * (type === "size" ? 36 : 48) +
      (data.length - 1) * 8 +
      24 * 2;

    if (_drawerListHeight > drawerListHeightMax) {
      setDrawerListHeight(drawerListHeightMax);
    } else {
      setDrawerListHeight("auto");
    }
  }, [open]);

  const listRef = useRef(null);

  const [showListArrow, setShowListArrow] = useState(true);

  useEffect(() => {
    if (listRef?.current && open) {
      console.log(
        "listRef",
        listRef?.current?.scrollHeight,
        listRef?.current?.clientHeight
      );

      if (listRef?.current?.scrollHeight > listRef?.current?.clientHeight) {
        setShowListArrow(true);
      } else if (
        listRef?.current?.scrollHeight === listRef?.current?.clientHeight
      ) {
        setShowListArrow(false);
      }
    }
  }, [listRef, open]);

  const onScrollHandler = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight === clientHeight) return;

    console.log(
      "scrollTop, scrollHeight, clientHeight",
      scrollTop,
      scrollHeight,
      clientHeight
    );
    if (isWithinRange(scrollHeight - scrollTop, clientHeight, 1)) {
      console.log("reached the bottom");
      setShowListArrow(false);
    } else {
      setShowListArrow(true);
    }
  };

  return (
    <>
      <button
        ref={buttonRef}
        className="border border-gray px-3 h-11 w-full outline-none flex sm:hidden items-center justify-between font-display-regular font-normal text-xs leading-none text-black tracking-[0.5px] uppercase"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span
          className={clsx(
            type !== "size" &&
              findColor(state) !== "none" &&
              "flex gap-2 items-center text-left"
          )}
        >
          {type !== "size" &&
            findColor(state) !== "none" &&
            (findFrameSwatch(state) ? (
              <img
                src={findFrameSwatch(state)?.[0]}
                className="block rounded-full overflow-hidden shrink-0 grow-0"
                width={24}
                height={24}
                alt=""
              />
            ) : (
              <div
                className={clsx(
                  "w-6 h-6 rounded-full relative overflow-hidden shrink-0 grow-0",
                  findColor(state)?.toLowerCase() === "#efeeec" &&
                    "after:content-[''] after:w-full after:h-[2px] after:bg-white after:absolute after:top-1/2 after:-translate-y-1/2 after:left-0 after:rotate-45 after:origin-center",
                  findColor(state)?.toLowerCase() === "#ffffff" &&
                    "border border-gray"
                )}
                style={{ backgroundColor: findColor(state) }}
              />
            ))}
          <span
            className="leading-[14px] translate-y-[1px] whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: findName(state) }}
          />
        </span>
        <FontAwesomeIcon
          icon={["fal", "chevron-down"]}
          className="text-gray-light"
        />
      </button>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              className="fixed top-0 left-0 z-[60] w-full bg-black bg-opacity-30 h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "tween" }}
            />
            <motion.div
              ref={drawerRef}
              className="select-drawer fixed z-[60] inset-x-0 bottom-0 w-full bg-white max-h-[50vh]"
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "tween" }}
            >
              <button
                className="border-y border-gray px-[33px] h-11 w-full outline-none flex sm:hidden items-center justify-between font-display-regular font-normal text-xs leading-none text-black tracking-[0.5px] uppercase text-left"
                onClick={() => setOpen(false)}
              >
                {findName(state)}
                <FontAwesomeIcon
                  icon={["fal", "chevron-up"]}
                  className="text-gray-light ml-2"
                />
              </button>
              <div
                className="flex flex-col gap-2 py-6 px-[33px] overflow-y-auto"
                style={{ height: `${drawerListHeight}px` }}
                ref={listRef}
                onScroll={onScrollHandler}
              >
                {data.map((dataItem, index) => (
                  <SelectItem
                    key={index}
                    value={dataItem}
                    color={dataItem.color}
                    frameSwatch={dataItem?.frameSwatch ?? false}
                    type={type}
                    setStateCallbackHandler={setStateCallbackHandler}
                  >
                    {dataItem.name}
                  </SelectItem>
                ))}
              </div>
              {showListArrow && (
                <span className="absolute bottom-0 left-0 right-4 z-[51] flex justify-center bg-white py-1">
                  <FontAwesomeIcon
                    icon={["fal", "chevron-down"]}
                    className="text-xs translate-x-2"
                  />
                </span>
              )}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default CustomSelectDrawer;
