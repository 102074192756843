import React, { Component } from 'react';
import Loading from './LoadingComponent';

class NewsletterPopupComponent extends Component {
	render() {
		const responseClass = this.props.hasError ? 'clear error-message' : 'clear info-message';
		return (
			<div className="sgpb-mailchimp-23140">
				<form className="sgpbmMailchimpForm" onSubmit={this.props.handleSubmit}>
					<div className="mc-field-group">
						<label class="sgpb-label">Email Address</label>
						<input type='email' name='email' value={this.props.value} onChange={this.props.changeFunc} onBlur={this.props.changeFunc} className='newsletter__input' />
					</div>
					<div className="mc-field-group sg-submit-wrapper">
						<input type="submit" id="sgpbm-mce-subscribe" value="subscribe" />
					</div>
					{ this.props.isSubmitting ? <Loading /> : null}
						{ this.props.hasError || this.props.isAdded ?
						<div className={responseClass}>
							{this.props.response}
						</div>
						: null}
				</form>
			</div>
		)
	}
}

export default NewsletterPopupComponent