import { isEmpty } from "lodash";

const getFirstSizeValue = (stringValue) => {
  if (stringValue.toUpperCase().includes("A")) {
    try {
      return parseInt(
        stringValue.split("(")[1].toLowerCase().split("x")[0].trim()
      );
    } catch (error) {
      return stringValue;
    }
  } else if (stringValue.toLowerCase().includes("x")) {
    try {
      return parseInt(stringValue.split("x")[0].trim());
    } catch (error) {
      return stringValue;
    }
  } else if (
    !stringValue.toLowerCase().includes("x") &&
    !stringValue.toUpperCase().includes("A") &&
    !stringValue.toLowerCase().includes("cm")
  ) {
    try {
      return parseInt(stringValue);
    } catch (error) {
      return stringValue;
    }
  } else {
    return stringValue;
  }
};

const getSortedProductSizes = (product) => {
  if (product?.size && !isEmpty(product?.size)) {
    const arrayWithASize = product.size.filter((s) =>
      s.name.toUpperCase().includes("A")
    );
    arrayWithASize.sort(
      (a, b) => getFirstSizeValue(a.name) - getFirstSizeValue(b.name)
    );

    const arrayWithRest = product.size.filter(
      (s) => !s.name.toUpperCase().includes("A")
    );
    arrayWithRest.sort(
      (a, b) => getFirstSizeValue(a.name) - getFirstSizeValue(b.name)
    );

    const mergedArray = [...arrayWithASize, ...arrayWithRest];

    return mergedArray;
  } else {
    return product;
  }
};

export default getSortedProductSizes;
