let populateList = () => {
  var url = window.location;
  var title = jQuery('div.summary h2').first().text();
  var artist = jQuery('.product-artist').text();
  var imgSrc = jQuery('img.pswp__img ').attr('src');
  var facebook = '<li><a href="https://www.facebook.com/sharer/sharer.php?u=' + url + '" target="_blank">facebook | </a></li>';
  var twitter = '<li><a data-count="horizontal" expr:href="data:post.canonicalUrl" href="https://www.twitter.com/share" rel="nofollow" target="_blank">twitter | </a></li>';
  var pinterest = '<li><a href="https://pinterest.com/pin/create/button/?url=' + url + '&media=' + imgSrc + '&description=' + title + ' by ' + artist + ' | Poster from theposterclub.com" class="pin-it-button" count-layout="horizontal" target="_blank">pinterest</a></li>';

  jQuery('.pswp__caption--fake .pswp__caption__center').html("<ul class='social-list'>" + facebook + twitter + pinterest + "</ul>");
  jQuery('.pswp__caption--fake .pswp__caption__center').show();
/*
  let list = jQuery('.social-list');

  if(list.length > 1) {
    list.slice(1).remove();
  }*/

}

let socialList = () => {

  jQuery('.flex-viewport').on('click', function() {
    jQuery('.pswp__caption--fake .pswp__caption__center').hide();
    setTimeout(populateList,300);
  });

  jQuery('.pswp__button').on('click', function() {
    populateList();
  });

  jQuery(".pswp").keydown(function(e) {
    if (e.keyCode == 37 || e.keyCode == 39) {
      jQuery('.pswp__caption--fake .pswp__caption__center').hide();
      setTimeout(populateList,150);
    }
  });

  jQuery(window).resize(function(){setTimeout(populateList,50);});
}

export default socialList;
