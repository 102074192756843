"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
// import { push } from 'react-router-redux';
import axios from "axios";
import { get, has, omit, isEqual, isEmpty } from "lodash";
import setFilteredProducts from "./../actions/products/setFilteredProducts";
import setProductParams from "./../actions/products/setProductParams";
import setProductSearch from "./../actions/products/setProductSearch";
import fetchProducts from "./../actions/products/fetchProducts";

import { urlStrToJSON, JSONtoUrlStr } from "./../lib/parse";
import { WOO_PRODUCT_LIMIT } from "../lib/globals";

import RelatedProducts from "./RelatedProducts";
import ProductsComponent from "./../components/ProductsComponent";
import setFavourites from "../actions/favourites/setFavourites";
import ArtWallProductsComponent from "../components/ArtWallProductsComponent";

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollRestored: false,
    };

    this.getUrlParams = this.getUrlParams.bind(this);
    this.filterProducts = this.filterProducts.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.scrollToGrid = this.scrollToGrid.bind(this);
    this.restoreScroll = this.restoreScroll.bind(this);
    this.getUrlSlug = this.getUrlSlug.bind(this);
  }
  getUrlSlug() {
    const pathname = this.props.pathname;
    const slug = pathname.replace(/\//g, "");
    return slug;
  }
  componentDidMount() {
    // console.log('the prod container did mount')
    let { filters, products, query } = this.props;
    let { filterParams, searchString } = filters;

    if (!products.isFetching && !products.isFailed) {
      //console.log('lets filter products initially!', filterParams);
      if (searchString) {
        this.searchProducts(searchString);
      } else if (!isEmpty(filterParams)) {
        // console.log('its the none empty filterParams!', filterParams)
        this.filterProducts(filters);
      } else if (
        !isEmpty(query) &&
        query.hasOwnProperty("size") &&
        this.getUrlSlug().indexOf("frames") !== -1
      ) {
        this.filterProducts(filters, true);
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (window.location.search.indexOf("pos=") > -1) {
      const prevSearchQuery = get(prevProps, "query", {});
      const currentSearchQuery = get(this.props, "query", {});
      // console.log('ComponentDidMount: init the scroll restoringsz stuff', this.props.products.clicked)
      // console.log('the prv props and these', prevSearchQuery, currentSearchQuery)
      if (
        prevSearchQuery.pos &&
        currentSearchQuery.pos &&
        !this.props.products.clicked &&
        this.props.products.items.length > 0
      ) {
        console.log("ComponentDidMount: RUN the restore!");
        this.restoreScroll();
      } else {
        // console.log(' to restore')
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { products, filters, isSingleProduct, isCatPage } = nextProps;
    const { filterParams, searchString } = filters;
    const { currParams, currSearch } = products;
    const compareFilterParams = omit(filterParams, ["page", "limit"]);
    const compareCurrParams = omit(currParams, ["page", "limit"]);
    // console.log('!products.isFetching', !products.isFetching);
    // console.log('!products.isFailed', !products.isFailed);
    // console.log('!isSingleProduct', !isSingleProduct);
    // console.log('!isCatPage', !isCatPage);
    if (
      !products.isFetching &&
      !products.isFailed &&
      !isSingleProduct &&
      !isCatPage
    ) {
      // console.log('INSIDE');
      // console.log('lets filter products to match new filterParams', filterParams, currParams);
      if (searchString && currSearch !== searchString) {
        this.searchProducts(searchString);
      } else if (!isEqual(compareFilterParams, compareCurrParams)) {
        // console.log('UNSAFE __ the filter params!', filterParams, currParams)
        // console.log('UNSAFE __ the filter params COMPOARE COMPADRE!', compareFilterParams, compareCurrParams)
        this.filterProducts(filters);
      } else {
        if (currSearch && !searchString) {
          this.searchProducts(searchString);
        }
      }
    }
  }
  searchProducts(search) {
    //console.log('prodprodprod', search);
    // console.log('the url search query', search)
    this.props.actions.fetchProducts({ search: search });
    // let searchedItems = filterSearch(items, search);
    // this.props.actions.setFilteredProducts(searchedItems);
    this.props.setProductSearch(search);
    this.scrollToGrid();
  }
  filterProducts(filters, framesBool = false) {
    //if frames call
    const { filterParams } = filters;
    const prodParams = Object.assign(filterParams, {});
    // console.log('the before the url filter params stuff', filterParams)

    const pageFromUrl = !isNaN(parseInt(this.getUrlParams({ key: "page" })))
      ? this.getUrlParams({ key: "page" })
      : false;
    let limit = false;
    if (pageFromUrl) {
      limit = parseInt(pageFromUrl) * WOO_PRODUCT_LIMIT;
      prodParams.limit = limit;
      prodParams.page = 1;
    }
    console.log(prodParams);
    // console.log('fetch the FILTEREED products', filterParams)
    if (!framesBool) {
      this.props.actions.fetchProducts({ filterParams: prodParams });
    } else {
      const { query } = this.props;
      prodParams.category = this.getUrlSlug();
      prodParams.size = query.size;
      console.log({ prodParams });
      this.props.actions.fetchProducts({
        filterParams: prodParams,
        mainGrid: false,
      });
    }

    this.props.setProductParams(filterParams);
    this.scrollToGrid();
  }
  scrollToGrid() {
    let top = window.pageYOffset || document.documentElement.scrollTop;
    // let hero = document.getElementById('hero')
    let filter = document.getElementById("filter-waypoint");
    if (filter) {
      // let heroOffset = hero.getBoundingClientRect().bottom
      let filterHeight = filter.getBoundingClientRect().height;
      // let offset = heroOffset + (top - filterHeight - 25);
      let offset = top - filterHeight - 25;
      document.documentElement.scrollTop = document.body.scrollTop = offset;
    }
  }
  restoreScroll() {
    const { isFetching, isFetchingMore } = this.props.products;
    // console.log('restoring the scroller', isFetching, isFetchingMore, this.props.filters.isFetching)
    let { pathname, search } = this.props;
    let searchObj = this.props.query;
    // let searchObj = window.location.search
    //     searchObj = urlStrToJSON(searchObj);
    if (
      has(searchObj, "pos") &&
      !isFetching &&
      !isFetchingMore &&
      !this.state.scrollRestored
    ) {
      let pos = searchObj.pos;
      // console.log('ready to restore scroll pos', pos, document.body.scrollHeight)
      this.setState({ scrollRestored: true });
      setTimeout(() => {
        // console.log('lets roll', pos, document.body.scrollHeight)
        document.documentElement.scrollTop = document.body.scrollTop = pos;
        // document.documentElement.scrollTop = pos
        searchObj = omit(searchObj, ["pos", "page"]);
        // console.log('searchObj', searchObj)
        search = JSONtoUrlStr(searchObj);
        // console.log('search after', search)
        search = search == "?" ? "" : search;
        // console.log('search last', search)
        // console.log('the pathname', pathname)
        if (pathname) {
          const restoredUrl = `${pathname}${search}`;
          // window.history.pushState({}, '', restoredUrl)
          this.props.actions.push(restoredUrl);
        }
      }, 250);
    } else {
      console.log("not ready to restore the scroll yet§");
    }
  }

  getUrlParams({ key = "" }) {
    // const { routing } = this.props
    // const { locationBeforeTransitions } = routing
    // const { search } = locationBeforeTransitions
    let params = {};
    if (this.props.search) {
      const searchObj = urlStrToJSON(this.props.search);
      // console.log('searchObj', searchObj);
      params = searchObj;
      if (key) {
        params = get(searchObj, key, false);
      }
    }
    return params;
  }

  render() {
    let {
      products,
      isFrames,
      isCatPage,
      isSingleProduct,
      isArtistOrCategory,
      routing,
      favourites,
      isArtWall,
      is404,
      isCampaignPage,
    } = this.props;

    return isArtWall ? (
      <ArtWallProductsComponent products={products} favourites={favourites} />
    ) : isSingleProduct ? (
      <RelatedProducts
        type={`frames`}
        products={products}
        productType={`product`}
        is404={is404}
      />
    ) : (
      <ProductsComponent
        isFrames={isFrames}
        isCatPage={isCatPage}
        products={products}
        isArtistOrCategory={isArtistOrCategory}
        isArtWall={isArtWall}
        favourites={favourites}
        is404={is404}
        isCampaignPage={isCampaignPage}
      />
    );
  }
}

Products.propTypes = {
  isFrames: PropTypes.bool,
  isSingleProduct: PropTypes.bool,
  isArtistOrCategory: PropTypes.bool,
};

Products.defaultProps = {
  isFrames: false,
  isSingleProduct: false,
  isArtistOrCategory: false,
};

let mapStateToProps = (state) => {
  const props = {
    products: state.products,
    filters: state.filters,
    routing: state.routing,
    favourites: state.favourites.favourites,
    query: state.router.location.query,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
  };

  return props;
};

let mapDispatchToProps = (dispatch) => {
  let actions = {
    fetchProducts: fetchProducts,
    setFilteredProducts: setFilteredProducts,
    setFavourites,
    push,
  };

  let actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
    setProductParams: (params) => {
      dispatch(setProductParams(params));
    },
    setProductSearch: (searchString) => {
      dispatch(setProductSearch(searchString));
    },
  };

  return actionMap;
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
