'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class SearchTermComponent extends Component {
    render(){
        let { noResult } = this.props;
        return(
          noResult ?
            <div className="search-term-wrapper">
              <div className="row">
                <div className="col-xs-12">
                  <p>Nothing matched your search. You might be interested in these products instead.</p>
                </div>
              </div>
            </div>
          :
            <p className="search-term-wrapper">
              Results for <span className="search-term">{this.props.searchTerm}</span>: <Link to="/artprints" className="clear-search">(clear search)</Link>
            </p>
        )
    }
}

SearchTermComponent.displayName = 'SearchTermComponent';
SearchTermComponent.propTypes = {

}
SearchTermComponent.defaultProps = {

}

export default SearchTermComponent;
