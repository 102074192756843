require('es6-promise').polyfill();
import fetch from 'isomorphic-fetch';
import fetchFiltersRequest from './fetchFiltersRequest';
import fetchFiltersFailure from './fetchFiltersFailure';
import fetchFiltersSuccess from './fetchFiltersSuccess';

import { endpoints } from './../../lib/globals';

export default (params = '') => {
    return dispatch => {
        dispatch(fetchFiltersRequest());
        return fetch(endpoints.filters)
            .then(response => response.json())
            .then(json => dispatch(fetchFiltersSuccess(json)))
    }
};
