import { useEffect, useState } from "react"
import HeaderComponent from "../components/HeaderComponent"
import ResponsiveHeader from "../components/ResponsiveHeader"
import useMediaQuery from "../hooks/useMediaQuery"

const Header = () => { 
  const isResponsive = useMediaQuery('(min-width: 1160px)')

  return (
    <>
      {isResponsive ? <HeaderComponent /> : <ResponsiveHeader />}
    </>
  )
}

export default Header