let path = require("path");
let host = window.location.host;
let homestoriesApiBase;
export let wp_base;
export let index;
export let ajax_url;
export let filter = [];
export let sortby = [];
export let endpoints;
export let apiBase;
export const WOO_PRODUCT_LIMIT = 100;
export const FAVOURITES_ACTIVE = true;
export const CAT_UNCATEGORIZED = "uncategorized";
export const CAT_POSTERS_PRINTS = "posters-prints";
export const CAT_FRAMES = "frames";
export const CAT_PASSEPAROUT = "passepartout";
export const CAT_TRANSPARENT_FRAMES = "transparent-frames";
export const CAT_OUTLET = "outlet";
export const CAT_ART_CARDS = "art-cards";
export const CAT_FRAME_PAINT = "framepaint";
export const CAT_ORIGINAL_ART = "original-art";
export const CAT_FRAMES_TPC = "tpc-frames";
export const CAT_FRAMES_OTHER = "other-frames";
export const CAT_FRAMES_ALUMINIUM = "aluminium-frames";
export const CAT_FRAMES_COLOURED = "coloured-frames";
export const CAT_FRAMES_OAK = "oak-frames";
export const CAT_FRAMES_MOEBE = "moebe-frames";
export const CAT_LIMITED_EDITIONS = "limited-editions";
export const CAT_WALL_OBJECTS = "wall-objects";

if (host == "localhost:8001") {
  wp_base = "/www/local-git/tpc-wp/";
  //wp_base = '/theposterclub/';
  index = wp_base;
} else if (host == "localhost") {
  wp_base = "/theposterclub/";
  index = wp_base;
} else {
  wp_base = "";
  index = "/";
}

apiBase = "/wp-json/tpc/v1/";
homestoriesApiBase = "/wp-json/wp/v2/";
ajax_url = path.relative(
  "/wp-content/themes/posterclub/dist",
  wp_base + "wp-admin/admin-ajax.php"
);

endpoints = {
  wpAjax: ajax_url,
  products: apiBase + "products",
  filters: apiBase + "filters",
  product: apiBase + "product",
  taxonomy: apiBase + "product",
  mailchimp: apiBase + "mc",
  location: apiBase + "location",
  destination: apiBase + "destination",
  homestories: homestoriesApiBase + "homestories",
};

filter = [
  {
    name: "Sort by",
    slug: "sortby",
  },
  {
    name: "Size",
    slug: "size",
  },
  {
    name: "Orientation",
    slug: "orientation",
  },
  {
    name: "Price",
    slug: "price_range",
  },
  {
    name: "Colour",
    slug: "color",
  },
  {
    name: "Artist",
    slug: "artist",
  },
  {
    name: "Category",
    slug: "poster_type",
  },
  {
    name: "Collection",
    slug: "collections",
  },
];
sortby = [
  {
    name: "New arrivals",
    slug: "newest",
    term_id: "1",
  },
  {
    name: "Most popular",
    slug: "bestsellers",
    term_id: "5",
  },
  {
    name: "All time bestsellers",
    slug: "popularity",
    term_id: "2",
  },
  {
    name: "Price, high to low",
    slug: "price",
    term_id: "3",
  },
  {
    name: "Random",
    slug: "random",
    term_id: "4",
  },
];

export const productInfoSidebarTabs = [
  {
    slug: "framing",
    tabTitle: "Framing",
    mainTitle: "Free Framing Service",
  },
  {
    slug: "frame-and-passepartout",
    tabTitle: "Frame & Passepartout",
    mainTitle: "Frame & Passepartout Details",
  },
  { slug: "shipping", tabTitle: "Shipping", mainTitle: "Shipping Information" },
];
