import setFavourites from './setFavourites'

export default function(object, favourites, search) {
  return dispatch => {
    const isShared = search.length > 0 ? true : false
    
    const favouritesUpdated = favourites.map(f => parseInt(f.id) === parseInt(object.id) ? object : f)

    if (!isShared) {
      localStorage.setItem('favouriteIds', JSON.stringify(favouritesUpdated))
    }

    dispatch(setFavourites(favouritesUpdated))
  }
}