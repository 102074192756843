'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { filtering } from './../lib/tracking';
import { isEmpty } from 'lodash';
import { scrollToShop } from './../modules/scrollTo';

import RangeSlider from './../containers/RangeSlider';
import FilterAttribute from './FilterAttributeComponent';


class FilterItemComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            atBottom: false
        }
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleScrool = this.handleScrool.bind(this);
        this.trackClick = this.trackClick.bind(this);
        this.scrollDown = this.scrollDown.bind(this);
    }
    componentDidMount(){
        this.dropdown.addEventListener('scroll', this.handleScrool);
    }
    componentWillUnmount(){
        this.dropdown.removeEventListener('scroll', this.handleScrool);
    }
    handleScrool(){
        let totalHeight = this.dropdown.scrollHeight,
            clientHeight = this.dropdown.clientHeight,
            scrollTop = this.dropdown.scrollTop,
            atBottom = (totalHeight-clientHeight) <= scrollTop;

        this.setState({atBottom: atBottom});
    }
    toggleFilter(e){
        let targetClassList = e.target.classList;
        if(!targetClassList.contains('rc-slider') && !targetClassList.contains('rc-slider-handle')) {
            this.props.toggleFilter(this.props.slug);
        }
    }
    scrollDown() {
      if(window.scrollY === 0 && document.getElementById('shop_anchor')) {
        scrollToShop();
      }
    }
    trackClick(e, values = []){
        if(!isEmpty(values)){
            let label = `${values.join('-')} EUR`;
            filtering(label);
        } else {
            filtering(e.target.firstChild.data);
        }
    }
    render() {
        let arrow, selected;
        arrow = this.props.filter.length > 9;
        selected = this.props.selectedValues ? this.props.selectedValues.split('&') : [];
        // console.log(`selected for ${this.props.name}`, selected);
        let filterClass = classNames({
        //   'col-sm-2': true,
          'filter-item-wrapper': true,
          'active': this.props.toggled
        });
        let filterItemClass = classNames({
            'filter-item': true,
            'arrow': this.props.toggled && arrow,
            'bottom': this.state.atBottom
        });

        // console.log(this.props.filter);
        return(
            <li className={filterClass} onClick={(e) => {this.toggleFilter(e); this.scrollDown();}}>
                <div className={filterItemClass}>
                    <p>{ this.props.name }</p>
                </div>
                <ul className={`dropdown ${this.props.slug}`} ref={(dropdown) => {this.dropdown = dropdown}}>
                    {this.props.slug == 'price_range' ?
                            <RangeSlider
                                className="dropdown__price-slider"
                                trackClick={this.trackClick}
                                slug={this.props.slug}
                            />
                        : this.props.filter.map( (attribute, i) => {
                            let isSelected = attribute.term_id && selected.includes(attribute.term_id.toString());
                            return(
                                <FilterAttribute
                                    key={i}
                                    trackClick={this.trackClick}
                                    slug={this.props.slug}
                                    attribute={attribute}
                                    isSelected={isSelected}
                                />
                            )
                        })
                    }

                </ul>
            </li>
        )
    }
}

FilterItemComponent.displayName = 'FilterItemComponent';

FilterItemComponent.propTypes = {
    filter: PropTypes.array,
    name: PropTypes.string,
    toggleFilter: PropTypes.func,
    toggled: PropTypes.bool,
    slug: PropTypes.string,
}
FilterItemComponent.defaultProps = {
    filter: [],
    name: '',
    toggleFilter: () => {},
    toggled: false,
    slug: ''
}

export default FilterItemComponent;
