import { useState } from "react";
import { isEmpty } from "../../utilities/isEmpty";
import axios from "axios";
import {
  parseAddToCartResponse,
  triggerFragmentRefresh,
} from "../../modules/ajaxAddToCart";
import { nanoid } from "nanoid/non-secure";
import clsx from "clsx";
import { GA4AddToCart } from "../../lib/tracking";

const AddToBagButton = ({
  hasSize,
  hasAmount,
  isGiftCardProduct,
  isPassepartoutProduct,
  isFrameProduct,
  selectedAmountVariant,
  selectedSizeVariant,
  selectedPassepartoutVariant,
  selectedFrameVariant,
  isOutOfStock,
}) => {
  const [buttonLabel, setButtonLabel] = useState("Add to bag");

  const trackAddToCart = ({ products }) => {
    const name = singleProductName;
    const artist = singleProductArtist;
    const currency = "EUR";
    let category = "Posters";
    const isRegularProduct =
      !isFrameProduct && !isPassepartoutProduct && !isGiftCardProduct;
    if (isFrameProduct) {
      category = "Frames";
    } else if (isPassepartoutProduct) {
      category = "Passepartouts";
    } else if (isGiftCardProduct) {
      category = "Giftcard";
    }

    const items = products.map((p, i) => {
      const item = {
        id: p.id,
        category,
        currency,
        quantity: 1,
        size: p.size,
        listName: "SPP",
        price: p.price,
      };
      if (i === 0) {
        item.name = name;
        item.artist = isRegularProduct ? artist?.[0]?.name : "";
        item.category = category;
      }
      return item;
    });

    GA4AddToCart({ items });
  };

  const typeMapper = (type) => {
    switch (type) {
      case "amount":
        return "g";
      case "size":
        return "p";
      case "passepartout":
        return "ppt";
      case "frame":
        return "f";

      default:
        return "";
    }
  };

  // const defaultText = () => {
  //   if (isGiftCardProduct) {
  //     return "Select amount";
  //   } else if (isFrameProduct) {
  //     return "Select size";
  //   } else if (isPassepartoutProduct) {
  //     return "Select size";
  //   } else {
  //     return "Select size";
  //   }
  // };

  const addToBag = async () => {
    if (isOutOfStock) return;

    if (!hasSize && !hasAmount) {
      console.log("otlolo");
      if (isGiftCardProduct) {
        setButtonLabel("Please select an amount");
      } else if (isFrameProduct) {
        setButtonLabel("Please select a size");
      } else if (isPassepartoutProduct) {
        setButtonLabel("Please select a size");
      } else {
        setButtonLabel("Please select a size");
      }

      setTimeout(() => {
        setButtonLabel("Add to bag");
      }, 2500);

      return;
    }

    setButtonLabel("Adding");

    const products = [
      ...(!isEmpty(selectedAmountVariant) ? [selectedAmountVariant] : []),
      ...(!isEmpty(selectedSizeVariant) ? [selectedSizeVariant] : []),
      ...(!isEmpty(selectedPassepartoutVariant)
        ? [selectedPassepartoutVariant]
        : []),
      ...(!isEmpty(selectedFrameVariant) ? [selectedFrameVariant] : []),
    ].filter(({ id }) => id);

    console.log("products", products);

    const uuid = nanoid();

    // const productsReadyForBag = products.map(
    //   ({ id, type }) => `${id}:1:${typeMapper(type)}-${products[0].id}`
    // );

    const productsReadyForBag = products.map(({ id }) => `${id}:1:${uuid}`);

    console.log("productsReadyForBag", productsReadyForBag);

    try {
      const params = new URLSearchParams();
      params.append(
        "addToCart",
        encodeURIComponent(productsReadyForBag.join(","))
      );
      params.append("tpcSource", "spp");

      console.log(
        'encodeURIComponent(productsReadyForBag.join(","))',
        encodeURIComponent(productsReadyForBag.join(","))
      );

      console.log("the params", params);

      const { data, status } = await axios({
        method: "post",
        url: window.location.origin,
        data: params,
      });

      if (status === 200) {
        const { msg, info, error } = parseAddToCartResponse({ data });
        triggerFragmentRefresh();

        setButtonLabel(
          `${
            productsReadyForBag.length === 1 ? "Product" : "Products"
          } added to bag`
        );

        setTimeout(() => {
          setButtonLabel("Add to bag");
        }, 2500);
        trackAddToCart({ products });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <button
        className={clsx(
          "font-normal font-display-bold text-sm tracking-wider text-white uppercase w-full h-11 flex justify-center items-center transition-colors duration-200 ease-in-out mt-4",
          isOutOfStock ? "bg-[#787878]" : "bg-black"
        )}
        onClick={() => addToBag()}
        disabled={isOutOfStock ? true : false}
      >
        {isOutOfStock
          ? isWallObjectsProduct || isWallObjectsProduct
            ? "Sold out"
            : "Out of stock"
          : buttonLabel}
      </button>
      {/* <div className="w-full flex items-center justify-center mt-5 gap-4">
        <img className="w-7 h-auto" src={visaCard} alt="" />
        <img className="w-7 h-auto" src={masterCard} alt="" />
        <img className="w-7 h-auto" src={americanExpressCard} alt="" />
        <img className="w-7 h-auto" src={paypalCard} alt="" />
        <img className="w-7 h-auto" src={dinersClubCard} alt="" />
        <img className="w-7 h-auto" src={googlePayCard} alt="" />
        <img className="w-7 h-auto" src={applePayCard} alt="" />
      </div> */}
    </>
  );
};

export default AddToBagButton;
