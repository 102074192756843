'use strict';

import React from 'react';
import { connect } from 'react-redux'

class BuyErrorComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { buyError } = this.props

    return(
      <p>All products needs a size before you can add them to your bag</p>
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    buyError: state.favourites.buyError
  }

  return props;
}

export default connect(mapStateToProps, null)(BuyErrorComponent);