'use strict';

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'
import updateFavourites from '../actions/favourites/updateFavourites';

class FavouritesNotificationsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayNotification: false,
      notificationType: '',
      notificationColorScheme: '',
      notificationText: '',
      notificationActionText: '',
      notificationActionData: false,
      isTabletAndOver: true
    }

    this.notificationHandler = this.notificationHandler.bind(this)
    this.createNotification = this.createNotification.bind(this)
    this.handleFavourite = this.handleFavourite.bind(this)
    this.handleNotificationAction = this.handleNotificationAction.bind(this)
    this.resetNotification = this.resetNotification.bind(this)
  }

  componentDidMount() {
    const handler = e => this.setState({
      isTabletAndOver: e.matches
    })

    window.matchMedia('(min-width: 768px)').addListener(handler)

    this.setState({
      isTabletAndOver: window.matchMedia('(min-width: 768px)').matches
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { displayNotification: prevDisplayNotification } = prevState

    if ((prevProps.favourites !== this.props.favourites) && (prevProps.favourites.length > this.props.favourites.length)) {
      
      const { favourites } = this.props
      const { favourites: prevFavourites } = prevProps
      const removedFavourite = _.difference(prevFavourites, favourites)

      // console.log('notifications difference', removedFavourite);
      const text = 'Item removed from Favourites '
      const actionText = 'Undo'
      const color = 'green'
      const actionData = removedFavourite[0]
      if (!_.isEmpty(removedFavourite)) {
        this.notificationHandler(prevDisplayNotification, 'removedFavourite', color, text, actionText, actionData)
      }
    }

    if (prevProps.buyError !== this.props.buyError && this.props.buyError === true) {
      // console.log('notifications buyError', this.props.buyError)
      const text = this.state.isTabletAndOver ? 'Please choose a size for all items before you add them to the bag' : 'Choose size for all items'
      const color = 'red'
      this.notificationHandler(prevDisplayNotification, 'buyError', color, text)
    }

    if (prevProps.buyErrorSingle !== this.props.buyErrorSingle && this.props.buyErrorSingle === true) {
      const text = 'Please select a size'
      const color = 'red'
      this.notificationHandler(prevDisplayNotification, 'buyError', color, text)
    }

    if (prevProps.addedToCart !== this.props.addedToCart) {
      // console.log('notifications addedtoCart', this.props.addedToCart)
      const text = 'All items added to '
      const actionText = 'bag'
      const color = 'green'
      this.notificationHandler(prevDisplayNotification, 'addedtoCart', color, text, actionText)
    }
  }

  resetNotification() {
    this.setState({
      displayNotification: false,
      notificationType: '',
      notificationText: '',
      notificationActionText: '',
      notificationColorScheme: '',
      notificationActionData: false
    })
  }

  notificationHandler(display, type, color, text, actionText = false, actionData = false) {
    let notificationDelay = 0

    if (display) {
      this.setState({
        displayNotification: false,
      })
      notificationDelay = 350
    }

    setTimeout(() => {
      this.setState({
        displayNotification: true,
        notificationType: type,
        notificationColorScheme: color,
        notificationText: text,
        notificationActionText: actionText,
        notificationActionData: actionData,
      })
  
      let timeoutId
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        this.resetNotification()
      }, 5000)
    }, notificationDelay)
    
  }

  handleFavourite(removedFavourite) {
    const { favourites, search } = this.props
    this.props.actions.updateFavourites(removedFavourite, favourites, search);
  }

  handleNotificationAction() {
    const {notificationType, notificationActionData} = this.state

    switch (notificationType) {
      case 'removedFavourite':
        this.handleFavourite(notificationActionData)
        this.resetNotification()
        break;

      case 'addedtoCart':
        window.location.href = `${window.location.origin}/cart`
        break;
    
      default:
        break;
    }
  }

  createNotification() {
    const {notificationText, notificationActionText, notificationType, displayNotification, notificationColorScheme} = this.state

    return (
      <div className={classNames('favourites-notification', {'active': displayNotification}, {[`color-scheme-${notificationColorScheme}`]: notificationColorScheme})}>
        <div className="favourites-notification__inner">
          <p className="favourites-notification-text">
            {notificationText}
            {notificationActionText && <span className="favourites-notification-text__action" onClick={this.handleNotificationAction}>{notificationActionText}</span>}
          </p>
        </div>
      </div>
    )
  }
 
  render() {
    return(
      this.createNotification()
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites.favourites,
    buyError: state.favourites.buyError,
    buyErrorSingle: state.favourites.buyErrorSingle,
    addedToCart: state.favourites.addedToCart,
    search: state.router.location.search,
  }

  return props;
}


function mapDispatchToProps(dispatch) {
  const actions = {
    updateFavourites
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
  };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesNotificationsComponent);