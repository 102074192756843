let toggleTopBar = () => {

  var barHeight = 40;
  var toChange = jQuery('#header, #offcanvas__toggle, #offcanvas');

  if(toChange.hasClass('with-topbar')){

    if(toChange.offset().top > barHeight) {
      toChange.removeClass('with-topbar');
    } else {
      toChange.addClass('with-topbar');
    }

  jQuery(window).scroll(function() {

        if(toChange.offset().top > barHeight){
          toChange.removeClass('with-topbar');
        }
        else {
          toChange.addClass('with-topbar');
        }
  });

  }

}

export default toggleTopBar;
