'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { generateFilterUrl } from './../lib/filter';
import toggleSidebar from './../modules/toggleSidebar';
import { has, isEmpty } from 'lodash';

import togglePrice from './../actions/price/togglePrice';

import Slider, { Range } from 'rc-slider';

class RangeSlider extends Component {
    constructor(props){
        super(props);
        this.state = {
            min: 20,
            max: 150,
            defaultValue: [20, 150],
            value: [20, 150],
            step: 5,
            calculated: false
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handlePriceFiltering = this.handlePriceFiltering.bind(this);
    }
    componentWillReceiveProps(nextProps){
      if (!this.state.calculated) {
          let { items } = nextProps.products,
              { max, min } = this.state;
          if (!isEmpty(items)) {
            for (let i = 0; i < items.length; i++) {
              let p = items[i],
                  price;
              if (p.inStock) {
                  price = parseInt(p.price);
                  if (has(p, 'sale_price') && !isEmpty(p.sale_price)) price = parseInt(p.sale_price);
                  if (price > max) max = price;
                  if (price < min) min = price;
              }
            }
            this.setState({max: max, min: min, calculated: true});
          }
      }
    }
    handleOnChange(value){
        this.setState({value: value});
    }
    handlePriceFiltering(e){
      console.log(e);
        e.preventDefault();
        // handle change
        let { slug, search } = this.props,
            { value } = this.state

        let attr = `${value[0]},${value[1]}`;
        console.log('attr before generating url', attr);
        let newUrlStr = generateFilterUrl(slug, attr, search);
        this.props.trackClick(e, value);
        this.props.togglePrice(false);
        toggleSidebar(false);
        this.props.dispatch(push(`/artprints/${newUrlStr}`));
    }
    render(){
        let { min, max, defaultValue, step, value } = this.state;
        return(
            <div>
                <Range
                    min={min}
                    max={max}
                    defaultValue={defaultValue}
                    step={step}
                    pushable={true}
                    onChange={this.handleOnChange}
                />
                <p className="slider-specs">
                    <span>{`${value[0]} EUR`}</span> - <span>{`${value[1]} EUR`}</span>
                    <a href="#" onClick={this.handlePriceFiltering}>Set filter</a>
                </p>
            </div>
        )
    }
}

let mapStateToProps = (state) => {
    const props = {
        search: state.router.location.search,
        products: state.products
    }

    return props;
}

let mapDispatchToProps = (dispatch) => {
    const actions = {};
    let actionMap = {
        dispatch,
        togglePrice: (toggle) => {
            dispatch(togglePrice(toggle))
        }
    };

    return actionMap;
}
export default connect(mapStateToProps, mapDispatchToProps)(RangeSlider);
