const HomestoriesGridItemComponent = ({_embedded,link, title, excerpt,homestories_text_color}) => {

  return (
    <div className="homestories-grid-item">
      <div>
        <a href={link} className="grid-item flex justify-center align-center overflow-hidden flex-col relative">
          <img 
            className="w-full h-full" 
            src={_embedded['wp:featuredmedia']['0'].source_url}
          />
          <div className="hover-content">
            <span className={`item-label text-white`}>Home Stories</span>

            <div className="hover-content__inner">
              <h3 className={`title font-body-medium text-white`} >{title.rendered}</h3>
              <p className={`description font-body-regular text-white`} dangerouslySetInnerHTML={{ __html: excerpt.rendered }}></p>
              <span className={`more font-body-regular text-white set-after-white`}>Explore more</span>
            </div>
          </div>
        </a>
        <h4 className="sm:hidden font-body-regular text-sm mb-1 mt-3" >{title.rendered}</h4>
        <p className="sm:hidden text-gray-light text-xs mb-0 pb-4" >{excerpt.rendered}</p>
        <a className="grid-item__read-more sm:hidden text-black font-display-bold uppercase text-xs w-fit border-solid border-b border-black my-0 py-0" href={link}>Explore more</a>
      </div>
    </div>
  )
}

export default HomestoriesGridItemComponent;