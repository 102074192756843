import { useEffect, useState } from "react"
import LoadingSpinnerComponent from "./LoadingSpinnerComponent"

const KlaviyoSignUpComponent = ({handleSubmit, value, changeFunc, hasError, isAdded, response, isSubmitting}) => {
  const [buttonState, setButtonState] = useState('Subscribe')

  useEffect(() => {
    isSubmitting ? setButtonState(<LoadingSpinnerComponent size={6} />) : hasError || isAdded ? setButtonState(response) : setButtonState('Subscribe')
  }, [isSubmitting, hasError, isAdded])

  useEffect(() => {
    setTimeout(() => {
      setButtonState('Subscribe')
    }, 5000)
  }, [hasError, isAdded])

  return (
    <form className="klaviyo-sign-up-form flex flex-col" onSubmit={handleSubmit}>
      <input type="text" value={value} onChange={changeFunc} onBlur={changeFunc} placeholder="Enter your email address" id="klaviyo-footer" className="border border-solid border-black mb-3 h-11 bg-transparent p-3 font-body-regular text-xs placeholder-gray-light text-black outline-none" />
      <button
        type="submit"
        className="border border-solid border-black flex justify-center items-center font-display-bold text-xs tracking-widest uppercase text-black" style={{height: '42px', paddingTop: '2px'}}
      >
        {buttonState}
      </button>
    </form>
  )
}

export default KlaviyoSignUpComponent