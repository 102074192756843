import isMobile from "./isMobile";

// Mini-cart Functionality
const mountMiniCart = (clickMaster = false, e = null) => {
  const isBelowDesktop = window.innerWidth < 769;
  const pathname = window?.location?.pathname;
  const bypassMiniCartRoutes =
    pathname.includes("/product/") ||
    // pathname.includes("/art-walls/") ||
    pathname.includes("/perfect-pair/") ||
    pathname.includes("/favourites/");
  console.log("🛒 BYPASS mini cart routes", bypassMiniCartRoutes);
  console.log("inside mountMiniCart", clickMaster);
  const miniCart = document.getElementById("mini-cart");
  const miniCarts = document.querySelectorAll(".minicart");
  const cartTriggers = document.querySelectorAll("#cart-contents");
  console.log("🧺 HOW MANY mini cart and car triggers!!", miniCarts, cartTriggers);
  console.log('the ID MINI CART', miniCart);
  const cartTrigger = document.getElementById("cart-contents");
  console.log('the ID CART TRIGGER ', cartTrigger);
  const cartTriggerContainer = document.getElementById("close_cart_on_leave");
  const arrow = document.querySelector(".minicart__arrow");

  if (miniCart && cartTrigger) {
    console.log("🧺 mini cart and cart trigger found");
    const toggleMiniCart = (toggleState = null) => {
      event.preventDefault();
      console.log("🧺 toggleMiniCart", toggleState, miniCart);
      toggleState =
        toggleState !== null
          ? toggleState
          : !miniCart.classList.contains("active");
      miniCart.classList.toggle("active", toggleState);
      getCartIconPosition();
    };

    const getCartIconPosition = () => {
      let triggerRect = cartTrigger.getBoundingClientRect(),
        cartRect = miniCart.getBoundingClientRect(),
        triggerRectRight = triggerRect.right,
        cartRight = cartRect.right,
        offset = cartRight - triggerRectRight - 2;

      if (arrow) {
        arrow.style.right = offset + "px";
      }
    };

    if (isMobile()) {
      // if (!bypassMiniCartRoutes) {
      //   cartTrigger.addEventListener(
      //     "click",
      //     toggleMiniCart.bind(null, null, e)
      //   );
      //   clickMaster.add(miniCart, function () {
      //     if (miniCart.classList.contains("active")) {
      //       miniCart.classList.remove("active");
      //     }
      //   });
      // }
    } else {
      console.log("add cartTrigger eventListener");
      // if (!bypassMiniCartRoutes) {
      cartTrigger.addEventListener(
        "mouseenter",
        toggleMiniCart.bind(null, true, e)
      );
      cartTriggerContainer.addEventListener(
        "mouseleave",
        toggleMiniCart.bind(null, false, e)
      );
      // }
    }
  } else {
    console.log("🧺 no mini cart or cart trigger found");
  }
};

export default mountMiniCart;
