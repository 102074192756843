import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useState } from "react";
import { connect } from "react-redux";
import { twoDigits } from "../lib/parse";
import ImageComponent from "./ImageComponent";
import updateFavourites from "../actions/favourites/updateFavourites";
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts";
import setArtWallActiveProduct from "../actions/artWall/setArtWallActiveProduct";
import setMinimizeArtWallProductSidebar from "../actions/artWall/setMinimizeArtWallProductSidebar";
import { bindActionCreators } from "redux";
import { nanoid } from "nanoid/non-secure";
import getSortedProductSizes from "../utilities/getSortedProductSizes";

const ArtWallProductComponent = ({
  product,
  favourites,
  search,
  favouritesFromHash,
  actions,
  artWallProducts,
}) => {
  const [showAddToWall, setShowAddToWall] = useState(false);

  const isVariableProduct = () => {
    const boolean =
      !isEmpty(product.size) && product.size.length > 1 ? true : false;

    return boolean;
  };

  const hasFavouriteMatch = () => {
    const match = favourites
      ? favourites.find(
          (favourite) => parseInt(favourite.id) === parseInt(product.id)
        )
      : false;

    return match;
  };

  const handleFavourite = () => {
    actions.updateFavourites(
      {
        id: product.id,
        isVariable: isVariableProduct(),
        variationId: "",
        variationSize: "",
        variationSizeSlug: "",
      },
      favourites,
      search
    );
  };

  const handleAddToArtWall = () => {
    console.log("DEBUG handleAddToArtWall()");
    const uuid = nanoid();
    const sortedProductSizes = getSortedProductSizes(product);
    const selectedSize = sortedProductSizes[0];
    const selectedVariation =
      !isEmpty(product.variations) &&
      product.variations.find((v) => v.sizeSlug === selectedSize.slug);
    console.log("DEBUG product to be added", product);
    actions.setArtWallActiveProduct({
      ...product,
      uuid: uuid,
      selectedFrame: null,
      selectedSize: selectedSize,
      ...(selectedVariation && { selectedVariationId: selectedVariation.id }),
      ...(selectedVariation && {
        selectedVariationPrice: parseInt(selectedVariation.price),
      }),
      justAdded: true,
    });

    actions.updateArtWallProducts({
      product: {
        ...product,
        uuid: uuid,
        selectedFrame: null,
        selectedSize: selectedSize,
        ...(selectedVariation && { selectedVariationId: selectedVariation.id }),
        ...(selectedVariation && {
          selectedVariationPrice: parseInt(selectedVariation.price),
        }),
      },
      justAdded: true,
      artWallProducts,
      type: "add",
      search,
    });

    // actions.setMinimizeArtWallProductSidebar(false)
  };

  // const productSizeLabel = isVariableProduct() ? 'Multiple sizes' : product.size[0].name
  const productArtist =
    product.artist.length > 0 ? product.artist[0].name : "Unknown artist";
  const productPrice = twoDigits(parseInt(product.price));
  const productPriceLabel =
    product.stockStatus !== "outofstock" ? `€ ${productPrice}` : "Out of stock";

  return (
    <motion.li
      className="w-1/2 flex flex-col pl-3 pt-3"
      onHoverStart={() => setShowAddToWall(true)}
      onHoverEnd={() => setShowAddToWall(false)}
    >
      <div className="relative">
        <div>
          <ImageComponent src={product.image} width={450} height={500} />
        </div>
        <AnimatePresence>
          {showAddToWall && (
            <motion.div
              className="flex flex-col bg-black justify-center items-center absolute bottom-0 left-0 w-full p-2 cursor-pointer"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
              transition={{ type: "tween" }}
              onClick={() => handleAddToArtWall()}
            >
              <motion.p
                className="font-display-bold uppercase text-white font-light text-xs tracking-wider mb-0 py-1 px-3"
                whileTap={{ scale: 0.95 }}
              >
                Add to Wall
              </motion.p>
              {/* <p className="font-body-regular italic text-gray-light font-normal mb-0" style={{fontSize: '10px'}}>{productSizeLabel}</p> */}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex flex-col bg-tpc-light p-3 relative z-10">
        <div className="flex justify-between">
          <p
            className="font-body-regular text-black text-xs font-normal mb-0"
            dangerouslySetInnerHTML={{ __html: productArtist }}
          />
          <div
            className="relative flex items-center cursor-pointer"
            onClick={() => handleFavourite()}
          >
            <FontAwesomeIcon
              icon={["fal", "heart"]}
              className={classNames(
                "text-gray-text transition duration-300 ease-in-out",
                { "opacity-0": hasFavouriteMatch() }
              )}
              style={{ width: "14px" }}
            />
            <FontAwesomeIcon
              icon={["fas", "heart"]}
              className={classNames(
                "absolute left-0 scale-150 opacity-0 transition duration-300 ease-in-out",
                { "scale-100 opacity-100": hasFavouriteMatch() }
              )}
              style={{ width: "14px" }}
            />
          </div>
        </div>
        <p className="font-display-bold text-xs font-normal text-black mb-0 mt-1 tracking-wide uppercase">
          {productPriceLabel}
        </p>
      </div>
    </motion.li>
  );
};

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites.favourites,
    artWallProducts: state.artWall.artWallProducts,
    search: state.router.location.search,
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    updateFavourites,
    updateArtWallProducts,
    setArtWallActiveProduct,
    setMinimizeArtWallProductSidebar,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtWallProductComponent);
