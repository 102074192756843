// import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { useBoundStore } from "../store";

// const FavouritesMenuIconComponent = ({ favourites, showBackground }) => {
const FavouritesMenuIconComponent = ({ showBackground }) => {
  const favourites = useBoundStore((state) => state.favourites);

  const isFrontpage = window.location.pathname === "/";

  const iconColor = isFrontpage
    ? showBackground
      ? "text-gray-light fill-gray-light"
      : "text-white fill-white"
    : "text-gray-light fill-gray-light";

  let hasFavouritesFromLS;
  if (localStorage.getItem("favouriteIds")) {
    const favouritesFromLS = JSON.parse(localStorage.getItem("favouriteIds"));
    hasFavouritesFromLS = !isEmpty(favouritesFromLS) ? true : false;
  }

  const hasFavourites = !isEmpty(favourites) ? true : false;

  return (
    <span className="flex items-center relative w-4 2lg:w-5 h-4 2lg:h-5">
      <FontAwesomeIcon
        icon={["fal", "heart"]}
        className={clsx(
          "w-4 h-4 transition duration-300 ease-in-out",
          iconColor,
          hasFavouritesFromLS || hasFavourites ? "opacity-0" : "opacity-100"
        )}
      />
      <FontAwesomeIcon
        icon={["fas", "heart"]}
        className={clsx(
          "w-4 h-4 absolute left-0 transition duration-300 ease-in-out",
          iconColor,
          hasFavouritesFromLS || hasFavourites
            ? "scale-100 opacity-100"
            : "scale-150 opacity-0"
        )}
      />
    </span>
  );
};

// const mapStateToProps = (state) => {
//   const props = {
//     favourites: state.favourites.favourites,
//   };

//   return props;
// };

// export default connect(mapStateToProps, null)(FavouritesMenuIconComponent);
export default FavouritesMenuIconComponent;
