export const fadeAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const fadeFromLeftAnimation = {
  initial: {
    opacity: 0,
    x: -10,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -10,
  },
};

export const fadeFromRightAnimation = {
  initial: {
    opacity: 0,
    x: 300,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 300,
  },
};

export const translateDownAnimation = {
  hidden: {
    y: "-100%",
  },
  visible: {
    y: 0,
  },
  exit: {
    y: "-100%",
  },
};
