import { SET_SHOW_OFF_CANVAS } from './../actions/const';

const initialState = {
  showOffCanvas: false
};

export default (state = initialState, action) => {
  switch(action.type){
    case SET_SHOW_OFF_CANVAS:
      return Object.assign({}, state, {
        showOffCanvas: action.showOffCanvas,
      });
    default: {
      return state
    }
  }
}
