'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from './LoadingComponent';

class NewsletterComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleInput = this.toggleInput.bind(this);
  }
  toggleInput(e){
    e.preventDefault();
    this.props.toggleInput(null);
    if (!this.props.toggled) {
      this.input.focus();
    }
  }
  render() {
    let { hasError, isAdded, response, isSubmitting } = this.props,
        containerClasses = this.props.toggled ? 'newsletter newsletter--toggled' : 'newsletter',
        infoClass = hasError ? 'error-message' : 'info-message';
    return(
      <div className={containerClasses}>
        <a href='#' className='newsletter__toggle' onClick={this.toggleInput}>Newsletter</a>
        <form className='newsletter__form' onSubmit={this.props.handleSubmit}>
          <input ref={(input) => {this.input = input;}} type='email' name='email' value={this.props.value} onChange={this.props.changeFunc} onBlur={this.props.changeFunc} className='newsletter__input' placeholder='Enter e-mail here...' />
          <input type='submit' value='' className='newsletter__submit' />
        </form>
        { isSubmitting ? <Loading /> : ''}
        { hasError || isAdded ? <p className={infoClass}>{response}</p> : '' }
      </div>
    )
  }
}

export default NewsletterComponent;
