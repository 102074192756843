'use strict';

let isMobile = (mediaQueryString = "(max-width: 768px)") => {
  if(window.matchMedia) {
    return window.matchMedia(mediaQueryString).matches;
  } else {
    return false;
  }
};

export default isMobile;
