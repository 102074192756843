import _ from 'underscore';
import * as globals from './globals';
var functions = require('./filter.js');
let parse = require('./parse.js');
let tracking = require('./tracking.js');

function whichTransitionEvent() {
    var el = document.createElement('fake'),
        transEndEventNames = {
            'WebkitTransition' : 'webkitTransitionEnd',// Saf 6, Android Browser
            'MozTransition'    : 'transitionend',      // only for FF < 15
            'transition'       : 'transitionend'       // IE10, Opera, Chrome, FF 15+, Saf 7+
        };

    for(var t in transEndEventNames){
        if( el.style[t] !== undefined ){
            return transEndEventNames[t];
        }
    }
}
var transEndEventName = whichTransitionEvent();

export const getOffset = (el) => {
  let rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let top = rect.top > rect.height ? rect.top - rect.height : rect.top;
  return { top: top + scrollTop, left: rect.left + scrollLeft};
}

export const preloader = (state) => {
  var target;
  target = document.getElementById('grid');
  if(state){
    target.style.position = 'relative';
    var preload = document.createElement('div');
    var inner = document.createElement('div');
    var lineOne = document.createElement('span');
    var lineTwo = document.createElement('span');
    var icon = document.createElement('span');
    var img = document.createElement('img');
    preload.id = 'preload';
    preload.style.cssText = 'display:none;position:absolute;z-index:99999999999;top:35px;left:15px;width:100%;height:100%;text-align:center;';
    inner.className = 'loading-wrapper';
    inner.style.cssText = 'position:relative;display:table-cell;vertical-align:middle;';
    lineOne.className = 'line';
    lineTwo.className = 'line';
    icon.id = 'loading';
    img.src = globals.wp_base+'wp-content/themes/posterclub/img/712.GIF';
    img.style.width = '25px';
    img.style.height = 'auto';

    icon.appendChild(img);

    inner.appendChild(lineOne);
    inner.appendChild(icon);
    inner.appendChild(lineTwo);

    preload.appendChild(inner);
    target.appendChild(preload);
  } else {
    var preload = document.getElementById('preload');
    if(preload){
      target.removeChild(preload);
    }
  }
}

export const waypoint = () => {
  if(jQuery('html').hasClass('no-touchevents')){
    //console.log('desktop no mobil');
    let waypoint = new Waypoint({
      element: document.getElementById('filter-waypoint'),
      offset: 100,
      handler: (direction) => {
        direction == "up" ? ( jQuery('.filter-wrapper').removeClass('stuck'), jQuery('#grid').removeClass('top-spacing')) : (jQuery('.filter-wrapper').addClass('stuck'), jQuery('#grid').addClass('top-spacing') );
        //console.log('direction', direction, jQuery(window).scrollTop());
      }
    });
    return waypoint;
  }
}

export const blazy = () => {
  var bLazy = new Blazy({
    offset: 0,
    success: (ele) => {
      //console.log('succcesss blazy');
    },
    error: (ele, msg) => {
      //console.log('blazy error', msg);
      if(msg == "missing"){
        jQuery(ele).removeClass('b-error');
        jQuery(ele).attr('data-src', jQuery(ele).attr('data-url'));
        bLazy.load(ele);
      }
    }
  });
  return bLazy;
}

export const removeArrow = () => {
    jQuery('.filter-item-wrapper .dropdown').on('scroll', (e) => {
      let parent = jQuery(e.currentTarget).parents('.filter-item-wrapper');
      if(jQuery(e.currentTarget).scrollTop() + jQuery(e.currentTarget).innerHeight() >= jQuery(e.currentTarget)[0].scrollHeight) {
        jQuery('.filter-item', parent).addClass('bottom');
      } else {
        jQuery('.filter-item', parent).removeClass('bottom');
      }
    });
}

export const toggleSidebar = () => {
  jQuery('body').hasClass('menu-hover') ? ( jQuery('body').removeClass('menu-hover'), jQuery('.click-div').css('display', 'none') ) : null;
}

export const toggleFilter = () => {
  window.addEventListener('mouseup', (event) => {
    let filter = jQuery('.filter-item');
    if(!jQuery(event.target).hasClass('filter-item') && !jQuery(event.target.parentNode).hasClass('filter-item')){
      jQuery('.filter-item-wrapper.active').removeClass('active');
      jQuery('.filter-item.arrow').removeClass('active');
    }
  });
  jQuery('.filter-item').on('click', (e) => {
    let currTarget = e.currentTarget;
    let target = jQuery(e.currentTarget).parent('.filter-item-wrapper');
    let dropdown = jQuery('.dropdown', target);
    jQuery('.filter-item-wrapper.active').not(target).removeClass('active');
    jQuery('.filter-item.arrow').not(e.currentTarget).removeClass('arrow bottom');
    jQuery(target).toggleClass('active');
    let max = 9;
    let li = jQuery('li', dropdown)[0]
    let liHeight = jQuery(li).height();
    let liLength = jQuery('li', dropdown).length;
    if(max < liLength){
      jQuery(currTarget).toggleClass('arrow');
      jQuery(dropdown).one(transEndEventName, (e) => {

      });

    }
  });
}

export const fetchSearch = (object) => {
  jQuery('#product-search, #mobile-search').submit( (e) => {
    e.preventDefault();
    let form = jQuery(e.currentTarget);
    let newProducts = [];
    let search;
    search = jQuery('input[name=s]', form).val().toLowerCase();
    newProducts = functions.filterSearch(object.state.allProducts, search);
    //console.log('any new products', newProducts);
    !_.isEqual(newProducts, object.state.products) ? (jQuery('.b-lazy').removeClass('b-error b-loaded'), object.setState({products: newProducts, search: true, searchTerm: search})) : null;
    jQuery('body').hasClass('menu-hover') ? (jQuery('body').removeClass('menu-hover'), jQuery('.click-div').css('display', 'none'), jQuery('input[name=s]', form).blur()) : (jQuery('input[name=s]', form).blur(), jQuery('#close-esc').removeClass('open'));
    window.history.pushState(null, null, '?s='+search);
    jQuery('input[name=s]', form).val('');
    tracking.search(search);
    return object;
  });
}

export const handleMobileHistory = (history) => {
  jQuery('#sidebar').on('change', '.filter-select', (e) => {
    //console.log('something changed', e.currentTarget);
    let val = jQuery('option:selected', e.currentTarget).val();
    //console.log('selected value', val);
    jQuery(e.currentTarget).prop('selectedIndex', 0);
    history.pushState(null, val);
    window.location.hash = parse.randomHash();
    jQuery('body').hasClass('menu-hover') ? ( jQuery('body').removeClass('menu-hover'), jQuery('.click-div').css('display', 'none') ) : null;
  });
}
