import React, { Component } from "react";
// import { useState } from 'react'
import { connect } from "react-redux";
import Products from "./../containers/Products";
import Filter from "./../containers/Filter";
import clsx from "clsx";
import {
  CAT_FRAMES,
  CAT_OUTLET,
  CAT_ART_CARDS,
  CAT_FRAME_PAINT,
  CAT_PASSEPAROUT,
  CAT_ORIGINAL_ART,
  CAT_FRAMES_TPC,
  CAT_FRAMES_OTHER,
  CAT_FRAMES_ALUMINIUM,
  CAT_FRAMES_COLOURED,
  CAT_FRAMES_MOEBE,
  CAT_FRAMES_OAK,
  CAT_LIMITED_EDITIONS,
  CAT_WALL_OBJECTS,
} from "./../lib/globals";

class AppComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catPages: [
        CAT_FRAMES,
        CAT_PASSEPAROUT,
        CAT_OUTLET,
        CAT_ART_CARDS,
        CAT_FRAME_PAINT,
        CAT_ORIGINAL_ART,
        CAT_FRAMES_TPC,
        CAT_FRAMES_OTHER,
        CAT_FRAMES_ALUMINIUM,
        CAT_FRAMES_COLOURED,
        CAT_FRAMES_MOEBE,
        CAT_FRAMES_OAK,
        CAT_LIMITED_EDITIONS,
        CAT_WALL_OBJECTS,
      ],
    };
  }
  render() {
    let { pathname, is404 } = this.props;

    console.log("is404", is404);

    let isFrames = pathname.indexOf("frames") > -1 ? true : false;
    let isCatPage =
      this.state.catPages.indexOf(pathname.split("/")[1]) > -1
        ? this.state.catPages[
            this.state.catPages.indexOf(pathname.split("/")[1])
          ]
        : false;
    let isSingleProduct = pathname.indexOf("product") > -1 ? true : false;
    let isArtistOrCategory =
      pathname.indexOf("artists") > -1 || pathname.indexOf("categories") > -1;
    let isFavourites = pathname.indexOf("favourites") > -1;
    let isTPCFrames = pathname.indexOf("tpc-frames") > -1;
    let isOtherFrames = pathname.indexOf("other-frames") > -1;
    let isCollection = pathname.indexOf("collection") > -1;
    let isArtWall = pathname.indexOf("art-wall-designer") > -1;
    let isCampaignPage = window.campaignPages
      ? window.campaignPages.indexOf(pathname.replace(/\//g, "")) > -1
      : false;

    console.log("IS Cat page?", isCatPage, this.state.catPages, pathname);

    return (
      <>
        <div className={clsx(isArtWall ? "relative h-full" : "index relative")}>
          {isFrames ||
          isCatPage ||
          isSingleProduct ||
          isArtistOrCategory ||
          isFavourites ||
          isTPCFrames ||
          isOtherFrames ||
          isCollection ||
          isArtWall ||
          is404 ||
          isCampaignPage ? null : (
            <Filter />
          )}
          <Products
            isFrames={isFrames}
            isCatPage={isCatPage}
            isSingleProduct={isSingleProduct}
            isArtistOrCategory={isArtistOrCategory}
            isArtWall={isArtWall}
            is404={is404}
            isCampaignPage={isCampaignPage}
          />
        </div>
      </>
    );
  }
}

AppComponent.displayName = "AppComponent";

let mapStateToProps = (state) => {
  const props = {
    pathname: state.router.location.pathname,
  };

  return props;
};

export default connect(mapStateToProps, null)(AppComponent);

// const AppComponent = (props) => {
//     const [catPages] = useState([
//         CAT_FRAMES,
//         CAT_OUTLET,
//         CAT_ART_CARDS,
//         CAT_FRAME_PAINT,
//         CAT_ORIGINAL_ART,
//         CAT_FRAMES_TPC,
//         CAT_FRAMES_OTHER,
//     ])

//     const { pathname } = props

//     const isFrames = pathname.indexOf('frames') > -1 ? true : false
//     const isCatPage = catPages.indexOf(pathname.split('/')[1]) > -1 ? catPages[catPages.indexOf(pathname)] : false
//     const isSingleProduct = pathname.indexOf('product') > -1 ? true : false
//     const isArtistOrCategory = pathname.indexOf('artists') > -1 || pathname.indexOf('categories') > -1
//     const isFavourites = pathname.indexOf('favourites') > -1
//     const isTPCFrames = pathname.indexOf('tpc-frames') > -1
//     const isOtherFrames = pathname.indexOf('other-frames') > -1
//     const isCollection = pathname.indexOf('collection') > -1

//     return (
//         <div className="index">
//             {isFrames || isCatPage || isSingleProduct || isArtistOrCategory || isFavourites || isTPCFrames || isOtherFrames || isCollection ? '' : <Filter />}
//             <Products isFrames={isFrames} isCatPage={isCatPage} isSingleProduct={isSingleProduct} isArtistOrCategory={isArtistOrCategory} />
//         </div>
//     )
// }

// const mapStateToProps = (state) => {
//     const props = {
//         pathname: state.router.location.pathname,
//     }

//     return props
// }

// export default connect(mapStateToProps, null)(AppComponent)
