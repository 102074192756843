import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import * as Select from "@radix-ui/react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const SelectItem = forwardRef(
  ({ children, className, color, type, ...props }, forwardedRef) => {
    return (
      <Select.Item
        ref={forwardedRef}
        className={clsx(
          "outline-none font-display-regular font-normal text-xs leading-none text-black tracking-[0.5px] uppercase py-2 cursor-pointer hover:text-gray-light transition-colors duration-200 ease-in-out text-left",
          type !== "size" && "flex items-center gap-2",
          className
        )}
        {...props}
      >
        <Select.ItemText>{children}</Select.ItemText>
        {/* <Select.ItemIndicator className="absolute top-0 left-0 w-6 h-full flex justify-center items-center">
          <FontAwesomeIcon icon={["fal", "check"]} />
        </Select.ItemIndicator> */}
      </Select.Item>
    );
  }
);

const Price = ({ priceForSelectedSizeVariant }) => {
  const [approxPrice, setApproxPrice] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");

  useEffect(() => {
    const fetchConversion = async () => {
      try {
        if (!selectedCurrency) {
          const { data: destinationData } = await axios.get(
            `/wp-json/country-picker/destination`
          );

          const { data: priceData } = await axios.post(
            `/wp-json/country-picker/convert`,
            {
              countryCode: destinationData.countryCode,
              price: priceForSelectedSizeVariant,
            }
          );
          console.log("priceData", priceData);

          setApproxPrice(priceData.convertedPrice);
        } else {
          const { data: priceData } = await axios.post(
            `/wp-json/country-picker/convert`,
            {
              currencyCode: selectedCurrency,
              price: priceForSelectedSizeVariant,
            }
          );

          console.log("priceData", priceData);

          setApproxPrice(priceData.convertedPrice);
        }
      } catch (error) {}
    };

    fetchConversion();
  }, [priceForSelectedSizeVariant, selectedCurrency]);

  console.log("approxPrice", approxPrice);

  return (
    <div className="flex flex-col gap-2 mt-8">
      <p className="font-display-bold text-lg leading-[21px] tracking-[0.34px] text-black m-0 uppercase">{`Total: ${currency} ${priceForSelectedSizeVariant}`}</p>
      <div className="relative inline-flex justify-start">
        <p className="font-display-regular text-gray-light text-xs m-0">
          {approxPrice
            ? `Approx. ${approxPrice}`
            : "Calculating approx. price..."}
        </p>
        <Select.Root
          value={selectedCurrency}
          onValueChange={setSelectedCurrency}
        >
          <Select.Trigger
            className="outline-none inline-flex items-center justify-start font-display-regular font-normal text-xs leading-none text-black tracking-[0.5px] uppercase absolute inset-y-0 left-0 w-full opacity-0"
            aria-label=""
          >
            <Select.Value aria-label={selectedCurrency}>
              {selectedCurrency}
            </Select.Value>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="border border-gray bg-white px-3 py-[15px] flex flex-col relative z-[9999] overflow-y-auto">
              <Select.ScrollUpButton className="SelectScrollButton flex justify-center">
                <FontAwesomeIcon
                  icon={["fal", "chevron-up"]}
                  className="text-gray-light"
                />
              </Select.ScrollUpButton>
              <Select.Viewport className="flex flex-col gap-2">
                {Object.entries(currencyList).map(([k, v], index) => (
                  <SelectItem key={index} value={k}>
                    {`${k}`}
                  </SelectItem>
                ))}
              </Select.Viewport>
              <Select.ScrollDownButton className="SelectScrollButton flex justify-center">
                <FontAwesomeIcon
                  icon={["fal", "chevron-down"]}
                  className="text-gray-light"
                />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </div>
  );
};

export default Price;
