import { initialFilters } from '../lib/filter';
import {
    FETCH_FILTERS_REQUEST,
    FETCH_FILTERS_FAILURE,
    FETCH_FILTERS_SUCCESS,
    TOGGLE_FILTER,
    SET_FILTER_PARAMS,
    SET_FILTER_SEARCH,
    TOGGLE_OFFCANVAS_FILTER,
    SET_SHOW_ART_WALL_SORT_BY,
    SET_SHOW_ART_WALL_FILTERS,
    SET_FILTERS_CHECKED
} from './../actions/const';

const initialState = {
    items: initialFilters,
    filterParams: {},
    searchString: '',
    isFetching: false,
    isFailed: false,
    toggled: null,
    toggleOffCanvasFilter: false,
    showArtWallSortBy: false,
    showArtWallFilters: false,
    filtersChecked: false
};

export default (state = initialState, action) => {
    switch(action.type){
        case FETCH_FILTERS_REQUEST:
            return Object.assign({}, state, {isFetching: true, isFailed: false});
        case FETCH_FILTERS_FAILURE:
            return Object.assign({}, state, {isFetching: false, isFailed: true});
        case FETCH_FILTERS_SUCCESS:
            return Object.assign({}, state, {
                items: action.json,
                isFetching: false,
                isFailed: false
            });
        case TOGGLE_FILTER:
            return Object.assign({}, state, {toggled: action.attribute});
        case SET_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParams: action.filterParams,
                searchString: ''
            });
        case SET_FILTER_SEARCH:
            return Object.assign({}, state, {
                filterParams: {},
                searchString: action.searchString
            });
        case TOGGLE_OFFCANVAS_FILTER:
            return Object.assign({}, state, {
                toggleOffCanvasFilter: action.toggleOffCanvasFilter
            });
        case SET_SHOW_ART_WALL_SORT_BY:
            return Object.assign({}, state, {
                showArtWallSortBy: action.showArtWallSortBy,
            })
        case SET_SHOW_ART_WALL_FILTERS:
            return Object.assign({}, state, {
                showArtWallFilters: action.showArtWallFilters,
            })
        case SET_FILTERS_CHECKED:
            return Object.assign({}, state, {
                filtersChecked: action.filtersChecked
            })
        default: {
            return state
        }
    }
}
