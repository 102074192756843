const OffCanvasExtraMenus = () => {
  console.log(offcanvasExtraMenus);
  return (
    <div className="flex -ml-5 mt-7">
      {offcanvasExtraMenus.map((column, index) =>
        <ul key={index} className="w-1/2 pl-5">
          {column.offcanvas_extra_menu_column_title && 
            <li>
              <a
                className="font-display-regular tracking-wider uppercase text-black"
                style={{fontSize: '10px'}}
              >
                {column.offcanvas_extra_menu_column_title}
                <span className="block w-11 bg-gray mt-2 mb-5" style={{height: '1px'}}></span>
              </a>
            </li>
          }
          {column.offcanvas_extra_menu_items.map((menuItem, index) =>
            <li key={index} className="mb-4 last:mb-0">
              <a
                href={menuItem.offcanvas_extra_menu_item_custom_link_to ? menuItem.offcanvas_extra_menu_item_custom_link_to : menuItem.offcanvas_extra_menu_item_link_to.slug}
                className="font-body-regular text-sm text-black"
              >
                {menuItem.offcanvas_extra_menu_item_title ? menuItem.offcanvas_extra_menu_item_title : menuItem.offcanvas_extra_menu_item_link_to.post_name}
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

export default OffCanvasExtraMenus