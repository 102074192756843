'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isEmpty, union } from 'lodash';
import { generateFilterUrl } from './../lib/filter';

class SelectedFiltersComponent extends Component {

    render() {
        let { filterParams, items } = this.props,
            params, url,
            attr = [];
        
        // if( filterParams.collections ) {
        //     filterParams.collection = filterParams.collections;
        //     delete filterParams.collections;
        // }

        let selected = Object.keys(filterParams).map( (k) => {
            if(k == 'price_range'){
                params = Object.assign({}, filterParams);
                attr = params[k];
                url = generateFilterUrl(k, attr, params);
                let splitAttr = attr.split(','),
                    label = `${splitAttr[0]} - ${splitAttr[1]} EUR`;
                return (
                    <Link to={url}><li className="filter-selected">{label}</li></Link>
                )
            } else {
              let other,
                  attr = [],
                  splitParams;
                  console.log('the filter params k', k, filterParams)
              splitParams = filterParams[k] ? filterParams[k].split('&') : [];
              if (filterParams[k] === '0' || splitParams.indexOf('0') > -1) {
                other = items['size'].find(a => a.term_id.toString() === filterParams[k] || splitParams.indexOf(a.term_id.toString()) > -1 );
              } else {
                attr = items[k] ? items[k].filter(attr =>  filterParams[k] === attr.term_id.toString() || splitParams.indexOf(attr.term_id.toString()) > -1 ) : [];
              }
              if(!isEmpty(other)) attr.push(other);
              if(!isEmpty(attr)){
                  return attr.map( (a) => {
                      params = Object.assign({}, filterParams);
                      url = generateFilterUrl(k, a, params);
                      return(
                          <Link to={url}><li className="filter-selected" dangerouslySetInnerHTML={{__html: a.name}} /></Link>
                      )
                  })

            }
          }
        })
        return(
            <div className="selected-filters-container mt-3">
                <div className="selected-filters-row">
                    { selected }
                    <Link className="filter-reset-a" to="/artprints"><li className="filter-reset">Clear All Filters</li></Link>
                </div>
            </div>
        )
    }
}

SelectedFiltersComponent.displayName = 'SelectedFiltersComponent';
SelectedFiltersComponent.propTypes = {
    filterParams: PropTypes.object,
    items: PropTypes.shape({
        size: PropTypes.array,
        price_range: PropTypes.array,
        color: PropTypes.array,
        artist: PropTypes.array,
        poster_type: PropTypes.array,
        collections: PropTypes.array
    })
}
SelectedFiltersComponent.defaultProps = {
    filterParams: {},
    items: {
        size: [],
        price_range: [],
        color: [],
        artist: [],
        poster_type: [],
        collections: [],
    }
}

export default SelectedFiltersComponent;
