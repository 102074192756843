'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { filtering } from './../lib/tracking';
import toggleSidebar from './../modules/toggleSidebar';

import togglePrice from './../actions/price/togglePrice';

import SidebarFilterComponent from './../components/SidebarFilterComponent';

class SidebarFilter extends Component {
    constructor(props) {
        super(props);

        // document.getElementById('offcanvas__toggle').addEventListener('click', function() {
        //   if(document.querySelector('#mobile-filter-mount .rc-slider')) {
        //     this.props.togglePrice(false);
        //   }
        // }.bind(this));

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e){
        console.log('sidebare filter val', e.target.value);
        let val = e.target.value,
            label = e.target.options[e.nativeEvent.target.selectedIndex].text;
        filtering(label);
        this.props.togglePrice(false);
        toggleSidebar(false);
        this.props.dispatch(push(val));
    }
    render() {
        let { filters } = this.props;
        return(
            <SidebarFilterComponent
                handleChange={this.handleChange}
                filters={filters}
                isToggled={this.props.price.isToggled}
            />
        )
    }
}

let mapStateToProps = (state) => {
    const props = {
        filters: state.filters,
        price: state.price
    };

    return props;
}

let mapDispatchToProps = (dispatch) => {
    let actionMap = {
        dispatch,
        togglePrice: (toggle) => {
            dispatch(togglePrice(toggle))
        }
    };

    return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFilter);
