'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import toggleFilter from './../actions/filters/toggleFilter';
import { isEmpty, omit } from 'lodash';
import classNames from 'classnames';
import FilterItem from './FilterItemComponent';
import SidebarFilterItem from './SidebarFilterItemComponent';
import { filtering } from './../lib/tracking';

import SelectedFilters from './SelectedFiltersComponent';
import { filter } from './../lib/globals';
import { getOffset } from './../lib/functions';
import toggleSidebar from './../modules/toggleSidebar';

class FilterComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            height: props.height,
            offset: props.offset,
            stuck: props.stuck,
            toggleOffcanvasFilter: false,
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        let offset = getOffset(this.filterWaypoint);

        this.setState({offset: offset});
        document.addEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps(nextProps){
        if(this.state.offset.top == 0){
            let offset = getOffset(this.filterWaypoint);
            this.setState({offset: offset});
        }
    }
    componentWillUnmount(){
        document.removeEventListener('scroll', this.handleScroll);
    }
    openSidebar() {
        toggleSidebar(true);
        // this.setState({toggleOffcanvasFilter: !this.state.toggleOffcanvasFilter});
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.state.toggleOffcanvasFilter !== prevState.toggleOffcanvasFilter) {
    //         const offCanvasFilterElement = document.getElementById('offcanvas_filter');
    //         offCanvasFilterElement.classList.toggle('offcanvas-filter--toggled', this.state.toggleOffcanvasFilter);
    //     }
    // }
    handleScroll(){
        let top  = window.pageYOffset || document.documentElement.scrollTop;
        if(this.state.offset.top === 0){
            return;
        }
        if(top > (this.state.offset.top - 30)){
            if(!this.state.stuck){
                this.setState({stuck: true});
                let grid = document.getElementById('grid');
                if(!grid.classList.contains('top-spacing')){
                    let { filterParams } = this.props.filters;
                    let isSelection = !isEmpty(filterParams);
                    if(isSelection){
                        grid.classList.add('selected');
                    }
                    grid.classList.add('top-spacing');
                }
            }
        } else {
            if(this.state.stuck){
                this.setState({stuck: false});
                let grid = document.getElementById('grid');
                if(grid.classList.contains('top-spacing')){
                    grid.classList.remove('top-spacing');
                }
                if(grid.classList.contains('selected')){
                    grid.classList.remove('selected');
                }
            }
        }
    }
    handleChange(e){
        console.log('sidebare filter val', e.target.value);
        let val = e.target.value,
            label = e.target.options[e.nativeEvent.target.selectedIndex].text;
        filtering(label);
        this.props.dispatch(push(val));
    }
    render() {

        let { offset } = this.state;
        let { filters, toggleFilter } = this.props;
        let { items, toggled, filterParams } = filters;
        let isSelection, filterClasses, filterContainer;
        
        filterParams = omit(filterParams, 'page')
        isSelection = !isEmpty(filterParams);
        filterClasses = classNames({
            'filter-wrapper': true,
            'selected': false,
            'stuck': this.state.stuck,
            'sticky': true,
            'left-0': true,
            'w-full': true,
            'z-50': true,
        });
        filterContainer = classNames({
            'container': true,
            'filter-container': true,
            'no-spacing': true
        });

        // console.log('items', items);

        return(
            <div ref={(filterWaypoint) => {this.filterWaypoint = filterWaypoint}} id="filter-waypoint" className={filterClasses}>
                <div className={filterContainer}>
                    <div className="offcanvas-filter__events">
                      { !filters.isFetching && !filters.isFailed ?
                          filter.map( (f, i) => {
                              return(
                                  
                                f.slug === 'sortby' ?
                                  <SidebarFilterItem
                                      name={f.name}
                                      slug={ f.slug === 'collections' ? 'collection' : f.slug }
                                      item={ f.slug === 'collections' ? items['collection'] : items[f.slug] }
                                      key={i}
                                      handleChange={this.handleChange}
                                  />
                                : ''
                              )
                      }) : '' }
                      <div id="mobile-filters__trigger" className={`mobile-filters__trigger trigger`} onClick={this.openSidebar}>Filters</div>
                    </div>
                    <ul className="hidden lg:flex" style={{marginLeft: '-30px'}}>
                        {
                            filter.map( (f, i) => {
                                return(
                                    <FilterItem
                                        name={f.name}
                                        slug={f.slug}
                                        toggleFilter={toggleFilter}
                                        toggled={f.slug == toggled}
                                        //filter={ f.slug === 'collections' ? items['collection'] : items[f.slug] }
                                        filter={ items[f.slug] }
                                        key={i}
                                        selectedValues={filters.filterParams[f.slug]}
                                    />
                                )
                            })
                        }
                    </ul>
                    { isSelection ? <SelectedFilters items={items} filterParams={filterParams} /> : '' }
                </div>
            </div>
        )
    }
}

FilterComponent.displayName = 'FilterComponent';

FilterComponent.propTypes = {
    filters: PropTypes.shape({
        items: PropTypes.shape({
            size: PropTypes.array,
            size_category: PropTypes.array,
            price_range: PropTypes.array,
            color: PropTypes.array,
            artist: PropTypes.array,
            poster_type: PropTypes.array,
            collection: PropTypes.array // sebastianp task 160322_01
        }),
        isFetching: PropTypes.bool,
        isFailed: PropTypes.bool,
        toggled: PropTypes.string,
        filterParams: PropTypes.object
    }),
    offset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number
    }),
    stuck: PropTypes.bool,
    height: PropTypes.number
}
FilterComponent.defaultProps = {
    filters: {
        items: {
            size: [],
            size_category: [],
            price_range: [],
            color: [],
            artist: [],
            poster_type: [],
            collection: [] // sebastianp task 160322_01
        },
        isFetching: false,
        isFailed: false,
        toggled: null,
        filterParams: {}
    },
    offset: {
        top: 0,
        left: 0
    },
    stuck: false,
    height: 0
}

let mapStateToProps = (state) => {
    const props = {
        filters: state.filters
    };

    return props;
}

let mapDispatchToProps = (dispatch) => {
    let actionMap = {
        dispatch
    };

    return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
