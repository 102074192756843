import { useState } from 'react'
import { search } from '../lib/tracking'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

const Search2Component = (props) => {
  const [searchString, setSearchString] = useState('')

  const handleInput = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchString(value)
  }

  const handleSubmit = () => {
    const { pathname } = props
    const url = `/artprints?s=${searchString}`
    const path = pathname

    if (path !== '/artprints') {
      window.location = `/artprints?s=${searchString}`
    }

    // google analytics
    search(searchString)

    props.push(url)
  }

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)} role="search" className="">
        <input
          value={searchString}
          onChange={(e) => handleInput(e)}
          type="text"
          id="s"
          name="s"
          autoComplete="off"
          autoFocus
          placeholder="Search The Poster Club"
          className="rounded-none border-b border-solid border-black bg-transparent py-4 2lg:py-6 font-body-regular text-sm text-black placeholder-black px-7 2lg:px-11 w-full outline-none leading-none"
        />
      </form>
    </div>
  )
}

let mapStateToProps = (state) => {
	const props = {
    pathname: state.router.location.pathname,
	}

	return props
}

export default connect(mapStateToProps, { push })(Search2Component)