import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import { showLightboxAtom } from "../../jotai";
import { useSetAtom } from "jotai";
import useClickAway from "../../hooks/useClickAway";

const LightboxImageSlider = ({
  selectedSizeVariant,
  selectedPassepartoutVariant,
  selectedFrameVariant,
  dimension,
  selectedIndexFromParent,
}) => {
  useLockBodyScroll();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const setShowLightbox = useSetAtom(showLightboxAtom);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    loop: true,
  });

  const closeRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const onClick = useCallback(
    (index) => {
      if (!emblaMainApi) return;

      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return;

    setSelectedIndex(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;

    onSelect();

    emblaMainApi.on("reInit", onSelect);
    emblaMainApi.on("select", onSelect);
  }, [emblaMainApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollPrev();
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollNext();
  }, [emblaMainApi]);

  const getImageScale = (hasPassepartout, hasFrame) => {
    if (hasPassepartout && !hasFrame) {
      return 0.57;
    } else if (!hasPassepartout && hasFrame) {
      return 0.75;
    } else if (hasPassepartout && hasFrame) {
      return 0.57;
    } else {
      return 0.75;
    }
  };

  const getBoxShadowScaleY = (hasPassepartout) => {
    if (hasPassepartout) {
      if (dimension === "landscape") {
        return 0.746;
      } else if (dimension === "square") {
        return 0.7325;
      } else {
        return 0.745;
      }
    } else {
      if (dimension === "landscape") {
        return 0.763;
      } else {
        return 0.75;
      }
    }
  };
  const getBoxShadowScaleX = (hasPassepartout, dimension) => {
    if (hasPassepartout) {
      if (dimension === "landscape") {
        return 0.746;
      } else if (dimension === "square") {
        return 0.7325;
      } else {
        return 0.732;
      }
    } else {
      if (dimension === "landscape") {
        return 0.763;
      } else {
        return 0.75;
      }
    }
  };

  useEffect(() => {
    onClick(0);
  }, [
    selectedPassepartoutVariant?.desktopImageUrl,
    selectedFrameVariant?.frameImage,
  ]);

  let productImages;

  if (!isPassepartoutProduct && !isFrameProduct) {
    if (singleProductImages?.length > 1) {
      const [first, ...rest] = singleProductImages;
      console.log("productImages first and rest", first, rest);

      rest.unshift(rest.pop());

      productImages = rest;
    } else {
      productImages = singleProductImages;
    }
  } else {
    productImages = singleProductImages;
  }

  const indexSort = (arr, value) => {
    if (value < 0 && value > arr.length) return arr;

    const beforeValues = arr.slice(0, value);
    const afterValues = arr.slice(value + 1);

    return [arr[value], ...afterValues, ...beforeValues];
  };

  const sortedproductImages = indexSort(productImages, selectedIndexFromParent);

  const getNewIndexForMainImage = (arr, value) => {
    if (value < 0 && value > arr?.length) return arr;

    const arrayLength = arr?.length;
    const beforeValuesLength = arr.slice(0, value)?.length;

    return beforeValuesLength === 0 ? 0 : arrayLength - beforeValuesLength;
  };

  const newIndexForMainImage = getNewIndexForMainImage(
    productImages,
    selectedIndexFromParent
  );

  useClickAway(
    [closeRef, leftArrowRef, rightArrowRef],
    [() => setShowLightbox(false)]
  );

  useEffect(() => {
    console.log("selectedIndex", selectedIndex);

    const keyDownHandler = (e) => {
      if (e.key === "Escape") {
        console.log("is Escape");
        setShowLightbox(false);
      }

      if (e.key === "ArrowLeft") {
        console.log("is ArrowLeft");
        scrollPrev();
      }

      if (e.key === "ArrowRight") {
        console.log("is ArrowRight");
        scrollNext();
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [emblaMainApi, selectedIndex]);

  return (
    <div className="flex justify-center items-center relative h-full">
      <div className="absolute top-0 right-0 z-9999 m-3">
        <button
          ref={closeRef}
          type="button"
          className="w-8 h-8 bg-tpc-light rounded-full"
          onClick={() => setShowLightbox(false)}
        >
          <FontAwesomeIcon icon={["fal", "times"]} />
        </button>
      </div>
      <div className="embla overflow-hidden">
        <div className="embla__viewport relative" ref={emblaMainRef}>
          <div className="embla__container flex items-center">
            {sortedproductImages.map((productImage, index) => (
              <div
                key={index}
                className="embla__slide flex-[0_0_100%] m-w-0 flex justify-center"
              >
                <div
                  className={clsx(
                    "aspect-[800/1100] max-h-[100dvh] h-full w-auto flex items-center origin-center",
                    newIndexForMainImage === index && "relative",
                    newIndexForMainImage === index &&
                      !isLimitedEditionsProduct &&
                      "bg-gradient-to-r from-[#f6f6f6] to-[#d9d9d9]",
                    newIndexForMainImage === index &&
                      isLimitedEditionsProduct &&
                      "bg-[#ececec]"
                  )}
                >
                  <AnimatePresence>
                    {(selectedSizeVariant ||
                      selectedPassepartoutVariant?.passepartoutImage) &&
                      !selectedFrameVariant?.frameImage &&
                      !isPassepartoutProduct &&
                      !isFrameProduct &&
                      !isWallObjectsProduct &&
                      !isLimitedEditionsProduct &&
                      !isWallObjectsProduct && (
                        <motion.div
                          key="lightbox-shadow"
                          initial={{
                            opacity: 0,
                            scaleY: getBoxShadowScaleY(
                              selectedPassepartoutVariant?.desktopImageUrl
                            ),
                            scaleX: getBoxShadowScaleX(
                              selectedPassepartoutVariant?.desktopImageUrl,
                              dimension
                            ),
                            top:
                              dimension === "landscape" ||
                              dimension === "square"
                                ? "50%"
                                : "auto",
                            y:
                              dimension === "landscape" ||
                              dimension === "square"
                                ? "-50%"
                                : "0",
                          }}
                          animate={{
                            opacity: 1,
                            scaleY: getBoxShadowScaleY(
                              selectedPassepartoutVariant?.desktopImageUrl
                            ),
                            scaleX: getBoxShadowScaleX(
                              selectedPassepartoutVariant?.desktopImageUrl,
                              dimension
                            ),
                            top:
                              dimension === "landscape" ||
                              dimension === "square"
                                ? "50%"
                                : "auto",
                            y:
                              dimension === "landscape" ||
                              dimension === "square"
                                ? "-50%"
                                : "0",
                          }}
                          exit={{ opacity: 0 }}
                          transition={{ type: "tween" }}
                          style={{
                            boxShadow:
                              newIndexForMainImage === index
                                ? "-2.4px 2.4px 5.2px 0px rgba(0,0,0,0.15)"
                                : "none",
                          }}
                          className={clsx(
                            "embla__slide__img block absolute left-0 w-full origin-center pointer-events-none aspect-[800/1100]",
                            dimension === "landscape" || dimension === "square"
                              ? "top-1/2 -translate-y-1/2"
                              : "top-0",
                            dimension === "portrait" && "aspect-[800/1100]",
                            dimension === "landscape" && "aspect-[1389/992]",
                            dimension === "square" && "aspect-square",
                            index !== newIndexForMainImage
                              ? "hidden z-0"
                              : "z-40"
                          )}
                        />
                      )}
                  </AnimatePresence>
                  <motion.img
                    key="lightbox-artprint"
                    layout
                    initial={{
                      scale:
                        index === newIndexForMainImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isWallObjectsProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct
                          ? getImageScale(
                              selectedPassepartoutVariant?.desktopImageUrl,
                              selectedFrameVariant?.frameImage
                            )
                          : 1,
                    }}
                    animate={{
                      scale:
                        index === newIndexForMainImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isWallObjectsProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct
                          ? getImageScale(
                              selectedPassepartoutVariant?.desktopImageUrl,
                              selectedFrameVariant?.frameImage
                            )
                          : 1,
                    }}
                    src={productImage?.main[0]}
                    width={1600}
                    height={2200}
                    alt=""
                  />
                  <AnimatePresence>
                    {selectedPassepartoutVariant?.passepartoutImage &&
                      !isPassepartoutProduct &&
                      !isFrameProduct &&
                      !isWallObjectsProduct &&
                      !isLimitedEditionsProduct &&
                      !isWallObjectsProduct && (
                        <motion.img
                          key="lightbox-passepartout"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            type: "tween",
                          }}
                          className={clsx(
                            "embla__slide__img block absolute left-0 z-40 h-full w-full",
                            dimension === "landscape" || dimension === "square"
                              ? "top-1/2 -translate-y-1/2"
                              : "top-0",
                            index !== newIndexForMainImage
                              ? "hidden z-0"
                              : "z-40"
                          )}
                          src={selectedPassepartoutVariant.passepartoutImage}
                          width={1600}
                          height={2200}
                          alt=""
                        />
                      )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {selectedFrameVariant?.frameImage &&
                      !isPassepartoutProduct &&
                      !isFrameProduct &&
                      !isWallObjectsProduct &&
                      !isLimitedEditionsProduct &&
                      !isWallObjectsProduct && (
                        <motion.img
                          key="lightbox-frame"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            type: "tween",
                          }}
                          className={clsx(
                            "embla__slide__img block absolute left-0 z-40 h-full w-full",
                            dimension === "landscape" || dimension === "square"
                              ? "top-1/2 -translate-y-1/2"
                              : "top-0",
                            index !== newIndexForMainImage
                              ? "hidden z-0"
                              : "z-40"
                          )}
                          src={selectedFrameVariant.frameImage}
                          width={800}
                          height={1100}
                          alt=""
                        />
                      )}
                  </AnimatePresence>
                </div>
              </div>
            ))}
          </div>
          <div className="fixed inset-0 flex justify-center">
            <div className="aspect-[1000/1100] max-h-[100dvh] h-full w-auto relative max-w-full">
              <button
                type="button"
                className="embla__prev w-8 h-8 bg-tpc-light rounded-full absolute top-1/2 -translate-y-1/2 left-3 z-50"
                onClick={scrollPrev}
              >
                <FontAwesomeIcon icon={["fal", "arrow-left"]} />
              </button>
              <button
                type="button"
                className="embla__next w-8 h-8 bg-tpc-light rounded-full absolute top-1/2 -translate-y-1/2 right-3 z-50"
                onClick={scrollNext}
              >
                <FontAwesomeIcon icon={["fal", "arrow-right"]} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightboxImageSlider;
