require('es6-promise').polyfill();
import fetch from 'isomorphic-fetch';
import fetchProductsRequest from './fetchProductsRequest';
import fetchProductsFailure from './fetchProductsFailure';
import fetchProductsSuccess from './fetchProductsSuccess';
import { prepareFilterAndPaginationParams } from './../../lib/parse'

import { endpoints } from './../../lib/globals';

export default ({
  page = 1, limit, filterParams = {}, search = '', mainGrid = true, favouriteIds = [], product_ids = [], favouritesHash = false, favouritesFromHash = []
}) => {
  let endpoint

  console.log({filterParams, mainGrid});

  const urlQuery = prepareFilterAndPaginationParams(
    {
      page: page,
      limit: limit,
      filterParams: filterParams,
      search: search,
      mainGrid: mainGrid,
      favouriteIds: favouriteIds,
      product_ids: product_ids,
      favouritesHash: favouritesHash,
      favouritesFromHash: favouritesFromHash
    }
  )
  console.log({urlQuery});
  endpoint = `${endpoints.products}${urlQuery}`
  // console.log(endpoint);

  return dispatch => {
    dispatch(fetchProductsRequest());

    return fetch(endpoint)
      .then(response => response.json())
      .then(json => dispatch(fetchProductsSuccess(json)))
  }
}
