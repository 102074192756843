import { AnimatePresence, motion } from 'framer-motion'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { generateFilterUrl } from '../lib/filter'
import { sortby } from './../lib/globals'
import setShowArtWallSortBy from '../actions/filters/setShowArtWallSortBy';
import { useRef } from 'react'
import useOnClickOutsideExtended from '../hooks/useOutsideClickExtended'
// import useOnClickOutside from '../hooks/useOutsideClick'

const ArtWallSortByComponent = ({ filters, search, actions, buttonRef }) => {
  // console.log('filters', filters)
  // console.log('sortby', sortby)

  const sortByModalRef = useRef(null)
  useOnClickOutsideExtended(sortByModalRef, buttonRef, () => actions.setShowArtWallSortBy(false))

  return (
    <div ref={sortByModalRef} className="px-8">
      <ul className="py-2">
        {sortby.map((attr, i) =>
          <li key={i} className="relative flex items-center justify-between">
            <AnimatePresence>
              {attr?.term_id && filters?.filterParams && (get(filters.filterParams, 'sortby', false) == attr.term_id) &&
                <motion.div
                  key={attr.slug+'-active'}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{type: 'tween'}}
                  className="absolute transform -translate-x-3 w-1 h-1 bg-black"
                  style={{top: '40%'}}
                />
              }
            </AnimatePresence>
            <Link
              to={generateFilterUrl('sortby', attr, search)}
              className="block font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 my-1"
              dangerouslySetInnerHTML={{__html: attr.name}}
              onClick={() => actions.setShowArtWallSortBy(false)}
            />
            <AnimatePresence>
              {attr?.term_id && filters?.filterParams && (get(filters.filterParams, 'sortby', false) == attr.term_id) &&
                <motion.div
                  key={attr.slug+'-clear'}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{type: 'tween'}}
                >
                  <Link
                    to={generateFilterUrl('sortby', attr, search)}
                    className="block font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 px-3 bg-tpc-dark hover:bg-tpc-darker transition-colors duration-200 ease-in-out rounded-md ml-2"
                  >
                    clear
                  </Link>
                </motion.div>
              }
            </AnimatePresence>
          </li>
        )}
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  const props = {
    search: state.router.location.search,
  }

  return props
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setShowArtWallSortBy,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }

  return actionMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallSortByComponent)