import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoundStore } from "../../store";
import clsx from "clsx";

const SelectedForYouProductCard = ({ sfyProduct }) => {
  const favourites = useBoundStore((state) => state.favourites);
  const updateFavourites = useBoundStore((state) => state.updateFavourites);

  const isFavourite = () => {
    const match =
      favourites && favourites.length > 0
        ? favourites.find(
            (favourite) => parseInt(favourite.id) === parseInt(sfyProduct.id)
          )
        : false;

    return match;
  };

  const favouritesHandler = (e) => {
    e.preventDefault();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());

    updateFavourites(
      {
        id: sfyProduct.id,
        isVariable: sfyProduct.type === "variable" ? true : false,
        variationId: "",
        variationSize: "",
        variationSizeSlug: "",
      },
      favourites,
      searchParams
    );
  };

  return (
    <a href={sfyProduct.url} className="flex flex-col h-full">
      <div className="">
        <img
          src={sfyProduct.image[0]}
          width={sfyProduct.image[1]}
          height={sfyProduct.image[2]}
          alt=""
        />
      </div>
      <div className="bg-tpc-light flex flex-col p-3 gap-3 h-full">
        <div className="flex justify-between items-start">
          <p className="mb-0 font-body-regular text-[14px] leading-[20px] tracking-[0.7px] text-black">
            {sfyProduct?.artist}
          </p>
          <button
            className="relative flex items-center cursor-pointer mt-[2px]"
            onClick={(e) => favouritesHandler(e)}
          >
            <FontAwesomeIcon
              icon={["fal", "heart"]}
              className={clsx(
                "w-4 h-4 text-gray-text transition duration-300 ease-in-out",
                isFavourite() ? "opacity-0" : "opacity-100"
              )}
            />
            <FontAwesomeIcon
              icon={["fas", "heart"]}
              className={clsx(
                "w-4 h-4 absolute left-0 transition duration-300 ease-in-out",
                isFavourite() ? "scale-100 opacity-100" : "scale-150 opacity-0"
              )}
            />
          </button>
        </div>
        <div className="flex justify-between items-center mt-auto">
          <p className="mb-0 font-display-bold text-[12px] leading-[16px] tracking-[0.6px] text-black uppercase">
            {sfyProduct?.price &&
              `${sfyProduct?.currency} ${parseInt(sfyProduct?.price)?.toFixed(
                2
              )}`}
          </p>
          {sfyProduct.type === "variable" && (
            <p className="mb-0 hidden sm:block font-display-regular text-[12px] leading-[16px] tracking-[1px] text-gray-text">
              Multiple sizes
            </p>
          )}
        </div>
      </div>
    </a>
  );
};

export default SelectedForYouProductCard;
