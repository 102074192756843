export const customSort = (arrayToSort) => {
  return arrayToSort.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (isNaN(nameA.charAt(0)) && !isNaN(nameB.charAt(0))) {
      return -1; // a comes before b
    } else if (!isNaN(nameA.charAt(0)) && isNaN(nameB.charAt(0))) {
      return 1; // b comes before a
    } else {
      return nameA.localeCompare(nameB); // default sorting for strings
    }
  });
};
