import useEmblaCarousel from "embla-carousel-react";
import SelectedForYouProductCard from "./SelectedForYouProductCard";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useMediaQuery from "../../hooks/useMediaQuery";

const SelectedForYouSlider = ({ selectedForYouProducts }) => {
  const lg = useMediaQuery("(min-width: 1024px)");

  const [slidesToScroll, setSlidesToScroll] = useState(4);

  useEffect(() => {
    if (lg) {
      setSlidesToScroll(4);
    } else {
      setSlidesToScroll(2);
    }
  }, [lg]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "start",
    slidesToScroll: slidesToScroll,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  console.log("selectedForYouProducts", selectedForYouProducts);

  return (
    <>
      <div className="embla relative">
        <div className="embla__viewport overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex -ml-4">
            {selectedForYouProducts.map((sfyProduct, index) => (
              <div
                key={index}
                className="embla__slide flex-[0_0_50%] lg:flex-[0_0_25%] min-w-0 pl-4"
              >
                <SelectedForYouProductCard
                  key={index}
                  sfyProduct={sfyProduct}
                />
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          className="embla__prev w-11 h-11 bg-white rounded-full absolute top-1/2 -translate-y-1/2 -left-[22px] hidden sm:flex z-50 shadow-[0_0_10px_0_rgba(163,163,163,1)] justify-center items-center"
          onClick={scrollPrev}
        >
          <FontAwesomeIcon className="w-5 h-5" icon={["fal", "arrow-left"]} />
        </button>
        <button
          type="button"
          className="embla__next w-11 h-11 bg-white rounded-full absolute top-1/2 -translate-y-1/2 -right-[22px] hidden sm:flex z-50 shadow-[0_0_10px_0_rgba(163,163,163,1)] justify-center items-center"
          onClick={scrollNext}
        >
          <FontAwesomeIcon className="w-5 h-5" icon={["fal", "arrow-right"]} />
        </button>
      </div>
      <div className="embla__dots flex gap-4 justify-center mt-8">
        {scrollSnaps.map((_, index) => (
          <div
            key={index}
            onClick={() => scrollTo(index)}
            className={clsx(
              "w-[11px] h-[11px] rounded-full cursor-pointer",
              index === selectedIndex ? "bg-black" : "bg-tpc-darker"
            )}
          />
        ))}
      </div>
    </>
  );
};

export default SelectedForYouSlider;
