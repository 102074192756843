import setArtWallProducts from "./setArtWallProducts";

export default function ({
  product,
  existing,
  artWallProducts,
  type,
  search,
  initial = false,
}) {
  return (dispatch) => {
    console.log("DEBUG updateArtWallProducts action", artWallProducts, product);
    // const isShared = !isEmpty(search) ? true : false
    let updatedArtWallProducts;

    switch (type) {
      case "set":
        console.log("set case");
        updatedArtWallProducts = artWallProducts;
        if (!initial) {
          localStorage.setItem(
            "artWallProducts",
            JSON.stringify(updatedArtWallProducts)
          );
        }
        break;

      case "add":
        console.log("DEBUG add case", artWallProducts, product);
        updatedArtWallProducts = [...artWallProducts, product];
        localStorage.setItem(
          "artWallProducts",
          JSON.stringify(updatedArtWallProducts)
        );
        break;

      case "switchFrame":
        // console.log('switchFrame case');
        console.log("product", product);
        console.log("existing", existing);
        console.log("artWallProducts", artWallProducts);
        updatedArtWallProducts = artWallProducts.map((artWallProduct) => {
          console.log("artWallProduct", artWallProduct);

          return artWallProduct.uuid === existing.uuid
            ? product
            : artWallProduct;
        });
        console.log("updatedArtWallProducts", updatedArtWallProducts);
        localStorage.setItem(
          "artWallProducts",
          JSON.stringify(updatedArtWallProducts)
        );
        break;

      case "remove":
        // console.log('remove case');
        if (
          artWallProducts.find(
            (artWallProduct) => artWallProduct.uuid === product.uuid
          )
        ) {
          const frame = artWallProducts.find(
            (artWallProduct) => artWallProduct.belongsTo === product.uuid
          );

          if (frame) {
            updatedArtWallProducts = artWallProducts
              .filter((artWallProduct) => {
                return artWallProduct.belongsTo !== product.uuid;
              })
              .filter((artWallProduct) => {
                return artWallProduct.uuid !== product.uuid;
              });

            // updatedArtWallProducts = artWallProducts.filter(artWallProduct => {
            //   let match = artWallProduct.belongsTo !== product.uuid

            //   if (match) {
            //     match = artWallProduct.uuid !== product.uuid
            //   }

            //   return match
            // })
          } else {
            updatedArtWallProducts = artWallProducts.filter(
              (artWallProduct) => {
                return artWallProduct.uuid !== product.uuid;
              }
            );
          }
        }
        // console.log('updatedArtWallProducts', updatedArtWallProducts);
        localStorage.setItem(
          "artWallProducts",
          JSON.stringify(updatedArtWallProducts)
        );
        break;

      case "removeAll":
        // console.log('removeAll case');
        updatedArtWallProducts = [];
        localStorage.setItem(
          "artWallProducts",
          JSON.stringify(updatedArtWallProducts)
        );
        break;

      default:
        // console.log('default case');
        // updatedArtWallProducts = artWallProducts.find(artWallProduct => artWallProduct.uuid === product.uuid)
        // ? artWallProducts.filter(artWallProduct => artWallProduct.uuid !== product.uuid)
        // : [...artWallProducts, product]
        break;
    }

    dispatch(setArtWallProducts(updatedArtWallProducts));
  };
}
