import React, { Suspense } from "react"
// import ArtWallComponent from "../components/ArtWallComponent"
import ArtWallError from "../components/ArtWallError"
import ArtWallSpinner from "../components/ArtWallSpinner"
import useMediaQuery from "../hooks/useMediaQuery"

const ArtWallComponent = React.lazy(() => import('../components/ArtWallComponent'))

const ArtWall = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      { isDesktop ? 
        <Suspense fallback={
          <div className="h-screen">
            <ArtWallSpinner />
          </div>
        }>
          <ArtWallComponent /> 
        </Suspense>
        : <ArtWallError />
      }
    </>
  )
}

export default ArtWall