'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generateFilterUrl } from './../lib/filter';

class SidebarFilterAttributeComponent extends Component {
    render(){
        let { attribute, slug, search } = this.props;
        let attrName, url, urlSlug, isSizeCat;
        attrName = {__html: attribute.name};
        isSizeCat = attribute.taxonomy === 'pa_size-category'
        urlSlug = isSizeCat ? 'size_category' : slug
        url = generateFilterUrl(urlSlug, attribute, search);
        return(
            <option value={url} dangerouslySetInnerHTML={attrName} />
        )
    }
}

SidebarFilterAttributeComponent.propTypes = {
    attribute: PropTypes.shape({
        term_id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        term_group: PropTypes.number,
        term_taxonomy_id: PropTypes.number,
        taxonomy: PropTypes.string,
        description: PropTypes.string,
        parent: PropTypes.number,
        count: PropTypes.number,
        filter: PropTypes.string
    })
}
SidebarFilterAttributeComponent.defaultProps = {
    attribute: {
        term_id: '',
        name: '',
        slug: '',
        term_group: 0,
        term_taxonomy_id: 0,
        taxonomy: '',
        description: '',
        parent: 0,
        count: 0,
        filter: ''
    }
}

let mapStateToProps = (state) => {
    const props = {
        search: state.router.location.search,
    };
    return props;
}

export default connect(mapStateToProps, null)(SidebarFilterAttributeComponent);
