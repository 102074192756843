import { useEffect, useState } from "react";
import SingleProductComponent from "../components/single-product/SingleProductComponent";
import { isEmpty } from "../utilities/isEmpty";
import axios from "axios";
import { useBoundStore } from "../store";

const SingleProductContainer = () => {
  const [frames, setFrames] = useState([]);
  const [passepartout, setPassepartout] = useState([]);

  const setFavourites = useBoundStore((state) => state.setFavourites);

  useEffect(() => {
    const fetchFrames = async () => {
      const result = await axios(
        `/wp-json/tpc/v1/products?page=1&limit=100&category=frames`
      );
      const framesFromWooCommerce = result.data.products;
      const onlyFramesWithFrameType = framesFromWooCommerce.filter(
        (frame) => !isEmpty(frame.frameType)
      );

      setFrames(onlyFramesWithFrameType);
    };

    fetchFrames();

    const fetchPassepartout = async () => {
      const result = await axios(
        `/wp-json/tpc/v1/products?page=1&limit=100&category=passepartout`
      );
      const passepartoutFromWooCommerce = result.data.products;

      setPassepartout(passepartoutFromWooCommerce);
    };

    fetchPassepartout();

    const favourites = localStorage.getItem("favouriteIds")
      ? JSON.parse(localStorage.getItem("favouriteIds"))
      : [];
    setFavourites(favourites);
  }, []);

  return (
    <div>
      <SingleProductComponent frames={frames} passepartout={passepartout} />
    </div>
  );
};

export default SingleProductContainer;
