import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { isEmpty, truncate } from "lodash"
import { connect } from "react-redux"
import setShowOffCanvas from '../actions/header/setShowOffCanvas'
import { bindActionCreators } from "redux"
import useWindowSize from "../hooks/useWindowSize"
import useLockBodyScroll from "../hooks/useLockBodyScroll"
import OffCanvasExtraMenus from "./OffCanvasExtraMenus"

const OffCanvasMenu = (props) => {
  const [isStep, setIsStep] = useState(1)
  const [selectedMenu, setSelectedMenu] = useState({})
  const [menuAreaHeight, setMenuAreaHeight] = useState(null)

  useLockBodyScroll()
  const windowSize = useWindowSize()

  useEffect(() => {
    if (!isEmpty(selectedMenu)) {      
      const menuAreaHeightTemporaryVariable = windowSize.height - 56
      setMenuAreaHeight(menuAreaHeightTemporaryVariable)
    }
  }, [selectedMenu])

  const nextStepHandler = (menu) => {
    setIsStep(2)
    setSelectedMenu(menu)
  }

  const previousStepHandler = () => {
    setIsStep(1)
    setSelectedMenu({})
  }

  const fadeInFromLeftAnimation = {
    hidden: {
      opacity: 0,
      x: -200
    },
    visible: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      x: -200
    },
  }

  const fadeInFromRightAnimation = {
    hidden: {
      opacity: 0,
      x: 200
    },
    visible: {
      opacity: 1,
      x: 0
    },
    exit: {
      opacity: 0,
      x: 200
    },
  }

  console.log('megaMenuMenus', megaMenuMenus);
  console.log('selectedMenu', selectedMenu);

  return (
    <div className="relative">
      <div className="px-5">
        <div className={classNames('relative h-14 border-b border-solid border-gray flex items-center', {'justify-between': isStep > 1})}>
          <div className="cursor-pointer" onClick={() => props.actions.setShowOffCanvas(false)}>
            <FontAwesomeIcon icon={['fal', 'times']} className="block text-xl" />
          </div>
          <a href="/" className="block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" style={{height: '26px'}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 506 274"
              width="48"
              height="26"
            >
              <motion.path
                d="M72.415 151.728V65.553l49.687.687c3.825-2.425 4.525-19.45.7-23.275L72.415 46.09l1.037-44.137c-1.037-2.425-13.9-2.775-15.637 0-12.163 22.587-33.35 41.7-55.6 49.35-2.775 1.387-3.125 13.2 0 14.937h21.2l-2.775 99.384c0 28.154 21.887 45.879 52.475 45.879 29.187 0 52.812-20.163 61.5-45.18 1.387-6.612-7.3-11.12-12.163-6.95-7.637 13.905-17.725 19.117-27.8 19.117-12.85 0-22.237-9.041-22.237-26.762zm145.112-76.45l.688-36.838c-2.425-2.425-11.813-2.075-13.55 0-10.775 9.388-35.438 19.463-61.163 22.25-2.425 1.038-2.775 10.425 0 11.813 17.038 7.637 20.513 10.075 20.513 18.062v166.813l-17.375 2.775c-2.088 2.437-2.088 11.462 0 13.55h98.687c2.075-2.088 2.075-11.113 0-13.55l-27.8-2.775v-57.35c8.688 7.65 20.15 11.475 33.7 11.475 45.875 0 84.1-37.542 84.1-96.613 0-44.125-23.975-77.487-62.9-77.487-23.975 0-45.862 18.762-54.9 37.875zm0 10.075c5.563-9.375 15.638-17.025 28.15-17.025 21.888 0 34.738 23.625 34.738 62.55 0 39.258-17.025 62.9-36.825 62.9-11.475 0-21.9-9.388-26.063-26.067V85.353zM446.39 56.165c12.85 0 20.5 6.263 20.5 14.25 0 7.3-15.637 7.3-15.637 26.413 0 12.162 9.725 21.187 22.587 21.187 15.988 0 27.8-11.812 27.8-30.925 0-28.837-22.937-49.687-57.687-49.687-53.863 0-91.388 43.437-91.388 92.087 0 49.334 33.7 82.013 79.575 82.013 39.263 0 66.025-28.5 72.975-61.513-.35-4.862-7.3-7.3-11.812-4.862-10.775 18.408-25.375 25.708-43.788 25.708-26.762 0-50.387-19.808-50.387-57.683 0-34.4 22.237-56.988 47.262-56.988z"
                className="text-black fill-current"
              />
            </svg>
          </a>
        </div>

        <div className="py-7 border-b border-solid border-gray">
          <ul>
            {megaMenuMenus.map((menu, index) => 
              <li
                key={index}
                className="flex items-center justify-between font-display-regular text-black text-sm uppercase mb-10 last:mb-0 cursor-pointer"
                onClick={() => nextStepHandler(menu)}
              >
                {menu.mega_menu_menu_title}
                <FontAwesomeIcon icon={['fal', 'chevron-right']} className="block text-lg"/>
              </li>
            )}
          </ul>
        </div>

        <OffCanvasExtraMenus />
      </div>

      <AnimatePresence>
        {isStep === 2 &&
          <motion.div
            className="fixed top-0 left-0 h-screen w-screen bg-tpc-light"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeInFromRightAnimation}
            transition={{type: 'tween'}}
          >
            <div className="px-5">
              <div className={classNames('relative h-14 border-b border-solid border-gray flex items-center', {'justify-between': isStep > 1})}>
                <FontAwesomeIcon icon={['fal', 'chevron-left']} className="block text-lg" onClick={() => previousStepHandler()} />
                <a href="/" className="block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" style={{height: '26px'}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 506 274"
                    width="48"
                    height="26"
                  >
                    <motion.path
                      d="M72.415 151.728V65.553l49.687.687c3.825-2.425 4.525-19.45.7-23.275L72.415 46.09l1.037-44.137c-1.037-2.425-13.9-2.775-15.637 0-12.163 22.587-33.35 41.7-55.6 49.35-2.775 1.387-3.125 13.2 0 14.937h21.2l-2.775 99.384c0 28.154 21.887 45.879 52.475 45.879 29.187 0 52.812-20.163 61.5-45.18 1.387-6.612-7.3-11.12-12.163-6.95-7.637 13.905-17.725 19.117-27.8 19.117-12.85 0-22.237-9.041-22.237-26.762zm145.112-76.45l.688-36.838c-2.425-2.425-11.813-2.075-13.55 0-10.775 9.388-35.438 19.463-61.163 22.25-2.425 1.038-2.775 10.425 0 11.813 17.038 7.637 20.513 10.075 20.513 18.062v166.813l-17.375 2.775c-2.088 2.437-2.088 11.462 0 13.55h98.687c2.075-2.088 2.075-11.113 0-13.55l-27.8-2.775v-57.35c8.688 7.65 20.15 11.475 33.7 11.475 45.875 0 84.1-37.542 84.1-96.613 0-44.125-23.975-77.487-62.9-77.487-23.975 0-45.862 18.762-54.9 37.875zm0 10.075c5.563-9.375 15.638-17.025 28.15-17.025 21.888 0 34.738 23.625 34.738 62.55 0 39.258-17.025 62.9-36.825 62.9-11.475 0-21.9-9.388-26.063-26.067V85.353zM446.39 56.165c12.85 0 20.5 6.263 20.5 14.25 0 7.3-15.637 7.3-15.637 26.413 0 12.162 9.725 21.187 22.587 21.187 15.988 0 27.8-11.812 27.8-30.925 0-28.837-22.937-49.687-57.687-49.687-53.863 0-91.388 43.437-91.388 92.087 0 49.334 33.7 82.013 79.575 82.013 39.263 0 66.025-28.5 72.975-61.513-.35-4.862-7.3-7.3-11.812-4.862-10.775 18.408-25.375 25.708-43.788 25.708-26.762 0-50.387-19.808-50.387-57.683 0-34.4 22.237-56.988 47.262-56.988z"
                      className="text-black fill-current"
                    />
                  </svg>
                </a>
                <div className="cursor-pointer" onClick={() => props.actions.setShowOffCanvas(false)}>
                  <FontAwesomeIcon icon={['fal', 'times']} className="block text-xl" />
                </div>
              </div>

              <div className="overflow-y-auto" style={{height: `${menuAreaHeight}px`}}>
                {!isEmpty(selectedMenu) && selectedMenu.mega_menu_columns.map((column, index) =>
                  <ul key={index} className="first:mt-3 mb-9">
                    {column.mega_menu_column_title && 
                      <li>
                        <a
                          className="font-display-regular tracking-wider uppercase text-black"
                          style={{fontSize: '10px'}}
                        >
                          {column.mega_menu_column_title}
                          <span className="block w-11 bg-gray mt-2 mb-5" style={{height: '1px'}}></span>
                        </a>
                      </li>
                    }
                    {column.mega_menu_column_use_latest_posts
                      ? (
                        column?.mega_menu_column_latest_posts_array.map((latestPost, index) =>
                          <li key={index} className="mb-3 last:mb-0 flex items-center">
                            <a
                              href={latestPost?.post_slug}
                              className="block"
                              style={{width: '79px'}}
                            >
                              <img src={latestPost?.post_image[0]} width={latestPost?.post_image[1]} height={latestPost?.post_image[2]} className="block" />
                            </a>
                            <div className="py-3 flex flex-col" style={{marginLeft: '10px'}}>
                              <p className="font-body text-sm text-black">{latestPost?.post_title}</p>
                              {latestPost?.post_excerpt && <p className="font-body-regular text-xs text-gray-light" style={{maxWidth: '178px'}}>{truncate(latestPost?.post_excerpt, {'length': 50})}</p>}
                              <a href={latestPost?.post_slug} className="font-display-regular text-black uppercase tracking-wide relative inline-block mb-1 self-start" style={{fontSize: '10px'}}>
                                Read More
                                <span className="absolute left-0 bottom-0 bg-black w-full" style={{height: '1px', marginBottom: '-4px'}} />
                              </a>
                            </div>
                          </li>
                        )
                      )
                      : (
                        column.mega_menu_menu_items.map((menuItem, index) =>
                          <li key={index} className="mb-4 last:mb-0">
                            <a
                              href={menuItem.mega_menu_menu_item_custom_link_to ? menuItem.mega_menu_menu_item_custom_link_to : menuItem.mega_menu_menu_item_link_to.slug}
                              className="font-body-regular text-sm text-black"
                            >
                              {menuItem.mega_menu_custom_menu_item_title ? menuItem.mega_menu_custom_menu_item_title : menuItem.mega_menu_menu_item_link_to.name}
                            </a>
                          </li>
                        )
                      )
                    }
                  </ul>
                )}
              </div>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  const actions = {
    setShowOffCanvas,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
  }

  return actionMap
}

export default connect(null, mapDispatchToProps)(OffCanvasMenu)