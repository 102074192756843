import { useAtom } from "jotai";
import { showSizeGuideSidebarAtom } from "../../store";
import SidebarOuter from "./wrappers/SidebarOuter";
import SidebarInner from "./wrappers/SidebarInner";
import GallerySlider from "./product-info/GallerySlider";

const SizeGuideSidebar = () => {
  const [showSizeGuideSidebar, setShowSizeGuideSidebar] = useAtom(
    showSizeGuideSidebarAtom
  );

  console.log("sizeGuide", sizeGuide);

  return (
    <SidebarOuter
      title={sizeGuide?.title}
      showSidebar={showSizeGuideSidebar}
      setShowSidebar={setShowSizeGuideSidebar}
      extraCallbacks={[() => (window.location.hash = "")]}
    >
      <SidebarInner extraCallbacks={[() => (window.location.hash = "")]}>
        <div className="px-5 sm:px-8">
          <div
            className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
            dangerouslySetInnerHTML={{ __html: sizeGuide?.content }}
          />
          <a
            className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-8 underline underline-offset-4 inline-block"
            href={sizeGuide?.cta?.link}
          >
            {sizeGuide?.cta?.name}
          </a>
          <div className="mt-8">
            <GallerySlider gallery={sizeGuide?.gallery} />
          </div>
        </div>
      </SidebarInner>
    </SidebarOuter>
  );
};

export default SizeGuideSidebar;
