import setFavourites from './setFavourites'

export default function(object, favourites, search) {
  return dispatch => {
    const isShared = !_.isEmpty(search) ? true : false

    let favouritesUpdated = favourites.find(f => parseInt(f.id) === parseInt(object.id)) ? favourites.filter(f => parseInt(f.id) !== parseInt(object.id)) : [...favourites, object]

    if (!isShared) {
      localStorage.setItem('favouriteIds', JSON.stringify(favouritesUpdated))
    } else {
      const currentFavouritesFromLocalStorage = localStorage.getItem('favouriteIds');
      if (currentFavouritesFromLocalStorage) {
        favouritesUpdated = JSON.parse(currentFavouritesFromLocalStorage)
        const currentIndex = favouritesUpdated.findIndex(f => parseInt(f.id) === parseInt(object.id))
        if (currentIndex >= 0) {
          favouritesUpdated.splice(currentIndex, 1)
        } else {
          favouritesUpdated.push(object)
        }
      } else {
        favouritesUpdated = [object]
      }
      localStorage.setItem('favouriteIds', JSON.stringify(favouritesUpdated))
    }

    dispatch(setFavourites(favouritesUpdated))
  }
}