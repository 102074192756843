import {
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_MORE_PRODUCTS_REQUEST,
    FETCH_MORE_PRODUCTS_FAILURE,
    FETCH_MORE_PRODUCTS_SUCCESS,
    FILTER_PRODUCTS,
    SET_PRODUCT_PARAMS,
    SET_PRODUCT_SEARCH,
    SET_PRODUCT_CLICK
} from './../actions/const';

const initialState = {
    items: [],
    currItems: [],
    currParams: {
        page: 1
    },
    page: 1,
    allFetched: false,
    currSearch: '',
    isFetching: false,
    isFetchingMore: false,
    isFailed: false,
    searchNoResults: false,
    favouritesFromHash: [],
    clicked: false
};

export default (state = initialState, action) => {
    //console.log('statestate from products reducer', action, state);
    switch(action.type){
        case FETCH_PRODUCTS_REQUEST:
            return Object.assign({}, state, {isFetching: true, isFailed: false});
        case FETCH_MORE_PRODUCTS_REQUEST:
            return Object.assign({}, state, {isFetchingMore: true, isFailed: false});
        case FETCH_PRODUCTS_FAILURE:
        case FETCH_MORE_PRODUCTS_FAILURE:
            return Object.assign({}, state, {isFetching: false, isFailed: true});
        case FETCH_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                items: action.json.products,
                currItems: action.json.products,
                isFetching: false,
                isFailed: false,
                page: action.json.nextPage,
                allFetched: action.json.allFetched,
                searchNoResults: action.json.searchNoResults,
                favouritesFromHash: action.json.favouritesFromHash
            });
        case FETCH_MORE_PRODUCTS_SUCCESS:
            return Object.assign({}, state, {
                items: [...state.items, ...action.json.products],
                currItems: [...state.items, ...action.json.products],
                isFetchingMore: false,
                isFailed: false,
                page: action.json.nextPage,
                allFetched: action.json.allFetched,
                searchNoResults: action.json.searchNoResults,
                favouritesFromHash: action.json.favouritesFromHash
            })
        case FILTER_PRODUCTS:
            return Object.assign({}, state, {
                currItems: action.filteredItems,
                isFetching: false,
                isFailed: false
            });
        case SET_PRODUCT_PARAMS:
            return Object.assign({}, state, {
                currParams: action.params,
                currSearch: ''
            });
        case SET_PRODUCT_SEARCH:
            return Object.assign({}, state, {
                currParams: state.currParams,
                currSearch: action.searchString
            });
        case SET_PRODUCT_CLICK:
            return Object.assign({}, state, {
                clicked: action.clicked
            })
        default: {
            return state
        }
    }
}
