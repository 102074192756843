const ArtistSection = ({
  artist,
  artistImage,
  artistSecondaryImage,
  artistLink,
}) => {
  return (
    <div className="mt-12 sm:mt-20">
      <a
        href={artistLink ?? ""}
        className="font-display-regular text-[14px] leading-[16px] tracking-[0.6px] text-gray-light uppercase m-0"
      >
        Artist
      </a>
      <h2 className=" font-body-medium text-[32px] sm:text-[56px] tracking-[0.2px] leading-[38px] sm:leading-[60px] text-black mt-[10px] mb-8">
        <a href={artistLink ?? ""}>{artist?.post_title}</a>
      </h2>
      <div className="flex sm:gap-4">
        <a href={artistLink ?? ""} className="block w-full sm:w-1/2">
          <img
            className="block"
            src={artistImage[0]}
            width={artistImage[1]}
            height={artistImage[2]}
            alt=""
          />
        </a>
        {artistSecondaryImage && (
          <a href={artistLink ?? ""} className="hidden sm:block sm:w-1/2">
            <img
              className="block"
              src={artistSecondaryImage[0]}
              width={artistSecondaryImage[1]}
              height={artistSecondaryImage[2]}
              alt=""
            />
          </a>
        )}
      </div>
      {artist?.post_excerpt && (
        <div className="flex flex-col justify-center">
          <a
            href={artistLink ?? ""}
            className="max-w-[500px] font-body-regular text-[14px] leading-[18px] text-gray-light text-center mt-10 mb-0 mx-auto"
          >
            {artist?.post_excerpt}
          </a>
          {artistLink && (
            <a
              href={artistLink}
              className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-5 underline underline-offset-4 text-center"
            >
              Read more
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default ArtistSection;
