import { SET_ART_WALL_PRODUCT_FEED, SET_ART_WALL_PRODUCTS, SET_ART_WALL_ACTIVE_PRODUCT, SET_MINIMIZE_ART_WALL_PRODUCTS_SIDEBAR, SET_MINIMIZE_ART_WALL_PRODUCT_SIDEBAR, SET_POSTER_ON_TOP, SET_ART_WALL_CANVAS_HEIGHT, SET_SHOW_EDIT_ROOM_MODAL, SET_ACTIVE_ART_WALL_BACKGROUND, SET_ART_WALL_CANVAS_REF, SET_ART_WALL_SCREENSHOT, SET_SHOW_SHARE_AND_DOWNLOAD_MODAL, SET_ART_WALL_CANVAS_WIDTH, SET_FIRST_CLICK, SET_ART_WALL_SHAREABLE_URL, SET_ART_WALL_PRODUCTS_TO_BE_SHARED, SET_FRAMES, SET_SHOW_ART_WALL_CLEAR_ALL_MODAL, SET_SHOW_ART_WALL_CLOSE_MODAL, SET_ART_WALL_SCREENSHOT_STATUS } from './../actions/const'

const initialState = {
  artWallProducts: [],
  artWallProductFeed: 'allArtPrints',
  artWallActiveProduct: {},
  minimizeArtWallProductsSidebar: false,
  minimizeArtWallProductSidebar: true,
  posterOnTop: null,
  artWallCanvasHeight: null,
  showShareModal: false,
  activeArtWallBackground: [],
  artWallCanvasRef: null,
  artWallScreenshot: null,
  showShareAndDownloadModal: false,
  ArtWallCanvasWidth: null,
  firstClick: true,
  artWallShareableUrl: 'Generating shareable link...',
  artWallProductsToBeShared: [],
  frames: [],
  showArtWallClearAllModal: false,
  showArtWallCloseModal: false,
  artWallScreenshotStatus: 'generating',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ART_WALL_PRODUCTS:
      return Object.assign({}, state, {
        artWallProducts: action.artWallProducts,
      })

    case SET_ART_WALL_PRODUCT_FEED:
      return Object.assign({}, state, {
        artWallProductFeed: action.artWallProductFeed,
      })

    case SET_ART_WALL_ACTIVE_PRODUCT:
      return Object.assign({}, state, {
        artWallActiveProduct: action.artWallActiveProduct,
      })

    case SET_MINIMIZE_ART_WALL_PRODUCTS_SIDEBAR:
      return Object.assign({}, state, {
        minimizeArtWallProductsSidebar: action.minimizeArtWallProductsSidebar,
      })

    case SET_MINIMIZE_ART_WALL_PRODUCT_SIDEBAR:
      return Object.assign({}, state, {
        minimizeArtWallProductSidebar: action.minimizeArtWallProductSidebar,
      })

    case SET_POSTER_ON_TOP:
      return Object.assign({}, state, {
        posterOnTop: action.posterOnTop,
      })

    case SET_ART_WALL_CANVAS_HEIGHT:
      return Object.assign({}, state, {
        artWallCanvasHeight: action.artWallCanvasHeight,
      })

    case SET_SHOW_EDIT_ROOM_MODAL:
      return Object.assign({}, state, {
        showEditRoomModal: action.showEditRoomModal,
      })

    case SET_ACTIVE_ART_WALL_BACKGROUND:
      return Object.assign({}, state, {
        activeArtWallBackground: action.activeArtWallBackground,
      })

    case SET_ART_WALL_CANVAS_REF:
      return Object.assign({}, state, {
        artWallCanvasRef: action.artWallCanvasRef,
      })

    case SET_ART_WALL_SCREENSHOT:
      return Object.assign({}, state, {
        artWallScreenshot: action.artWallScreenshot,
      })

    case SET_ART_WALL_SCREENSHOT_STATUS:
      return Object.assign({}, state, {
        artWallScreenshotStatus: action.artWallScreenshotStatus,
      })

    case SET_SHOW_SHARE_AND_DOWNLOAD_MODAL:
      return Object.assign({}, state, {
        showShareAndDownloadModal: action.showShareAndDownloadModal,
      })

    case SET_ART_WALL_CANVAS_WIDTH:
      return Object.assign({}, state, {
        ArtWallCanvasWidth: action.ArtWallCanvasWidth,
      })

    case SET_FIRST_CLICK:
      return Object.assign({}, state, {
        firstClick: action.firstClick,
      })

    case SET_ART_WALL_SHAREABLE_URL:
      return Object.assign({}, state, {
        artWallShareableUrl: action.artWallShareableUrl,
      })

    case SET_ART_WALL_PRODUCTS_TO_BE_SHARED:
      return Object.assign({}, state, {
        artWallProductsToBeShared: action.artWallProductsToBeShared,
      })

    case SET_FRAMES:
      return Object.assign({}, state, {
        frames: action.frames,
      })
      
    case SET_SHOW_ART_WALL_CLEAR_ALL_MODAL:
      return Object.assign({}, state, {
        showArtWallClearAllModal: action.showArtWallClearAllModal,
      })

    case SET_SHOW_ART_WALL_CLOSE_MODAL:
      return Object.assign({}, state, {
        showArtWallCloseModal: action.showArtWallCloseModal,
      })

    default: {
      return state
    }
  }
}
