const getFrameVariationFromSize = (sizeVariation, size, frames) => {
  console.log("TESTING sizeVariation", sizeVariation);
  console.log("TESTING frames", frames);
  const frame = frames?.find((frame) => frame?.id == sizeVariation?.parent_id);
  const frameVariation = frame?.variations?.find(
    (variation) => variation?.sizeSlug == size?.slug
  );

  return frameVariation ?? false;
};

export default getFrameVariationFromSize;
