
let toggleAbout = () => {
  // console.log('toggleAbout');
  jQuery('.about-artist').click(function() {
    jQuery('.about-shipping-text').hide(200);
    jQuery('.about-artist-text').slideToggle(300);
    jQuery(this).find('.dropdown-arrow').toggleClass('rotate');
    if(jQuery('.about-shipping .dropdown-arrow').hasClass('rotate')) {
      jQuery('.about-shipping .dropdown-arrow').toggleClass('rotate');
    }

  });
}

let toggleShipping = () => {
  // console.log('toggleShipping');
  jQuery('.about-shipping').click(function() {
    jQuery('.about-artist-text').hide(200);
    jQuery('.about-shipping-text').slideToggle(300);
    jQuery(this).find('.dropdown-arrow').toggleClass('rotate');
    if(jQuery('.about-artist .dropdown-arrow').hasClass('rotate')) {
      jQuery('.about-artist .dropdown-arrow').toggleClass('rotate');
    }

  });
}

export {toggleAbout , toggleShipping};
