import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../utilities/isEmpty";
import clsx from "clsx";
import GallerySlider from "./GallerySlider";

const FrameAndPassepartoutDetails = ({
  selectedPassepartoutVariant,
  selectedFrameVariant,
}) => {
  const selectedProducts = [
    ...(!isEmpty(selectedPassepartoutVariant)
      ? [selectedPassepartoutVariant]
      : []),
    ...(!isEmpty(selectedFrameVariant) ? [selectedFrameVariant] : []),
  ].filter(({ id }) => id);

  console.log("frameAndPassepartoutDefaults", frameAndPassepartoutDefaults);
  console.log("frameAndPassepartoutFallbacks", frameAndPassepartoutFallbacks);
  console.log("selectedProducts", selectedProducts);

  // const [emblaRef, emblaApi] = useEmblaCarousel({
  //   loop: true,
  //   align: "start",
  //   slidesToScroll: 1,
  // });

  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const [scrollSnaps, setScrollSnaps] = useState([]);

  // const scrollPrev = useCallback(() => {
  //   if (emblaApi) emblaApi.scrollPrev();
  // }, [emblaApi]);

  // const scrollNext = useCallback(() => {
  //   if (emblaApi) emblaApi.scrollNext();
  // }, [emblaApi]);

  // const scrollTo = useCallback(
  //   (index) => {
  //     if (emblaApi) emblaApi.scrollTo(index);
  //   },
  //   [emblaApi]
  // );

  // const onInit = useCallback((emblaApi) => {
  //   setScrollSnaps(emblaApi.scrollSnapList());
  // }, []);

  // const onSelect = useCallback((emblaApi) => {
  //   setSelectedIndex(emblaApi.selectedScrollSnap());
  // }, []);

  // useEffect(() => {
  //   if (!emblaApi) return;

  //   onInit(emblaApi);
  //   onSelect(emblaApi);
  //   emblaApi.on("reInit", onInit);
  //   emblaApi.on("reInit", onSelect);
  //   emblaApi.on("select", onSelect);
  // }, [emblaApi, onInit, onSelect]);

  const determineContent = () => {
    if (selectedProducts?.length === 0) {
      if (isPassepartoutProduct) {
        return [
          {
            ...frameAndPassepartoutFallbacks?.frame,
            typeSlug: "fallback",
          },
        ];
      } else {
        return [
          { ...frameAndPassepartoutFallbacks?.frame, typeSlug: "fallback" },
          {
            ...frameAndPassepartoutFallbacks?.passepartout,
            typeSlug: "fallback",
          },
        ];
      }
    } else if (selectedProducts?.length === 1) {
      if (selectedProducts[0]?.type == "passepartout") {
        return [
          { ...selectedProducts[0], typeSlug: "product" },
          { ...frameAndPassepartoutFallbacks?.frame, typeSlug: "fallback" },
        ];
      } else if (selectedProducts[0]?.type == "frame") {
        if (isPassepartoutProduct) {
          return [{ ...selectedProducts[0], typeSlug: "product" }];
        } else {
          return [
            { ...selectedProducts[0], typeSlug: "product" },
            {
              ...frameAndPassepartoutFallbacks?.passepartout,
              typeSlug: "fallback",
            },
          ];
        }
      }
    } else if (selectedProducts?.length === 2) {
      return [
        { ...selectedProducts[1], typeSlug: "product" },
        { ...selectedProducts[0], typeSlug: "product" },
      ];
    }
  };

  console.log("determineContent", determineContent());

  return (
    <div className="flex flex-col gap-16">
      {/* {selectedProducts?.length > 0 ? (
        <div className="flex flex-col px-5 sm:px-8 gap-16">
          {selectedProducts?.reverse()?.map((product, index) => (
            <div key={index}>
              <h3 className="mt-0 mb-2 font-body-medium text-[18px] leading-6 tracking-[0.34px] text-black">
                {product?.parent?.name}
              </h3>
              <div
                className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
                dangerouslySetInnerHTML={{
                  __html: product?.parent?.description,
                }}
              />
              <div className="mt-4">
                <GallerySlider gallery={product?.parent?.gallery} />
              </div>
              <div
                className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0 mt-16"
                dangerouslySetInnerHTML={{
                  __html:
                    frameAndPassepartoutDefaults?.[product?.type]?.content,
                }}
              />
              <a
                className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-8 underline underline-offset-4 inline-block"
                href={frameAndPassepartoutDefaults?.[product?.type]?.cta?.link}
              >
                {frameAndPassepartoutDefaults?.[product?.type]?.cta?.name}
              </a>
            </div>
          ))}
        </div>
      ) : (
        <div className="px-5 sm:px-8">
          <div
            className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
            dangerouslySetInnerHTML={{
              __html: frameAndPassepartoutFallback?.content,
            }}
          />
          <a
            className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-8 underline underline-offset-4 inline-block"
            href={frameAndPassepartoutFallback?.cta?.link}
          >
            {frameAndPassepartoutFallback?.cta?.name}
          </a>
          <div className="mt-8">
            <GallerySlider gallery={frameAndPassepartoutFallback?.gallery} />
          </div>
        </div>
      )} */}
      <div className="flex flex-col px-5 sm:px-8 gap-16">
        {determineContent()?.map((item, index) =>
          item?.typeSlug == "product" ? (
            <div key={index}>
              <h3 className="mt-0 mb-2 font-body-medium text-[18px] leading-6 tracking-[0.34px] text-black">
                {item?.parent?.name}
              </h3>
              <div
                className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
                dangerouslySetInnerHTML={{
                  __html: item?.parent?.description,
                }}
              />
              <div className="mt-4">
                <GallerySlider gallery={item?.parent?.gallery} />
              </div>
              <div
                className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0 mt-12"
                dangerouslySetInnerHTML={{
                  __html: frameAndPassepartoutDefaults?.[item?.type]?.content,
                }}
              />
              <a
                className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-6 underline underline-offset-4 inline-block"
                href={frameAndPassepartoutDefaults?.[item?.type]?.cta?.link}
              >
                {frameAndPassepartoutDefaults?.[item?.type]?.cta?.name}
              </a>
            </div>
          ) : (
            <div key={index}>
              <div
                className="wysiwyg-content font-body-regular text-sm text-black [&>*:last-child]:mb-0"
                dangerouslySetInnerHTML={{
                  __html: item?.content,
                }}
              />
              <a
                className="font-display-bold text-xs tracking-[0.54px] text-black uppercase mt-6 underline underline-offset-4 inline-block"
                href={item?.cta?.link}
              >
                {item?.cta?.name}
              </a>
              <div className="mt-8">
                <GallerySlider gallery={item?.gallery} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FrameAndPassepartoutDetails;
