'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarFilterItem from './SidebarFilterItemComponent';
import { filter } from './../lib/globals';
import { filtering } from './../lib/tracking';
import { isEmpty } from 'lodash';
import RangeSlider from './../containers/RangeSlider';

class SidebarFilterComponent extends Component {
    constructor(props){
      super(props);

      this.trackClick = this.trackClick.bind(this);
    }
    trackClick(e, values = []){
        if(!isEmpty(values)){
            let label = `${values.join('-')} EUR`;
            filtering(label);
        }
    }
    render(){
        let { filters } = this.props;
        let { items } = filters;
        return(
            <div className="mobilefilter-component">
                <div className="filter-blocks">
                    { !filters.isFetching && !filters.isFailed ?
                        filter.map( (f, i) => {
                            return(
                              f.slug !== 'sortby' ?
                                <SidebarFilterItem
                                    name={f.name}
                                    slug={f.slug}
                                    item={items[f.slug]}
                                    key={i}
                                    handleChange={this.props.handleChange}
                                />
                              : ''
                            )
                    }) : '' }
                </div>
                { this.props.isToggled ? <RangeSlider trackClick={this.trackClick} slug={`price_range`} /> : '' }
            </div>
        )
    }
}

SidebarFilterComponent.displayName = 'SidebarFilterComponent';
SidebarFilterComponent.propTypes = {
    filters: PropTypes.shape({
        filterParams: PropTypes.object,
        items: PropTypes.object,
        isFetching: PropTypes.bool,
        isFailed: PropTypes.bool
    }),
    handleChange: PropTypes.func
}
SidebarFilterComponent.defaultProps = {
    filters: {
        filterParams: {},
        items: [],
        isFetching: false,
        isFailed: false
    },
    handleChange: () => {}
}

export default SidebarFilterComponent;
