'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LoadingComponent extends Component {
    render(){
        const { type } = this.props
        const loadingClasses = type === 'spinner' ? `loading-component` : `loading-component loading-component--text`
        return(
            <div className={loadingClasses}>
                { type === 'spinner' ? 
                    <div className="loading-component__loader" />
                : <p>Loading more ...</p> }
            </div>
        )
    }
}

LoadingComponent.displayName = 'LoadingComponent';

export default LoadingComponent;
