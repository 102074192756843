import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import { has } from 'lodash'
import { useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { generateFilterUrl } from '../lib/filter'
import { filter } from './../lib/globals'
import * as Slider from '@radix-ui/react-slider';
import { push } from 'react-router-redux';
import { JSONtoUrlStr, urlStrToJSON } from '../lib/parse'
import setShowArtWallFilters from '../actions/filters/setShowArtWallFilters';
import { bindActionCreators } from 'redux'
import useOnClickOutsideExtended from '../hooks/useOutsideClickExtended'

const PriceRange = ({ filterParams, filter, items, subFilters, search}) => {
  const [priceValues, setPriceValues] = useState([25, 150])
  const dispatch = useDispatch()

  const attr = `${priceValues[0]},${priceValues[1]}`

  const addPriceRangeToFilters = () => {
    dispatch(push(generateFilterUrl(filter.slug, attr, search)))
  }

  return (
    <div className="py-3">
      <Slider.Root
        defaultValue={[25, 150]}
        min={25}
        max={150}
        step={5}
        minStepsBetweenThumbs={1}
        onValueChange={(range) => setPriceValues(range)}
        className="relative flex items-center h-5 w-full"
      >
        <Slider.Track
          className="relative h-1 w-full border border-solid border-black"
        >
          <Slider.Range
            className="absolute h-full bg-tpc-darkest"
          />
        </Slider.Track>
        <Slider.Thumb
          className="block h-5 w-5 rounded-full bg-tpc-light border border-solid border-black"
        />
        <Slider.Thumb
          className="block h-5 w-5 rounded-full bg-tpc-light border border-solid border-black"
        />
      </Slider.Root>
      <div className="relative flex items-center justify-between mt-3">
        <AnimatePresence>
          {filterParams && has(filterParams, [filter.slug]) &&
            <motion.div
              key={filter.slug+'-active'}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{type: 'tween'}}
              className="absolute transform -translate-x-3 w-1 h-1 bg-black"
              style={{top: '40%'}}
            />
          }
        </AnimatePresence>
        <div className="flex items-center">
          <p className="font-display-regular font-normal text-xxs text-black tracking-wider uppercase py-1 mb-0"><span className="inline-block w-11">{`EUR ${priceValues[0]}`}</span><span className="inline-block" style={{marginRight: '6px'}}>-</span> <span className="inline-block w-11">{`EUR ${priceValues[1]}`}</span></p>
          <button
            type="button"
            className="block font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 px-3 bg-tpc-dark hover:bg-tpc-darker transition-colors duration-200 ease-in-out rounded-md ml-2"
            onClick={() => addPriceRangeToFilters()}
          >
            Set filter
          </button>
        </div>
        <AnimatePresence>
          {filterParams && has(filterParams, [filter.slug]) &&
            <motion.div
              key={filter.slug+'-clear'}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{type: 'tween'}}
            >
              <Link
                to={generateFilterUrl(filter.slug, attr, search)}
                // className="block font-display-regular font-normal text-xxs text-black tracking-wider px-3 py-1 bg-transparent hover:bg-tpc-dark transition-colors duration-200 ease-in-out rounded-md"
                className="block font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 px-3 bg-tpc-dark hover:bg-tpc-darker transition-colors duration-200 ease-in-out rounded-md ml-2"
              >
                clear
              </Link>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </div>
  )
}

const Accordion = ({ filter, onlyFilters, filters, i, expanded, setExpanded, search }) => {
  const isOpen = i === expanded

  const { items, filterParams } = filters

  const subFilters = items[filter.slug]

  const hasFilter = has(filterParams, [filter.slug])
  const length = onlyFilters.length

  const trueIndex = i + 1
  // console.log('trueIndex', trueIndex);

  const removeProp = filter.slug
  // console.log('removeProp', removeProp);
  const { [removeProp]: remove, ...rest } = urlStrToJSON(search)
  // console.log('rest', rest);

  const newUrlString = JSONtoUrlStr(rest)
  // console.log('newUrlString', newUrlString);

  return (
    <div className="flex flex-col w-full">
      <motion.header className="relative font-display-regular font-normal text-xxs text-black uppercase tracking-wider my-1 flex items-center justify-between px-8">
        <AnimatePresence>
          {hasFilter &&
            <motion.div
              key={filter.slug+'-active'}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{type: 'tween'}}
              className="absolute transform -translate-x-3 w-1 h-1 bg-black"
              style={{top: '40%'}}
            />
          }
        </AnimatePresence>
        <div className="flex items-center cursor-pointer" onClick={() => setExpanded(isOpen ? false : i)}>
          <span className="py-1 tracking-wider">{filter.name}</span>
          <FontAwesomeIcon icon={['fal', isOpen ? 'chevron-up' : 'chevron-down']} className="ml-2 block" style={{marginBottom: '1px' }}/>
        </div>
        <AnimatePresence>
          {hasFilter &&
            <motion.div
              key={filter.slug+'-clear'}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{type: 'tween'}}
            >
              <Link
                to={`/art-wall-designer${newUrlString}`}
                // className="block font-display-regular font-normal text-xxs text-black tracking-wider px-3 py-1 bg-transparent hover:bg-tpc-dark transition-colors duration-200 ease-in-out rounded-md uppercase"
                className="block font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 px-3 bg-tpc-dark hover:bg-tpc-darker transition-colors duration-200 ease-in-out rounded-md ml-2"
              >
                clear
              </Link>
            </motion.div>
          }
        </AnimatePresence>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {opacity: 1, height: "auto" },
              collapsed: {opacity: 0, height: 0 }
            }}
            transition={{ type: 'tween' }}
          >
            <div className={`border-t ${trueIndex === length ? '' : 'border-b'} ${trueIndex === length ? 'pt-1 mt-1 -mb-1' : 'py-1 my-1'} border-solid border-gray max-h-80 overflow-y-auto`}>
              <div className="px-8">
                {filter.slug === 'price_range'
                  ? (
                    <PriceRange filterParams={filterParams} filter={filter} items={items} subFilters={subFilters} search={search} />
                  )
                  : (
                    <div className="flex flex-wrap -ml-8">
                      {Array.isArray(subFilters) && subFilters.map((subFilter, i) =>
                        <div key={i} className="relative w-1/2 pl-8 flex items-center">
                          {/* <AnimatePresence>
                            {subFilter.term_id && filterParams && has(filterParams, [filter.slug]) && filterParams[filter.slug].split('&').includes(subFilter.term_id.toString()) &&
                              <motion.div
                                key={subFilter.slug+'-active'}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{type: 'tween'}}
                                className="absolute transform -translate-x-3 w-1 h-1 bg-black"
                                style={{top: '40%'}}
                              />
                            }
                          </AnimatePresence> */}
                          
                          <Link to={generateFilterUrl(filter.slug, subFilter, search)} className="flex items-center box-border font-display-regular font-normal text-xxs text-black uppercase tracking-wider py-1 my-1">
                            <div className="w-3 h-3 border border-solid border-black mr-2 flex justify-center items-center">
                              <AnimatePresence>
                                {subFilter.term_id && filterParams && has(filterParams, [filter.slug]) && filterParams[filter.slug].split('&').includes(subFilter.term_id.toString()) &&
                                  <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{type: 'tween'}}
                                    style={{top: '40%'}}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'times']} className="block" />
                                  </motion.div>
                                }
                              </AnimatePresence>
                            </div>
                            <span style={{transform: 'translateY(1px)'}} dangerouslySetInnerHTML={{__html: subFilter.name}} />
                          </Link>
                          
                          {/* <AnimatePresence>
                            {subFilter.term_id && filterParams && has(filterParams, [filter.slug]) && filterParams[filter.slug].split('&').includes(subFilter.term_id.toString()) &&
                              <motion.div
                                key={subFilter.slug+'-clear'}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{type: 'tween'}}
                              >
                                <Link
                                  to={generateFilterUrl(filter.slug, subFilter, search)}
                                  className="block font-display-regular font-normal text-xxs text-black tracking-wider px-3 py-1 bg-transparent hover:bg-tpc-dark transition-colors duration-200 ease-in-out rounded-md"
                                >
                                  clear
                                </Link>
                              </motion.div>
                            }
                          </AnimatePresence> */}
                        </div>
                      )}
                    </div>
                  )
                }
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

const ArtWallFiltersComponent = ({ filters, search, actions, buttonRef }) => {
  const [expanded, setExpanded] = useState(false)
  
  const onlyFilters = filter.filter((f) => f.slug !== 'sortby')

  const filterModalRef = useRef(null)
  useOnClickOutsideExtended(filterModalRef, buttonRef, () => actions.setShowArtWallFilters(false))

  return (
    <ul ref={filterModalRef} className="py-2">
      {onlyFilters.map((f, i) =>
        <li key={i} className="relative flex items-center">
          <Accordion filter={f} onlyFilters={onlyFilters} filters={filters} i={i} expanded={expanded} setExpanded={setExpanded} search={search} />
        </li>
      )}
    </ul>
  )
}

const mapStateToProps = (state) => {
  const props = {
    search: state.router.location.search,
  }

  return props
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setShowArtWallFilters,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }

  return actionMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallFiltersComponent)