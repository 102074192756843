export const filtering = (label) => {
  if (typeof ga == "function") {
    ga("send", {
      hitType: "event",
      eventCategory: "Filter",
      eventAction: "Select filter from list",
      eventLabel: label,
      hitCallback: (resp) => {
        //console.log('hit callback, tracking done', resp);
      },
    });
  } else {
    //console.log('no ga function!', label);
  }
};

export const search = (label) => {
  if (typeof ga == "function") {
    ga("send", {
      hitType: "event",
      eventCategory: "Search",
      eventAction: "Submit search form",
      eventLabel: label,
      hitCallback: (resp) => {
        //console.log('hit callback, tracking done', resp);
      },
    });
  } else {
    //console.log('no ga function', label);
  }
};

export const fbTrackAddToCart = ({ data }) => {
  if (typeof fbq == "function") {
    fbq("track", "AddToCart", {
      content_type: data.contentType,
      content_name: data.name,
      content_ids: data.id,
      currency: data.currency,
      value: data.value,
      contents: [
        {
          id: data.id,
          quantity: data.quantity,
          item_price: data.value,
        },
      ],
    });
  }
};

export const GA4AddToCart = ({ items = [] }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: items.map((item) => {
        return {
          item_name: item.name,
          // item_id: getVariantSkuFromFullSku({sku: item.sku}),
          item_id: item.id,
          price: parseInt(item.price).toFixed(2),
          item_brand: item.artist,
          item_category: item.category,
          item_variant: item.size, // @TODO
          quantity: item.quantity,
          currency: item.currency,
          item_list_name: item.listName || "",
          affiliation: item.affiliation || "",
        };
      }),
    },
  });
};
