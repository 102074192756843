'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarFilterAttribute from './SidebarFilterAttributeComponent';

import PriceToggle from './../containers/PriceToggle';

class SidebarFilterItemComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        selected: null
      }

      this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(event) {
      this.setState({
        selected: event.target.options[event.nativeEvent.target.selectedIndex].text
      })
    }

    render(){
        let { item, name, slug } = this.props;

        return(
            <div className="mobile-filters">
                <div className="wrapper-dropdown" tabIndex="1" data-selection={name}>
                  { slug == 'price_range' ?
                  <PriceToggle />
                :
                <select className="filter-select" onChange={(event) => {
                  this.props.handleChange(event);
                  this.handleSelect(event);
                }}>
                    <option>{name}</option>
                    { item.map( (attribute, i) => {
                        return(<SidebarFilterAttribute key={i} slug={slug} attribute={attribute} />)
                    }) }
                </select>}
                </div>
            </div>
        )
    }
}

SidebarFilterItemComponent.displayName = "SidebarFilterItemComponent";
SidebarFilterItemComponent.propTypes = {
    item: PropTypes.array,
    handleChange: PropTypes.func
};
SidebarFilterItemComponent.defaultProps = {
    item: [],
    handleChange: () => {}
};

export default SidebarFilterItemComponent;
