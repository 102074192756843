import { TOGGLE_SEARCH } from './../actions/const';

let initialState = {
    isToggled: false
}

export default (state = initialState, action) => {
    switch(action.type){
        case TOGGLE_SEARCH:
            return Object.assign({}, state, {
                isToggled: action.toggle
            });
        default: {
            return state
        }
    }
}
