'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NoItemsComponent extends React.Component {
  render() {
    const { pathname } = this.props
    const isFavouritesRoute = pathname === '/favourites/' ? true : false
    return (
      <div className={`noitems-component ${isFavouritesRoute && 'favourites'}`}>
        { isFavouritesRoute && <FontAwesomeIcon className="icon-outline" icon={['fal', 'heart']} size="4x" /> }
        { isFavouritesRoute && <p><strong>Your favourite list is currently empty</strong></p> }
        <p className="woocommerce-info">{isFavouritesRoute ? 'Add all your favourites to this list for saving and sharing' : 'No posters were found matching your selection.'}</p>
        <a href="/artprints" id="favourites-no-favourites-button" className="button alt back-button">{ isFavouritesRoute ? 'Explore what\'s new' : 'Clear all filters'}</a>
      </div>
    );
  }
}

NoItemsComponent.displayName = 'NoItemsComponent';

// Uncomment properties you need
// NoItemsComponent.propTypes = {};
// NoItemsComponent.defaultProps = {};

// export default NoItemsComponent;

const mapStateToProps = (state) => {
  const props = {
    pathname: state.router.location.pathname,
  }

  return props;
}

export default connect(mapStateToProps, null)(NoItemsComponent);