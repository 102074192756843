'use strict';

let toggleThis = function() {
  let mainIdentifier = this.classList[0],
      toggledClass = `${mainIdentifier}--toggled`;

  return function(toggleState = null) {
    toggleState = toggleState !== null ? toggleState : !this.classList.contains(toggledClass);
    this.classList.toggle(toggledClass, toggleState);
    let toggledclas = this.classList.toggle(toggledClass, toggleState);
    console.log('toggleState', toggleState, 'classList.toggle', toggledclas);
  }.bind(this);
}

export default toggleThis;
