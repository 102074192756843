const getPassepartoutVariationFromSize = (
  sizeVariation,
  size,
  passepartout
) => {
  console.log("TESTING sizeVariation", sizeVariation);
  console.log("TESTING passepartout", passepartout);
  const passepartoutItem = passepartout?.find(
    (passepartoutItem) => passepartoutItem?.id == sizeVariation?.parent_id
  );
  const passepartoutVariation = passepartoutItem?.variations?.find(
    (variation) => variation?.sizeSlug == size?.slug
  );

  return passepartoutVariation ?? false;
};

export default getPassepartoutVariationFromSize;
