import { 
  SET_FAVOURITES,
  SET_SHAREABLE_URL,
  SET_HASH,
  SET_BUY_ERROR,
  SET_BUY_ERROR_SINGLE,
  SET_LAST_HAS_OVERLAY,
  SET_ADDED_TO_CART,
  SET_SHOW_MODAL,
  SET_CLICK_COUNT } from './../actions/const';

const initialState = {
  favourites: [],
  showModal: false,
  clickCount: 0,
  shareableUrl: 'Generating shareable link...',
  buyError: false,
  buyErrorSingle: false,
  lastHasOverlay: '',
  addedToCart: false,
};

export default (state = initialState, action) => {
  switch(action.type){
    case SET_FAVOURITES:
      return Object.assign({}, state, {
        favourites: action.favourites,
      });
    case SET_SHOW_MODAL:
      return Object.assign({}, state, {
        showModal: action.showModal,
      });
    case SET_CLICK_COUNT:
      return Object.assign({}, state, {
        clickCount: state.clickCount + 1,
      });
    case SET_SHAREABLE_URL:
      return Object.assign({}, state, {
        shareableUrl: action.shareableUrl,
      });
    case SET_HASH:
      return Object.assign({}, state, {
        hash: action.hash,
      });
    case SET_BUY_ERROR:
      return Object.assign({}, state, {
        buyError: action.buyError,
      });
      case SET_BUY_ERROR_SINGLE:
        return Object.assign({}, state, {
          buyErrorSingle: action.buyErrorSingle,
        });
    case SET_LAST_HAS_OVERLAY:
      return Object.assign({}, state, {
        lastHasOverlay: action.lastHasOverlay,
      });
    case SET_ADDED_TO_CART:
      return Object.assign({}, state, {
        addedToCart: action.addedToCart,
      });
    default: {
      return state
    }
  }
}
