export const createFavouritesSlice = (set) => ({
  favourites: [],
  setFavourites: (favouritesFromLocalStorage) =>
    set((state) => ({ favourites: favouritesFromLocalStorage })),
  updateFavourites: (product, favourites, search) =>
    set((state) => {
      let newFavourites = favourites.find(
        (f) => parseInt(f.id) === parseInt(product.id)
      )
        ? favourites.filter((f) => parseInt(f.id) !== parseInt(product.id))
        : [...favourites, product];

      if (search) {
        console.log(
          "🌟 UPDATE FAVOURITES -*( search condition )*-",
          newFavourites
        );
        localStorage.setItem("favouriteIds", JSON.stringify(newFavourites));
        return { favourites: newFavourites };
      } else {
        const currentFavouritesFromLocalStorage =
          localStorage.getItem("favouriteIds");
        if (currentFavouritesFromLocalStorage) {
          newFavourites = JSON.parse(currentFavouritesFromLocalStorage);

          const currentIndex = newFavourites.findIndex(
            (f) => parseInt(f.id) === parseInt(product.id)
          );

          if (currentIndex >= 0) {
            newFavourites.splice(currentIndex, 1);

            console.log(
              "🌟 UPDATE FAVOURITES -*( currentIndex >= 0 )*-",
              newFavourites
            );
            localStorage.setItem("favouriteIds", JSON.stringify(newFavourites));
            return { favourites: newFavourites };
          } else {
            newFavourites.push(product);

            console.log(
              "🌟 UPDATE FAVOURITES -*( currentIndex else )*-",
              newFavourites
            );
            localStorage.setItem("favouriteIds", JSON.stringify(newFavourites));
            return { favourites: newFavourites };
          }
        } else {
          newFavourites = [product];

          console.log(
            "🌟 UPDATE FAVOURITES -*( no current favourites )*-",
            newFavourites
          );
          localStorage.setItem("favouriteIds", JSON.stringify(newFavourites));
          return { favourites: newFavourites };
        }
      }
    }),
});
