import { pick, isObject } from 'underscore';
import { urlStrToJSON, JSONtoUrlStr } from './parse';
import { filter } from './globals';

export const initialFilters = {
    sortby: [],
    size_category: [],
    size: [],
    orientation: [],
    price_range: [],
    color: [],
    artist: [],
    poster_type: [],
    collections: []
}

export const trimParams = (params) => {
    let fKeys = ['size_category', 'page'],
        len = filter.length;
    for (let i = 0; i < len; i++) {
        fKeys.push(filter[i].slug);
    }
    params = pick(params, fKeys);
    return params;
}

export const trimSearch = (params) => {
    params = pick(params, 's');
    return params.s;
}

export const generateFilterUrl = (slug, attr, search) => {
    let url;
    if(slug == 'sortby' && attr.slug == 'kids'){
        slug = 'poster_type';
    }
    if(!search){
        url = isObject(attr) ? `?${slug}=${attr.term_id}` : `?${slug}=${attr}`;
        return url;
    }
    let query;
    if(typeof search == 'string' || search instanceof String){
        query = urlStrToJSON(search);
    } else {
        query = search;
    }
    query = trimParams(query);

    if(slug == 'price_range'){
        if(query[slug] && query[slug] === attr){
            query[slug] = undefined;
            query = JSON.parse(JSON.stringify(query));
        } else {
            query[slug] = attr;
        }
    } else {
        //console.log('generating filter url', query[slug], slug);
        let termId = attr.term_id ? attr.term_id.toString() : false;
        if (termId) {
        if(!query[slug]){
            query[slug] = termId;
        } else if(query[slug] == termId){
            query[slug] = undefined;
            query = JSON.parse(JSON.stringify(query));
        } else if(query[slug].indexOf('&') > -1){
            let subQuery = query[slug].split('&'),
            index = subQuery.indexOf(termId);
            index > -1 ? subQuery.splice(index, 1) : subQuery.push(termId);
            query[slug] = subQuery.join('&');
        } else if(query[slug] && query[slug] !== termId){
            if(slug == 'sortby'){
                query[slug] = termId;
            } else {
                query[slug] = query[slug]+'&'+termId;
            }
        } else {
            query[slug] = termId;
        }
        }
    }

    url = JSONtoUrlStr(query);
    return url;
}