import { useEffect } from "react";

const useClickAway = (refArray, handlers) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        refArray.some((ref) => !ref.current) ||
        refArray.some((ref) => ref.current.contains(event.target))
      )
        return;

      handlers.forEach((handler) => {
        handler(event);
      });
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refArray, handlers]);
};

export default useClickAway;
