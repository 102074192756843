'use strict';

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setShowModal from '../actions/favourites/setShowModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

class FavouritesModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareUrl: '',
      copyText: 'Copy',
    }

    this.onClickHandlerClose = this.onClickHandlerClose.bind(this)
    this.onClickHandlerCopy = this.onClickHandlerCopy.bind(this)
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    this.onEscapeHandler = this.onEscapeHandler.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.onClickOutsideHandler);
    window.addEventListener('keydown', this.onEscapeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.onClickOutsideHandler);
    window.removeEventListener('keydown', this.onEscapeHandler);
  }

  onClickHandlerClose () {
    this.props.actions.setShowModal(false)
  }

  onClickHandlerCopy () {
    const favouritesCopyLinkRef = this.refs.favouritesCopyLink

    const selection = window.getSelection()
    selection.removeAllRanges()

    const range = document.createRange()
    range.selectNodeContents(favouritesCopyLinkRef)
    selection.addRange(range)

    document.execCommand('copy')
    selection.removeAllRanges()

    this.setState({
      copyText: 'Copied',
    })

    setTimeout(() => {
      this.setState({
        copyText: 'Copy',
      })
    }, 1500)
  }

  onClickOutsideHandler(e) {
    const favouritesModalContainerRef = this.refs.favouritesModalContainer

    if (favouritesModalContainerRef && !favouritesModalContainerRef.contains(e.target)) {
      this.props.actions.setShowModal(false)
    }
  }

  onEscapeHandler(e) {
    const favouritesModalContainerRef = this.refs.favouritesModalContainer

    if (favouritesModalContainerRef && e.key === 'Escape') {
      this.props.actions.setShowModal(false)
    }
  }

  render() {
    const { showModal, shareableUrl } = this.props.favourites

    return(
      <div className={classNames('favourites-modal', { 'open': showModal })}>
        <div className="favourites-modal__background"></div>
        <div className="favourites-modal__container" ref='favouritesModalContainer'>
          <FontAwesomeIcon className="favourites-modal-close" icon={['fal', 'times']} size='lg' onClick={this.onClickHandlerClose} />
          <p className="favourites-modal-text">Share favourites</p>
          <div className="favourites-copy-wrappper">
            <p className="favourites-copy-link" ref='favouritesCopyLink'>{shareableUrl}</p>
            <button className="favourites-copy-button" onClick={this.onClickHandlerCopy}>{this.state.copyText}</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites,
  }

  return props;
}


function mapDispatchToProps(dispatch) {
  const actions = {
    setShowModal,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
  };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesModalComponent);