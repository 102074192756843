'use strict';

import toggleThis from './toggleThis';

let toggleMobileFilters = function(toggleState = null) {
  let mobileFilters = document.getElementById('offcanvas_filter')
  let menuToggle = document.getElementById('offcanvas__toggle')
  // let menu = document.getElementById('offcanvas')

  let _t1 = toggleThis.call(mobileFilters)
  let _t2 = toggleThis.call(menuToggle)
  // let _t3 = toggleThis.call(menu)

  if (toggleState !== null) {
    _t1(toggleState);
    _t2(toggleState);
    // _t3(false);
  } else if (mobileFilters.classList.contains('offcanvas-filter--toggled') ) {
    _t1(false);
    _t2(false);
  } else if (!mobileFilters.classList.contains('offcanvas-filter--toggled') ) {
    _t1(true);
    _t2(true);
  }
}

export default toggleMobileFilters;
