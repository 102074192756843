import { AnimatePresence, motion } from "framer-motion";
import { Fragment, cloneElement, useRef } from "react";
import { fadeAnimation, fadeFromRightAnimation } from "../../../animations";
import useMediaQuery from "../../../hooks/useMediaQuery";
import useClickAway from "../../../hooks/useClickAway";

const SidebarOuter = ({
  children,
  title,
  showSidebar,
  setShowSidebar,
  extraCallbacks,
}) => {
  const ref = useRef(null);

  useClickAway(
    [ref],
    [
      () => setShowSidebar(false),
      ...(extraCallbacks?.length > 0 ? [...extraCallbacks] : []),
    ]
  );

  const md = useMediaQuery("(min-width: 768px)");

  return (
    <AnimatePresence mode="wait">
      {showSidebar && (
        <Fragment key="">
          {md && (
            <motion.div
              key=""
              className="fixed left-0 top-0 z-[1111] h-[100dvh] w-full bg-black"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.3 }}
              exit={{ opacity: 0 }}
              variants={fadeAnimation}
            />
          )}
          <motion.div
            ref={ref}
            key=""
            className="fixed bottom-0 right-0 top-[56px] sm:top-0 z-[2222] h-[calc(100dvh-56px)] sm:h-[100dvh] w-full bg-white md:w-[600px]"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeFromRightAnimation}
            transition={{ type: "tween" }}
          >
            {cloneElement(children, {
              title: title,
              setShowSidebar: setShowSidebar,
            })}
          </motion.div>
        </Fragment>
      )}
    </AnimatePresence>
  );
};

export default SidebarOuter;
