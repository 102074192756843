import HomestoriesGridComponent from "../components/HomestoriesGridComponent"
import React, { Component } from 'react';
import { endpoints } from './../lib/globals';
import axios from "axios";
import Loading from '../components/LoadingComponent';


class LoadMoreHomestories extends Component {

  state = {
    posts: [],
    isLoading: false,
    errorMsg: '',
    page: 0,
    maxPage: false,
    morePosts: true,
    alreadyUsedPostsState: [],
    checkingUsedPosts: true
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.loadPosts();
    }
  }

  alreadyUsedPosts = () => {
    let usedPosts = [];
    document.querySelectorAll('.homestories-grid-item').forEach((post)=>{
      usedPosts.push(post.dataset.homestoriesPost)
    })
    return usedPosts;
  };

  checkIfAllPostsAreAlreadyUsed = async () => {

    const alreadyUsed = this.alreadyUsedPosts();
    const { checkingUsedPosts } = this.state;
    let allUsed = true;

    const response = await axios.get(endpoints.homestories + '?_fields=id&custom_per_page=1000');

    response.data.forEach( (post) => {
      if( !alreadyUsed.includes( post.id.toString(  ) ) ){
        allUsed =  false;
      }
    } )

    this.setState({ checkingUsedPosts: allUsed });
  };

  loadPosts = async () => {

    const { maxPage } = this.state;
    const { page } = this.state;
    const { alreadyUsedPostsState } = this.state;
    this.setState({ alreadyUsedPostsState: this.alreadyUsedPosts() });

    if( maxPage === false || page <= maxPage ) {
       
      try {
        this.setState({ isLoading: true });
        const response = await axios.get(endpoints.homestories + '?page=' + page + '&per_page=6&_embed');
        this.setState((prevState) => ({
          posts: [...prevState.posts, ...response.data],
        }));
        this.setState({
          maxPage: response.headers['x-wp-totalpages'],
          morePosts: response.headers['x-wp-totalpages'] == page ? false : true,
        })
      } catch (error) {
        this.setState({
          errorMsg: 'Error while loading data. Try again later.'
        })      
      } finally {
        this.setState({ isLoading: false });
      }; 
    }      
  }

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1
    }));
  };

  render() {
    const { posts, isLoading, errorMsg, morePosts, alreadyUsedPostsState, checkingUsedPosts } = this.state;
    const allUsed = this.checkIfAllPostsAreAlreadyUsed();
    // console.log(checkingUsedPosts)
    // console.log(allUsed)
    if( !checkingUsedPosts ) {
      return (
        <div className="load-more-container w-full flex flex-col align-center">

          <HomestoriesGridComponent loadedPosts = { posts } alreadyUsed = { alreadyUsedPostsState } />
          
          {isLoading && <Loading type={`spinner`} />}
          {errorMsg && <p className="errorMsg text-center pt-6 pb-6">{errorMsg}</p>}

          {allUsed && morePosts && <button className="uppercase mr-auto ml-auto mt-12 mb-18 w-85 h-11 [ border border-solid border-black ] font-display-bold text-xs text-black hover:text-gray-light hover:border-gray-light" onClick={this.loadMore} >
            Load more
          </button>}

        </div>

      )      
    } else {
      return (
        <div></div>
      );
    }

  }

}

export default LoadMoreHomestories