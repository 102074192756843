'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import togglePrice from './../actions/price/togglePrice';

// import SearchToggleComponent from './../components/SearchToggleComponent';


class PriceToggle extends Component {
    constructor(props){
        super(props);

        this.handleTogglePrice = this.handleTogglePrice.bind(this);
    }
    handleTogglePrice(e){
        e.preventDefault();
        let { isToggled } = this.props.price;
        this.props.togglePrice(!isToggled);
    }
    render(){
        return(
            <div className="price-slider__trigger" onClick={this.handleTogglePrice}/>
        )
    }
}

let mapStateToProps = (state) => {
    const props = {
        price: state.price
    };

    return props;
}

let mapDispatchToProps = (dispatch) => {
    const actions = {};

    let actionMap = {
        togglePrice: (toggle) => {
            dispatch(togglePrice(toggle))
        }
    }

    return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceToggle);
