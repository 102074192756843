// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Breadcrumb = () => {
  const determineArchivePageName = () => {
    if (isPassepartoutProduct) {
      return "Passepartout";
    } else if (isFrameProduct) {
      return "Frames";
    } else if (isWallObjectsProduct) {
      return "Wall objects";
    } else if (isLimitedEditionsProduct) {
      return "Limited editions";
    } else {
      return "Art prints";
    }
  };

  return (
    <div className="flex gap-2 items-center my-6 text-gray-light font-display-regular font-normal text-[11px] leading-[14px] tracking-[0.5px] uppercase">
      <a href={"/"} className="transition-colors duration-200 ease-in-out">
        <img className="w-4 h-4 mb-[2px]" src={tpcLogoSymbol} alt="" />
      </a>
      <span className="mb-[2px]">›</span>
      <a
        href={shopUrl}
        className="hover:text-black transition-colors duration-200 ease-in-out"
      >
        {determineArchivePageName()}
      </a>
      <span className="mb-[2px] hidden sm:inline-block">›</span>
      {artistPost?.post_title && artistPostLink && (
        <>
          <a
            href={artistPostLink}
            className="hover:text-black transition-colors duration-200 ease-in-out hidden sm:inline-block"
          >
            {artistPost.post_title}
          </a>
          <span className="mb-[2px] hidden sm:inline-block">›</span>
        </>
      )}
      <p className="m-0 text-black hidden sm:inline-block">
        {singleProductName}
      </p>
    </div>
  );
};

export default Breadcrumb;
