'use strict';

import React, { Component } from 'react';
import Loading from './LoadingComponent';

class NewsletterPageComponent extends Component {
	render() {
		const responseClass = this.props.hasError ? 'clear error-message' : 'clear info-message';
		return (
			<div className="mc_embed_signup">
				<div className="mc_embed_signup__form">
					<h3 id="mc-embedded-subscribe-form" className="validate">NEWSLETTER</h3>
					<p className="validate">Keep in touch with our design releases, exclusive features and great offers.</p>
        	<p className="validate">Sign up for our monthly newsletter:</p>
					<form onSubmit={this.props.handleSubmit} className="validate">
						<div className="mc-field-group">
							<input ref={(input) => {this.input = input;}} type='email' name='email' value={this.props.value} onChange={this.props.changeFunc} onBlur={this.props.changeFunc} className='newsletter__input' placeholder='Enter e-mail here...' />
						</div>
						<div className="clear">
							<input type='submit' name="subscribe" value='Subscribe' className='button' />
						</div>
						{ this.props.isSubmitting ? <Loading /> : null}
						{ this.props.hasError || this.props.isAdded ?
						<div className={responseClass}>
							{this.props.response}
						</div>
						: null}
					</form>
				</div>
				<div className="mc_embed_signup__img">
        	<img className="alignnone size-large wp-image-11133" src="/wp-content/themes/posterclub/img/newsletter-new.jpg" alt="Hein Studio - The Wise Man" width="683" height="1024" />
      	</div>
			</div>
		)
	}
}

export default NewsletterPageComponent;