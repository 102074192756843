import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';
import products from './products';
import filters from './filters';
import search from './search';
import price from './price';
import favourites from './favourites';
import header from './header';
import artWall from './artWall';
import currencyPicker from './currencyPicker';

const createRootReducer = (history) => combineReducers({
    // routing: routerReducer,
    router: connectRouter(history),
    filters: filters,
    products: products,
    search: search,
    price: price,
    favourites: favourites,
    header: header,
    artWall: artWall,
    currencyPicker: currencyPicker,
});

export default createRootReducer;
