'use strict'

class outsideClick {
	constructor() {
		this._elements = []
		this._ctx = document.body
		this._check = (target, matchTarget) => {
			let current = target,
					outside;

			while ( current !== this._ctx && !current.classList.contains('trigger') ) {
				if( current === matchTarget ) {
					return true
				}
				current = current.parentNode
			}

			return false

		}
		this._ctx.addEventListener('click', e => {
			if( !e.target.classList.contains('trigger') ) {
				this._elements.forEach((elem) => {
					if ( !this._check( e.target, elem.el ) ) {
						elem.callBack()
					}
				})
			}
		})
	}

	add(el, callBack) {
		let a = {
			'el': el,
			'callBack': callBack
		}
		this._elements.push(a)
	}
}

export default outsideClick
