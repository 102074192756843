'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchForm from './SearchFormComponent';

class SearchComponent extends Component {
    render() {
        return(
            <div className="search-component">
                { this.props.isToggled ?
                    <SearchForm
                        handleSubmit={this.props.handleSubmit}
                        toggleSearch={this.props.toggleSearch}
                    />
                    : ''
                }
            </div>
        )
    }
}

SearchComponent.displayName = 'SearchComponent';
SearchComponent.propTypes = {
    isToggled: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleSearch: PropTypes.func
}
SearchComponent.defaultProps = {
    isToggled: false,
    handleSubmit: () => {},
    toggleSearch: () => {}
}

export default SearchComponent;
