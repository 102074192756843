'use strict';

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setShowModal from '../actions/favourites/setShowModal'
import setShareableUrl from '../actions/favourites/setShareableUrl'
import setHash from '../actions/favourites/SetHash'
import axios from 'axios'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { urlStrToJSON } from '../lib/parse';

class FavouritesShareButtonComponent extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      initialLocalStorage: [],
      isFirstClick: true,
      sharediInitialLocalStorage: [],
    }

    this.onClickHandlerOpen = this.onClickHandlerOpen.bind(this)
    this.saveFavouritesToDatabase = this.saveFavouritesToDatabase.bind(this)
  }

  componentDidMount() {
    const { favourites } = this.props.favourites

    this.setState({
      initialLocalStorage: favourites
    })
  }

  onClickHandlerOpen(type) {
    if( type === 'link' ) {
      this.props.actions.setShowModal(true)
    }
    
    const { favourites } = this.props.favourites
    const { initialLocalStorage, isFirstClick } = this.state

    if (isFirstClick || initialLocalStorage.sort().toString() !== favourites.sort().toString()) {
      this.saveFavouritesToDatabase(type)
    } else {
      if( type === 'sms' ) {
        window.location.href = `sms:&body=Favourites from The Poster Club ${this.props.favourites.shareableUrl}`
      }
      if( type === 'mail' ) {
        window.location.href = `mailto:?subject=Favourites from The Poster Club&body=Favourites from The Poster Club ${this.props.favourites.shareableUrl}`
      }
    }

    this.setState({
      isFirstClick: false,
    })

  }

  saveFavouritesToDatabase(type) {
    const { favourites } = this.props.favourites
    const baseUrl = window.location.origin
    // const { routing } = this.props
    const { isFirstClick } = this.state
    console.log(this.props.pathname);
    console.log(this.props.search);

    const searchParams = urlStrToJSON(this.props.search)
    console.log('searchParams', searchParams);
    
    const isShared = !_.isEmpty(searchParams) ? true : false
    const hashFromRouting = isShared && searchParams.h

    if (isShared && isFirstClick) {
      const url = `${baseUrl}/favourites?h=${hashFromRouting}`
      this.props.actions.setShareableUrl(`${url}`)
      if( type === 'sms' ) {
        window.location.href = `sms:?&body=Favourites from The Poster Club ${url}`
      }
      if( type === 'mail' ) {
        window.location.href = `mailto:?subject=Favourites from The Poster Club&body=Favourites from The Poster Club ${url}`
      }
    } else {
      this.props.actions.setShareableUrl('Generating shareable link...')

      axios.post(`${baseUrl}/wp-json/tpc/v1/share-favourites`, {
        favourites_value: favourites
      })
      .then(response => {
        this.props.actions.setShareableUrl(`${baseUrl}/favourites?h=${response.data}`)
        if( type === 'sms' ) {
          window.location.href = `sms:?&body=Favourites from The Poster Club ${baseUrl}/favourites?h=${response.data}`
        }
        if( type === 'mail' ) {
          window.location.href = `mailto:?subject=Favourites from The Poster Club&body=Favourites from The Poster Club ${baseUrl}/favourites?h=${response.data}`
        }
      })
      .catch(error => {
        // console.log(error)
      })

      this.setState({
        initialLocalStorage: favourites
      })
    }
  }

  render() {
    return(
      <div className='favourites-button-flex-wrapper'>
        <div className="icon-wrapper">
          <FontAwesomeIcon className="icon-outline" icon={['fal', 'comment']} title="Text" size="xs" onClick={()=>this.onClickHandlerOpen('sms')}/>
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon className="icon-outline" icon={['fal', 'envelope']} title="Email" size="xs" onClick={()=>this.onClickHandlerOpen('mail')}/>
        </div>
        <div className="icon-wrapper">
          <FontAwesomeIcon className="icon-outline" icon={['fal', 'link']} title="Link" size="xs" onClick={()=>this.onClickHandlerOpen('link')}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites,
    // routing: state.routing,
    pathname: state.router.location.pathname,
		search: state.router.location.search,
  }

  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    setShowModal,
    setShareableUrl,
    setHash,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
  };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesShareButtonComponent);