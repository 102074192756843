'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toggleSidebar from './../modules/toggleSidebar';

class SidebarSearchComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            searchString: props.searchString,
            inFocus: false
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    handleFocus() {
      this.setState({
        inFocus: true
      })
    }
    handleBlur(e) {
      if (e.target.value === '') {
        this.setState({
          inFocus: false
        })
      }
    }
    handleInput(e){
        let val = e.target.value;
        this.setState({searchString: val});
    }
    handleSubmit(e) {
        e.preventDefault();
        let { searchString } = this.state;
        this.searchInput.blur();
        if(searchString){
            this.props.handleSubmit(this.state.searchString);
            toggleSidebar(false);
        }
    }
    render(){
        let formClass = this.state.inFocus === false ? 'inactive' : 'active';
        // let formClass = 'active';
        return(
            <form className={formClass} onSubmit={this.handleSubmit} action="">
                <input
                    ref={(input) => {this.searchInput = input}}
                    value={this.state.searchString}
                    onChange={this.handleInput}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    className="input-text mobile-input"
                    name="s"
                    type="search"
                    placeholder="search"
                />
            </form>
        )
    }
}

SidebarSearchComponent.displayName = 'SidebarSearchComponent';
SidebarSearchComponent.propTypes = {
    searchString: PropTypes.string
}
SidebarSearchComponent.defaultProps = {
    searchString: ''
}

export default SidebarSearchComponent;
