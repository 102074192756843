import HomestoriesGridItemComponent from "./HomestoriesGridItemComponent";
import Masonry from 'react-masonry-css';

const HomestoriesGridComponent = ({ loadedPosts, alreadyUsed }) => {
  const breakpointColsumns = {
    default: 2,
    768: 1
  }
  
  return (
    <div className="loaded-posts">
      <Masonry
        breakpointCols={breakpointColsumns}
        className="homestories-masonry-grid"
        columnClassName="homestories-masonry-grid-colmun"
      >
        {loadedPosts && loadedPosts.map((post) => { 

          if( alreadyUsed.includes( post.id.toString(  ) ) ) {
            return false;
          }
          
          if( post._embedded ) {
            return <HomestoriesGridItemComponent key={post.id} {...post} />
          }
          
          }
        )}
      </Masonry>
    </div>
  )
}

export default HomestoriesGridComponent;