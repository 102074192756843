import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { get, has, isEmpty, truncate } from "lodash";
import { useState } from "react";
import { fadeAnimation } from "../animations";

const MegaMenuComponent = ({ columns, marketingPosts, setMegaMenuIndex }) => {
  const calculatePostsInMarketingPostsArray = () => {
    if (isEmpty(marketingPosts)) return [];
    const n =
      columns.length < 3
        ? 3
        : columns.length < 4
        ? 2
        : columns.length < 5
        ? 1
        : 0;
    const calculatedMarketingPostsArray = marketingPosts.slice(0, n);

    return calculatedMarketingPostsArray;
  };

  const linkHandler = () => {
    setMegaMenuIndex(false);
  };

  return (
    <>
      <div className="flex flex-1">
        {columns.map((column, index) => (
          <div
            key={index}
            className={classNames("flex-1", {
              "border-l border-solid border-gray pl-4": index > 0,
            })}
          >
            <ul>
              {column.mega_menu_column_title && (
                <li style={{ lineHeight: "10px" }}>
                  <a
                    className="font-display-regular tracking-wider uppercase text-black"
                    style={{ fontSize: "10px" }}
                    {...((isEmpty(column.mega_menu_column_link_to) ||
                      isEmpty(column.mega_menu_column_custom_link_to)) && {
                      href: column.mega_menu_column_custom_link_to
                        ? column.mega_menu_column_custom_link_to
                        : column.mega_menu_column_link_to.slug,
                    })}
                    onClick={() => linkHandler()}
                  >
                    {column.mega_menu_column_title}
                    <span
                      className="block w-11 bg-gray mt-2 mb-5"
                      style={{ height: "1px" }}
                    ></span>
                  </a>
                </li>
              )}
              {column.mega_menu_column_use_latest_posts
                ? column?.mega_menu_column_latest_posts_array.map(
                    (latestPost, index) => (
                      <motion.li
                        key={index}
                        className="mb-3 last:mb-0"
                        // onHoverStart={() => setHasLatestPostHover(index)}
                        // onHoverEnd={() => setHasLatestPostHover(false)}
                      >
                        <a
                          href={latestPost?.post_slug}
                          className="flex items-center cursor-pointer"
                          onClick={() => linkHandler()}
                        >
                          <div
                            className="flex-shrink-0"
                            style={{ width: "79px" }}
                          >
                            <img
                              src={latestPost?.post_image[0]}
                              width={latestPost?.post_image[1]}
                              height={latestPost?.post_image[2]}
                              className="block"
                            />
                          </div>
                          <div
                            className="py-3 flex flex-col"
                            style={{ marginLeft: "10px" }}
                          >
                            <p className="font-body text-sm text-black">
                              {latestPost?.post_title}
                            </p>
                            {latestPost?.post_excerpt && (
                              <p
                                className="font-body-regular text-xs text-gray-light"
                                style={{ maxWidth: "178px" }}
                              >
                                {truncate(latestPost?.post_excerpt, {
                                  length: 50,
                                })}
                              </p>
                            )}
                            <span
                              className="relative font-display-regular text-black uppercase tracking-wider mb-0 inline-block self-start"
                              style={{ fontSize: "10px" }}
                            >
                              Read More
                              <span
                                className="absolute left-0 bottom-0 bg-black w-full"
                                style={{ height: "1px", marginBottom: "-4px" }}
                              />
                            </span>
                          </div>
                        </a>
                      </motion.li>
                    )
                  )
                : column?.mega_menu_menu_items.map((menuItem, index) => (
                    <li key={index} className="mb-3 last:mb-0">
                      <a
                        href={
                          menuItem.mega_menu_menu_item_custom_link_to
                            ? menuItem.mega_menu_menu_item_custom_link_to
                            : menuItem.mega_menu_menu_item_link_to.slug
                        }
                        className="font-body-regular text-sm text-black"
                        onClick={() => linkHandler()}
                      >
                        {menuItem.mega_menu_custom_menu_item_title
                          ? menuItem.mega_menu_custom_menu_item_title
                          : menuItem.mega_menu_menu_item_link_to.name}
                      </a>
                    </li>
                  ))}
            </ul>
          </div>
        ))}
      </div>
      {columns.length < 5 && (
        <div className="flex -ml-8 pl-4">
          {calculatePostsInMarketingPostsArray().map(
            (marketingPost, index) =>
              (get(
                marketingPost,
                "mega_menu_marketing_post.customImage",
                false
              ) ||
                get(
                  marketingPost,
                  "mega_menu_marketing_post.post_image",
                  false
                )) && (
                <motion.div key={index} className="ml-8">
                  <a
                    className="block"
                    href={marketingPost?.mega_menu_marketing_post?.post_slug}
                    onClick={() => linkHandler()}
                  >
                    <div
                      style={{
                        height: marketingPost?.mega_menu_marketing_post
                          ?.customImage[2]
                          ? marketingPost?.mega_menu_marketing_post
                              ?.customImage[2] / 2
                          : marketingPost?.mega_menu_marketing_post
                              ?.post_image[2] / 2,
                      }}
                    >
                      <img
                        src={
                          marketingPost?.mega_menu_marketing_post
                            ?.customImage[0]
                            ? marketingPost?.mega_menu_marketing_post
                                ?.customImage[0]
                            : marketingPost?.mega_menu_marketing_post
                                ?.post_image[0]
                        }
                        width={
                          marketingPost?.mega_menu_marketing_post
                            ?.customImage[1]
                            ? marketingPost?.mega_menu_marketing_post
                                ?.customImage[1] / 2
                            : marketingPost?.mega_menu_marketing_post
                                ?.post_image[1] / 2
                        }
                        height={
                          marketingPost?.mega_menu_marketing_post
                            ?.customImage[2]
                            ? marketingPost?.mega_menu_marketing_post
                                ?.customImage[2] / 2
                            : marketingPost?.mega_menu_marketing_post
                                ?.post_image[2] / 2
                        }
                        className="block w-auto h-full max-h-full"
                        style={{
                          maxWidth:
                            marketingPost?.mega_menu_marketing_post
                              ?.customImage[1] &&
                            marketingPost?.mega_menu_marketing_post
                              ?.customImage[2]
                              ? marketingPost?.mega_menu_marketing_post
                                  ?.customImage[1] >
                                marketingPost?.mega_menu_marketing_post
                                  ?.customImage[2]
                              : marketingPost?.mega_menu_marketing_post
                                  ?.post_image[1] >
                                marketingPost?.mega_menu_marketing_post
                                  ?.post_image[2]
                              ? "initial"
                              : "100%",
                        }}
                      />
                    </div>
                    <p
                      className="font-body-regular text-xs text-gray-light mb-0"
                      style={{
                        marginTop: "15px",
                        maxWidth: `${
                          marketingPost?.mega_menu_marketing_post
                            ?.customImage[1]
                            ? marketingPost?.mega_menu_marketing_post
                                ?.customImage[1] / 2
                            : marketingPost?.mega_menu_marketing_post
                                ?.post_image[1] / 2
                        }px`,
                      }}
                    >
                      {marketingPost?.mega_menu_marketing_post_description}
                    </p>
                    <span
                      className="font-display-regular tracking-wider text-black uppercase relative inline-block mb-1"
                      style={{ fontSize: "10px", marginTop: "10px" }}
                    >
                      Read More
                      <span
                        className="absolute left-0 bottom-0 bg-black w-full"
                        style={{ height: "1px", marginBottom: "-4px" }}
                      />
                    </span>
                  </a>
                </motion.div>
              )
          )}
        </div>
      )}
    </>
  );
};

export default MegaMenuComponent;
