import { useAtom } from "jotai";
import {
  activeProductInfoSidebarTabAtom,
  showProductInfoSidebarAtom,
} from "../../store";
import SidebarOuter from "./wrappers/SidebarOuter";
import SidebarInner from "./wrappers/SidebarInner";
import FrameAndPassepartoutDetails from "./product-info/FrameAndPassepartoutDetails";
import FramingService from "./product-info/FramingService";
import ShippingInformation from "./product-info/ShippingInformation";

const ProductInfoSidebar = ({
  selectedPassepartoutVariant,
  selectedFrameVariant,
}) => {
  const [showProductInfo, setShowProductInfo] = useAtom(
    showProductInfoSidebarAtom
  );

  const [activeProductInfoSidebarTab, setActiveProductInfoSidebarTab] = useAtom(
    activeProductInfoSidebarTabAtom
  );

  const determineContent = (slug) => {
    switch (slug) {
      case "framing":
        return <FramingService />;

      case "frame-and-passepartout":
        return (
          <FrameAndPassepartoutDetails
            selectedPassepartoutVariant={selectedPassepartoutVariant}
            selectedFrameVariant={selectedFrameVariant}
          />
        );

      case "shipping":
        return <ShippingInformation />;

      default:
        return null;
    }
  };

  return (
    <SidebarOuter
      title={false}
      showSidebar={showProductInfo}
      setShowSidebar={setShowProductInfo}
      extraCallbacks={[() => (window.location.hash = "")]}
    >
      <SidebarInner extraCallbacks={[() => (window.location.hash = "")]}>
        {determineContent(activeProductInfoSidebarTab)}
      </SidebarInner>
    </SidebarOuter>
  );
};

export default ProductInfoSidebar;
