'use strict';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import FavouritesBuyButtonComponent from './../components/FavouritesBuyButtonComponent';

class FavouritesBuyButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { favourites, products } = this.props

    const hasFavouriteHash = _.get(this.props.routing, 'locationBeforeTransitions.query.h', false)
    const hasFavourites = !_.isEmpty(favourites.favourites) ? true : false
    const isLoaded = !products.isFetching && !products.isFailed ? true : false

    return(
      <div className={classNames( 'react-favourites-buy-button', { 'react-favourites-buy-button--show': isLoaded })}>
        {(hasFavourites || hasFavouriteHash) && <FavouritesBuyButtonComponent />}
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  const props = {
    favourites: state.favourites,
    products: state.products,
    routing: state.routing
  }

  return props;
}

export default connect(mapStateToProps, null)(FavouritesBuyButton);
