"use strict";

import React, { Component } from "react";

import { isEmpty } from "lodash";

// import fetchProduct from './../actions/products/fetchProduct'

import ProductsComponent from "./../components/ProductsComponent";

class RelatedProducts extends Component {
  constructor(props) {
    super(props);

    const stockStatus = window.tpcProductStock || false;
    const cats = window.tpcProductCats || [];
    const isFrames = cats.indexOf("frames") > -1;
    this.state = {
      stockStatus: stockStatus,
      isFrames: isFrames,
    };
    this.is404Page = this.is404Page.bind(this);
  }

  componentDidMount() {
    console.log("the related prodcs did mount..!");
  }

  is404Page() {
    return document.body.classList.contains("error404") ?? false;
  }

  render() {
    const is404Page = this.is404Page();
    console.log("porps", this.props);
    const { products, productType } = this.props;
    const { isFrames, stockStatus } = this.state;
    const infoTitle =
      stockStatus !== "outofstock"
        ? isFrames
          ? `Other frames`
          : `Find a matching frame`
        : `Other products`;
    const infoText =
      stockStatus !== "outofstock"
        ? isFrames
          ? ``
          : ``
        : `You might be interested in these products or <a href="/artprints">browse the full selection</a>`;
    const btnText = isFrames
      ? `See all frames`
      : stockStatus === "outofstock"
      ? `See all art prints`
      : `See all frames`;
    const btnLink = isFrames
      ? `/frames`
      : stockStatus === "outofstock"
      ? `/artprints`
      : `/frames`;
    // console.log('products or frames', productsOrFrames)
    return !isEmpty(products) ? (
      <div className="related-grid">
        {!is404Page && (
          <div className="related-grid related-grid--info container">
            <div className="row">
              <div className="col-xs-12">
                <h3>{infoTitle}</h3>
                {infoText && (
                  <p dangerouslySetInnerHTML={{ __html: infoText }} />
                )}
              </div>
            </div>
          </div>
        )}
        <ProductsComponent
          products={products}
          productsOrFrames={products}
          isSingleProduct={true}
          is404={is404Page}
        />

        {!is404Page && (
          <div className="related-grid related-grid--button container">
            <a href={btnLink} className="shop-button">
              {btnText}
            </a>
          </div>
        )}
      </div>
    ) : null;
  }
}

export default RelatedProducts;
