'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { push } from 'react-router-redux';

import { search } from './../lib/tracking';
import toggleSearch from './../actions/search/toggleSearch';

import SearchComponent from './../components/SearchComponent';
import SidebarSearchComponent from './../components/SidebarSearchComponent';

class Search extends Component {
    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleToggleSearch = this.handleToggleSearch.bind(this);
    }
    handleSubmit(searchString) {
        //console.log('SEARCH handling submit', searchString);
        searchString = searchString.toLowerCase();
        let url = '/?s='+searchString;
        let path = window.location.pathname;
        if(path !== '/'){
            window.location = '/?s='+searchString;
        }
        search(searchString);
        this.props.dispatch(push(url));
    }
    handleToggleSearch(){
        this.props.toggleSearch(false);
    }
    render(){
        let { isToggled } = this.props.search;
        return(
            this.props.position == 'header' ?
                <SearchComponent
                    handleSubmit={this.handleSubmit}
                    toggleSearch={this.handleToggleSearch}
                    isToggled={isToggled}
                />
                : <SidebarSearchComponent handleSubmit={this.handleSubmit} />
        )
    }
}

Search.propTypes = {
    position: PropTypes.string
}
Search.defaultProps = {
    position: 'header'
};

let mapStateToProps = (state) => {
    const props = {
        search: state.search
    }
    return props;
}

let mapDispatchToProps = (dispatch) => {
    let actionMap = {
        dispatch,
        toggleSearch: (toggle) => {
            dispatch(toggleSearch(toggle));
        }
    };
    return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
