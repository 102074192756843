import { atom } from "jotai";
import { create } from "zustand";
import { createFavouritesSlice } from "./slices/createFavouritesSlice";
import { productInfoSidebarTabs } from "./lib/globals";

export const useBoundStore = create((...a) => ({
  ...createFavouritesSlice(...a),
}));

export const selectedSizeVariantAtom = atom({});

export const selectedPassepartoutVariantAtom = atom({});

export const selectedFrameVariantAtom = atom({});

export const manualClickAtom = atom(false);

export const showSizeGuideSidebarAtom = atom(false);
export const showProductInfoSidebarAtom = atom(false);
export const activeProductInfoSidebarTabAtom = atom(
  productInfoSidebarTabs?.[0]?.slug
);
